/* eslint-disable @typescript-eslint/no-magic-numbers */
import { ChangeDetectionStrategy, Component, Input, Signal } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { CommonModule } from '@angular/common';
import { toSignal } from '@angular/core/rxjs-interop';
import { BaseetaBadgeArIconComponent } from '@app/shared/components/icons/generated/baseeta-badge-ar/baseeta-badge-ar.component';
import { BaseetaBadgeEnIconComponent } from '@app/shared/components/icons/generated/baseeta-badge-en/baseeta-badge-en.component';

@Component({
  standalone: true,
  selector: 'popsy-baseeta-badge',
  templateUrl: './baseeta-badge.template.html',
  styleUrls: ['./baseeta-badge.style.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CommonModule, BaseetaBadgeArIconComponent, BaseetaBadgeEnIconComponent],
})
export class BaseetaBadgeComponent {
  @Input() public size?: 'small' | 'medium' | 'default' = 'default';

  public readonly language: Signal<string>;

  constructor(private readonly translateService: TranslocoService) {
    this.language = toSignal(this.translateService.langChanges$, {
      initialValue: this.translateService.getActiveLang(),
    });
  }
}
