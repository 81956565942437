export class TabbyBuyerHistory {
  public registered_since: string;
  public loyalty_level?: number | null;
  public wishlist_count?: number | null;
  public is_social_networks_connected?: boolean | null;
  public is_phone_number_verified?: boolean | null;
  public is_email_verified?: boolean | null;

  constructor() {
    this.registered_since = '';
    this.loyalty_level = null;
    this.wishlist_count = null;
    this.is_social_networks_connected = null;
    this.is_phone_number_verified = null;
    this.is_email_verified = null;
  }
}

export const parseTabbyBuyerHistory = (
  data?: Record<string, any> | null
): TabbyBuyerHistory | null => {
  let result: TabbyBuyerHistory | null = null;
  if (data) {
    result = new TabbyBuyerHistory();
    result.registered_since = data.registered_since || '';
    result.loyalty_level = data.loyalty_level || null;
    result.wishlist_count = data.wishlist_count || null;
    result.is_social_networks_connected = data.is_social_networks_connected || null;
    result.is_phone_number_verified = data.is_phone_number_verified || null;
    result.is_email_verified = data.is_email_verified || null;
  }
  return result;
};
