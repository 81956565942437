<ng-container *transloco="let t">
  <footer
    class="footer-component"
    [class.widescreen]="(biggerThan$ | async)?.sm"
    [class.mobile]="(biggerThan$ | async)?.mobileL"
    [class.rtl]="rtlMode$ | async">
    <section class="content" *ngIf="currentLocation$ | async">
      <div class="head-wrap">
        <a
          class="popsy-icon name flex"
          href=""
          [routerLink]="homeUrlFragment"
          [queryParams]="queryParamsToKeep$ | async"
          [attr.aria-label]="t('aria_action_home')">
          <!--   <popsy-icon-popsy-logo-new
            class="icon"
            (click)="scrollToTop()" /> -->
          <popsy-icon-popsy-duo class="icon duo" (click)="scrollToTop()" />
        </a>
        <app-subscribe-box class="box"></app-subscribe-box>
      </div>

      <span class="fixed-space-32" aria-hidden="true"></span>
      <span *ngIf="(biggerThan$ | async)?.sm" class="dynamic-space"></span>

      <div class="flex flex-col container">
        <app-payment-methods-line [footerMode]="true"></app-payment-methods-line>

        <div class="grid">
          <!-- INFO COLUMN -->
          <div class="info">
            <span class="bold">{{ t('category_about') }} Popsy</span>
            <a
              class="link"
              href=""
              [routerLink]="popsyHowItWorks()"
              [queryParams]="queryParamsToKeep$ | async"
              [attr.aria-label]="t('title_how_it_works')">
              {{ t('title_our_mission') }}
            </a>

            <a
              class="link"
              [routerLink]="informationPageUrlFragment('warranty-and-refunds')"
              [queryParams]="queryParamsToKeep$ | async"
              [attr.aria-label]="t('label_customer_reviews')">
              {{ t('title_featured_warranty_variable', { var1: months }) }}
            </a>

            <ng-container *ngIf="language === 'ar'">
              <a
                class="link"
                href="https://popsyuae.myshopify.com/blogs/popsy-blog"
                [attr.aria-label]="t('aria_link_blog')">
                {{ t('footer_blog') }}
              </a>
            </ng-container>
            <ng-container *ngIf="language !== 'ar'">
              <a
                class="link"
                href="https://shop.popsy.app/blogs/popsy-blog"
                [attr.aria-label]="t('aria_link_blog')">
                {{ t('footer_blog') }}
              </a>
            </ng-container>
            <!-- <a
              class="link"
              href=""
              [routerLink]="popsyCustomerReviewsFragment()"
              [queryParams]="queryParamsToKeep$ | async"
              [attr.aria-label]="t('label_customer_reviews')">
              {{ t('label_customer_reviews') }}
            </a> -->
            <a
              class="link"
              href=""
              [routerLink]="popsyAllBrandsFragment()"
              [queryParams]="queryParamsToKeep$ | async"
              [attr.aria-label]="t('label_all_brands')">
              {{ t('label_all_brands') }}
            </a>
            <a
              class="link"
              href=""
              [routerLink]="popsySellerFragment()"
              [queryParams]="queryParamsToKeep$ | async"
              [attr.aria-label]="t('label_seller')">
              {{ t('title_sell_with_popsy') }}
            </a>
            <a
              class="link"
              [routerLink]="informationPageUrlFragment('warranty-and-refunds')"
              [queryParams]="queryParamsToKeep$ | async"
              [attr.aria-label]="t('label_customer_reviews')">
              {{ t('title_refund_popsy_protect_overlay') }}
            </a>

            <a
              class="link"
              href=""
              [routerLink]="informationPageUrlFragment('customer-reviews')"
              [queryParams]="queryParamsToKeep$ | async"
              [attr.aria-label]="t('label_customer_reviews')">
              {{ t('label_customer_reviews') }}
            </a>
          </div>

          <!-- GET HELP COLUMN-->
          <div *ngIf="language !== 'ar'" class="get-help">
            <span class="bold">{{ t('label_get_help') }}</span>
            <a
              *ngIf="user$ | async"
              class="link"
              href=""
              [routerLink]="informationPageUrlFragment('user')"
              [queryParams]="queryParamsToKeep$ | async"
              [attr.aria-label]="t('title_track_order')">
              {{ t('title_track_order') }}
            </a>
            <a
              *ngIf="!(user$ | async)"
              class="link"
              href=""
              [routerLink]="deliverySearchUrlFragment()"
              [queryParams]="queryParamsToKeep$ | async"
              [attr.aria-label]="t('title_track_order')">
              {{ t('title_track_order') }}
            </a>
            <a
              class="link"
              [routerLink]="informationPageUrlFragment('shipping-and-delivery')"
              [queryParams]="queryParamsToKeep$ | async"
              [attr.aria-label]="t('link_zendesk_arrive')">
              {{ t('link_zendesk_arrive') }}
            </a>
            <a
              class="link"
              [routerLink]="informationPageUrlFragment('payment-methods')"
              [queryParams]="queryParamsToKeep$ | async"
              [attr.aria-label]="t('link_zendesk_payment')">
              {{ t('link_zendesk_payment') }}
            </a>
            <!--  <a
              class="link"
              href="https://popsy.zendesk.com/hc/en-us/articles/6034188620429-What-is-your-Return-Policy-"
              [attr.aria-label]="t('link_zendesk_policy')">
              {{ t('link_zendesk_policy') }}
            </a> -->
            <!-- <a
              class="link"
              href="https://popsy.zendesk.com/hc/pt/articles/5948720071949-How-can-I-track-my-order-"
              [attr.aria-label]="t('aria_link_blog')">
              {{ t('link_zendesk_orders') }}
            </a> -->
            <a
              class="link"
              [routerLink]="informationPageUrlFragment('faq')"
              [queryParams]="queryParamsToKeep$ | async"
              [attr.aria-label]="t('aria_link_faq')">
              {{ t('link_faq') }}
            </a>
            <a
              class="link"
              href="https://help.tabby.ai/"
              [attr.aria-label]="t('link_tabby_faq')">
              {{ t('link_tabby_faq') }}
            </a>
            <a
              class="link"
              href=""
              [routerLink]="informationPageUrlFragment('credit-card-installments')"
              [queryParams]="queryParamsToKeep$ | async"
              [attr.aria-label]="t('label_credit_card_installments')">
              {{ t('label_credit_card_installments') }}
            </a>
            <a
              class="link"
              href=""
              [routerLink]="informationPageUrlFragment('terms-of-use')"
              [queryParams]="queryParamsToKeep$ | async"
              [attr.aria-label]="t('title_terms_of_use')">
              {{ t('title_terms_of_use') }}
            </a>
          </div>

          <!-- GET HELP COLUMN ARabic-->
          <div *ngIf="language === 'ar'" class="get-help">
            <span class="bold">{{ t('label_get_help') }}</span>
            <a
              *ngIf="user$ | async"
              class="link"
              href=""
              [routerLink]="informationPageUrlFragment('user')"
              [queryParams]="queryParamsToKeep$ | async"
              [attr.aria-label]="t('title_track_order')">
              {{ t('title_track_order') }}
            </a>
            <a
              *ngIf="!(user$ | async)"
              class="link"
              href=""
              [routerLink]="deliverySearchUrlFragment()"
              [queryParams]="queryParamsToKeep$ | async"
              [attr.aria-label]="t('title_track_order')">
              {{ t('title_track_order') }}
            </a>
            <a
              class="link"
              [routerLink]="informationPageUrlFragment('shipping-and-delivery')"
              [queryParams]="queryParamsToKeep$ | async"
              [attr.aria-label]="t('link_zendesk_arrive')">
              {{ t('link_zendesk_arrive') }}
            </a>
            <a
              class="link"
              [routerLink]="informationPageUrlFragment('payment-methods')"
              [queryParams]="queryParamsToKeep$ | async"
              [attr.aria-label]="t('link_zendesk_payment')">
              {{ t('link_zendesk_payment') }}
            </a>
            <!-- <a
              class="link"
              href="https://popsy.zendesk.com/hc/en-us/sections/6047421360013-%D8%A7%D9%84%D9%85%D8%B1%D8%AA%D8%AC%D8%B9%D8%A7%D8%AA"
              [attr.aria-label]="t('link_zendesk_policy')">
              {{ t('link_zendesk_policy') }}
            </a> -->
            <!--  <a
              class="link"
              href="https://popsy.zendesk.com/hc/pt/articles/4442894722317-%D9%83%D9%8A%D9%81-%D9%8A%D9%85%D9%83%D9%86%D9%86%D9%8A-%D8%A7%D9%84%D8%AA%D8%AD%D9%82%D9%82-%D9%85%D9%86-%D8%AD%D8%A7%D9%84%D8%A9-%D8%A7%D9%84%D8%B4%D8%AD%D9%86-%D8%A7%D9%84%D8%AE%D8%A7%D8%B5%D8%A9-%D8%A8%D9%8A-%D9%8A%D9%85%D9%83%D9%86%D9%83-%D9%85%D8%AA%D8%A7%D8%A8%D8%B9%D8%A9-%D8%B7%D9%84%D8%A8%D9%83-%D8%B9%D9%84%D9%89-%D9%85%D9%84%D9%81%D9%83-%D8%A7%D9%84%D8%B4%D8%AE%D8%B5%D9%8A-%D8%A3%D9%88-%D9%81%D9%8A-%D8%A7%D9%84%D8%AA%D8%B7%D8%A8%D9%8A%D9%82-%D8%A3%D9%88-%D8%A7%D9%84%D9%85%D9%88%D9%82%D8%B9-%D8%A7%D9%84%D8%A5%D9%84%D9%83%D8%AA%D8%B1%D9%88%D9%86%D9%8A-%D8%A7%D9%84%D8%AE%D8%A7%D8%B5-%D8%A8%D9%86%D8%A7-%D8%B3%D9%86%D8%B2%D9%88%D8%AF%D9%83-%D8%A3%D9%8A%D8%B6%D9%8B%D8%A7-%D8%A8%D8%B1%D9%82%D9%85-%D8%AA%D8%AA%D8%A8%D8%B9-%D8%A7%D9%84%D8%A8%D8%B1%D9%8A%D8%AF-%D8%A7%D9%84%D8%B3%D8%B1%D9%8A%D8%B9-%D8%A3%D9%8A-%D8%A3%D8%B3%D8%A6%D9%84%D8%A9-%D9%8A%D9%85%D9%83%D9%86%D9%83-%D8%A7%D9%84%D8%A7%D8%AA%D8%B5%D8%A7%D9%84-%D8%A8%D9%86%D8%A7-%D8%B9%D9%84%D9%89-support-popsy-app"
              [attr.aria-label]="t('aria_link_blog')">
              {{ t('link_zendesk_orders') }}
            </a> -->
            <a
              class="link"
              [routerLink]="informationPageUrlFragment('faq')"
              [queryParams]="queryParamsToKeep$ | async"
              [attr.aria-label]="t('aria_link_faq')">
              {{ t('link_faq') }}
            </a>
            <a
              class="link"
              href="https://help.tabby.ai/"
              [attr.aria-label]="t('link_tabby_faq')">
              {{ t('link_tabby_faq') }}
            </a>
            <a
              class="link"
              href=""
              [routerLink]="informationPageUrlFragment('credit-card-installments')"
              [queryParams]="queryParamsToKeep$ | async"
              [attr.aria-label]="t('label_credit_card_installments')">
              {{ t('label_credit_card_installments') }}
            </a>
            <a
              class="link"
              href=""
              [routerLink]="informationPageUrlFragment('terms-of-use')"
              [queryParams]="queryParamsToKeep$ | async"
              [attr.aria-label]="t('title_terms_of_use')">
              {{ t('title_terms_of_use') }}
            </a>
          </div>

          <!-- MORE COLUMN-->
          <div class="more-info">
            <span class="bold" (click)="openFeedbackForm()">
              {{ t('action_contact_us') }}
            </span>
            <span
              class="link"
              [style.cursor]="'pointer'"
              [attr.aria-label]="t('action_contact_us_description')"
              (click)="openFeedbackForm()">
              support&#64;popsy.app
            </span>
            <!--           <div class="force-ltr link">+966 800 891 2028</div>
  -->
            @if (country === 'SA') {
              <a
                class="link parag cert"
                target="_blank"
                [href]="certificateLink"
                [attr.aria-label]="t('link_tabby_faq')"
                >{{ companyNo }}</a
              >
            } @else {
              <div class="parag">{{ companyNo }}</div>
            }

            <div class="parag">{{ displayAddress }}</div>
          </div>
        </div>
      </div>
    </section>

    <span class="fixed-space-32" aria-hidden="true"></span>

    <section class="products-content">
      <div class="grid four-col">
        <div class="info">
          <span class="bold">iPhones</span>
          <ng-container
            *ngFor="
              let product of iPhones$ | async | slice: 0 : 7;
              trackBy: trackByModel
            ">
            <span
              [routerLink]="productFragment(product.id)"
              [queryParams]="queryParamsToKeep$ | async"
              (click)="scrollToTop()"
              >{{ product.model }}</span
            >
          </ng-container>
        </div>
        <div class="info">
          <span class="bold">{{ t('category_laptop_title') }}</span>
          <ng-container
            *ngFor="
              let product of laptops$ | async | slice: 0 : 7;
              trackBy: trackByModel
            ">
            <span
              [routerLink]="productFragment(product.id)"
              [queryParams]="queryParamsToKeep$ | async"
              (click)="scrollToTop()"
              >{{ product.model }}</span
            >
          </ng-container>
        </div>
        <div class="info">
          <span class="bold">{{ t('category_tablet_title') }}</span>
          <ng-container
            *ngFor="
              let product of tablets$ | async | slice: 0 : 7;
              trackBy: trackByModel
            ">
            <span
              [routerLink]="productFragment(product.id)"
              [queryParams]="queryParamsToKeep$ | async"
              (click)="scrollToTop()"
              >{{ product.model }}</span
            >
          </ng-container>
        </div>
        <div class="info" *ngIf="(watches$ | async)?.length">
          <span class="bold">{{ t('category_watch_title') }}</span>
          <ng-container
            *ngFor="
              let product of watches$ | async | slice: 0 : 4;
              trackBy: trackByModel
            ">
            <span
              [routerLink]="productFragment(product.id)"
              [queryParams]="queryParamsToKeep$ | async"
              (click)="scrollToTop()"
              >{{ product.model }}</span
            >
          </ng-container>
        </div>
      </div>
    </section>
    <span class="fixed-space-32" aria-hidden="true"></span>

    <div class="extended-content">
      <div class="wrapper footer-wrap-to-center">
        <div class="social-wrapper">
          <nav class="social" aria-label="Social Links">
            <a
              [attr.aria-label]="t('aria_link_social_facebook')"
              class="button flex align-center justify-center"
              rel="noopener noreferrer"
              href="https://www.facebook.com/popsyapp/?fref=ts"
              target="_blank">
              <popsy-icon-facebook aria-hidden="true" class="icon"></popsy-icon-facebook>
            </a>
            <a
              [attr.aria-label]="t('aria_link_social_twitter')"
              class="button flex align-center justify-center"
              rel="noopener noreferrer"
              href="https://twitter.com/popsyapp"
              target="_blank">
              <popsy-icon-twitter aria-hidden="true" class="icon"></popsy-icon-twitter>
            </a>
            <a
              [attr.aria-label]="t('aria_link_social_instagram')"
              class="button flex align-center justify-center"
              rel="noopener noreferrer"
              href="https://www.instagram.com/popsy.app/"
              target="_blank">
              <popsy-icon-instagram
                aria-hidden="true"
                class="icon"></popsy-icon-instagram>
            </a>
            <a
              [attr.aria-label]="t('aria_link_social_linkedin')"
              class="button flex align-center justify-center"
              rel="noopener noreferrer"
              href="https://www.linkedin.com/company/popsy/"
              target="_blank">
              <popsy-icon-in aria-hidden="true" class="icon"></popsy-icon-in>
            </a>
          </nav>
          <nav aria-label="Mobile Apps" class="app-links">
            <a
              [attr.aria-label]="t('aria_link_social_twitter')"
              class="button flex align-center justify-center"
              rel="noopener noreferrer"
              href="https://itunes.apple.com/app/apple-store/id1387389249?pt=119076750&ct=web&mt=8"
              target="_blank">
              <popsy-icon-app-store-badge class="icon"></popsy-icon-app-store-badge>
            </a>
            <a
              [attr.aria-label]="t('aria_link_social_twitter')"
              class="button flex align-center justify-center"
              rel="noopener noreferrer"
              href="https://play.google.com/store/apps/details?id=com.mypopsy.android"
              target="_blank">
              <popsy-icon-play-store-badge class="icon"></popsy-icon-play-store-badge>
            </a>
            <a
              *ngIf="language !== 'ar'"
              [attr.aria-label]="t('aria_link_social_twitter')"
              class="button flex align-center justify-center"
              rel="noopener noreferrer"
              href="https://url.cloud.huawei.com/iXL8mMNkyY?shareTo=qrcode"
              target="_blank">
              <popsy-icon-huawei-store-badge class="icon"></popsy-icon-huawei-store-badge>
            </a>
            <a
              *ngIf="language === 'ar'"
              [attr.aria-label]="t('aria_link_social_twitter')"
              class="button flex align-center justify-center"
              rel="noopener noreferrer"
              href="https://url.cloud.huawei.com/iXL8mMNkyY?shareTo=qrcode"
              target="_blank">
              <popsy-icon-huawei-store-ar class="icon"></popsy-icon-huawei-store-ar>
            </a>
          </nav>
        </div>
        <div class="extended-content-copyright">
          <span
            *ngIf="!(biggerThan$ | async)?.sm"
            class="fixed-space-16"
            aria-hidden="true"></span>
          <span class="fixed-space-16" aria-hidden="true"></span>
          <p class="address">{{ displayAddressBR }}</p>

          <div class="wrapper">
            <a
              class="link"
              href=""
              [routerLink]="informationPageUrlFragment('privacy-policy')"
              [queryParams]="queryParamsToKeep$ | async"
              [attr.aria-label]="t('link_privacy_policy')"
              >{{ t('link_privacy_policy') }}
            </a>
            <span class="fixed-space-4" aria-hidden="true"></span>|<span
              class="fixed-space-4"
              aria-hidden="true"></span>

            <a
              class="link"
              href=""
              [routerLink]="informationPageUrlFragment('terms-of-use')"
              [queryParams]="queryParamsToKeep$ | async"
              [attr.aria-label]="t('title_terms_of_use')">
              {{ t('title_terms_of_use') }}
            </a>
            <ng-container *ngIf="(biggerThan$ | async)?.xs">
              <span class="fixed-space-4" aria-hidden="true"></span>|<span
                class="fixed-space-4"
                aria-hidden="true"></span>
              <div class="nowrap">{{ t('app_copyright', { var1: currentYear }) }}</div>
            </ng-container>
          </div>
          <ng-container *ngIf="!(biggerThan$ | async)?.xs">
            <div class="nowrap">{{ t('app_copyright') }}</div>
          </ng-container>
        </div>
      </div>
    </div>

    <span class="fixed-space-8" aria-hidden="true"></span>

    <button
      type="button"
      class="scroll-up-button flex align-center justify-center"
      [attr.aria-label]="t('tooltip_scroll_up')"
      matTooltipClass="popsy-tooltip"
      *ngIf="showArrow && !(biggerThan$ | async)?.sm"
      (click)="scrollToTop()">
      <popsy-icon-keyboard-arrow-up
        class="icon gray-01 arrow rtl"></popsy-icon-keyboard-arrow-up>
    </button>
  </footer>
</ng-container>
