/* eslint-disable @typescript-eslint/no-magic-numbers */
import {
  ChangeDetectionStrategy,
  Component,
  OnDestroy,
  Input,
  AfterViewInit,
  OnChanges,
  SimpleChanges,
  ChangeDetectorRef,
} from '@angular/core';
import { AkitaRouterQuery } from '@app/akita/router/state/router.query';

import { AkitaScreenQuery } from '@app/akita/screen/state/screen.query';
import { ScreenSizeInfoState } from '@app/akita/screen/state/screen.store';
import { TranslocoModule, TranslocoService } from '@ngneat/transloco';
import { map, Observable, Subject, Subscription, takeUntil } from 'rxjs';

import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { LoadingComponent } from '@app/shared/components/loading/loading.component';
import { AmountPipe } from '@app/shared/pipes/amount.pipe';
import { PriceModel } from '@app/shared/models/api/price.model';

import { BaseetaOverlayDynamicService } from '@app/overlays/baseeta-overlay/services/baseeta-overlay-dynamic.service';
import { BaseetaBadgeComponent } from '../baseeta-badge/baseeta-badge.component';
import { logPaymentInfoCardClicked } from '@app/core/services/google-analytics.lazy';
import { GoogleAnalyticsService } from '@app/core/services/google-analytics.service';

export const BASEETA_SUPPORTED_COUNTRIES = ['SA']; // sa
export const BASEETA_SUPPORTED_CURRENCIES = ['SAR'];
export const BASEETA_MIN_AMOUNT = 0;
export const BASEETA_MAX_AMOUNT = 30000;

export const BASEETA_MAX_INSTALLMENTS = 36;
export const SHOW_BASEETA_PROMO = true;
export const BASEETA_MERCHANT_ID = 'c934e22c-8e02-475b-8f05-90c8b54fa5ef';

@Component({
  standalone: true,
  selector: 'popsy-baseeta-promo',
  templateUrl: './baseeta-promo.template.html',
  styleUrls: ['./baseeta-promo.style.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    AmountPipe,
    RouterModule,
    TranslocoModule,
    LoadingComponent,
    BaseetaBadgeComponent,
  ],
})
export class BaseetaPromoComponent implements OnDestroy, OnChanges, AfterViewInit {
  @Input() public productId?: string | null;
  @Input() public amount?: number | null;
  @Input() public currency?: string | null;
  @Input() public country?: string | null;
  @Input() public source?: 'info' | 'product' | 'cart' | 'checkout' | null;
  @Input() public mode?: 'compact' | null;
  @Input() public iconSize?: string | null;

  public readonly lessThan$: Observable<ScreenSizeInfoState>;

  public isViewInitialized: boolean;
  public isBaseetaSupported: boolean;

  public splitAmount: PriceModel;
  public maxInstallments: number | null;

  public showingQuaraOverlay: boolean;
  private openQuaraOverlaySubscription?: Subscription | null;

  public readonly language$: Observable<string>;

  public readonly widgetLocale$: Observable<string>;

  private readonly subscriptions: Subscription;
  private readonly destroySubject: Subject<void>;

  constructor(
    // private readonly quaraActionsService: QuaraActionsService,

    private readonly translateService: TranslocoService,
    private readonly akitaScreenQuery: AkitaScreenQuery,
    private readonly changeDetector: ChangeDetectorRef,
    private readonly akitaRouterQuery: AkitaRouterQuery,
    private readonly baseetaOverlayDynamicService: BaseetaOverlayDynamicService,
    private readonly analytics: GoogleAnalyticsService
  ) {
    this.destroySubject = new Subject();
    this.subscriptions = new Subscription();
    this.isViewInitialized = false;
    this.showingQuaraOverlay = false;
    this.maxInstallments = BASEETA_MAX_INSTALLMENTS;

    this.splitAmount = {
      amount: (this.amount || 0) / BASEETA_MAX_INSTALLMENTS,
      currency: this.currency || '',
    };

    this.language$ = this.translateService.langChanges$;

    this.isBaseetaSupported =
      BASEETA_SUPPORTED_COUNTRIES.includes(this.country?.toUpperCase() || '') &&
      BASEETA_SUPPORTED_CURRENCIES.includes(this.currency || '') &&
      SHOW_BASEETA_PROMO;
    // this.monitorQuaraSession();
    this.lessThan$ = this.akitaScreenQuery.selectLessThan();

    this.widgetLocale$ = this.translateService.langChanges$.pipe(
      map((activeLang: string) => {
        let lang = 'en';
        if (`${activeLang || ''}`.toLowerCase() === 'ar') {
          lang = 'ar';
        }
        return lang;
      })
    );
  }
  public ngOnChanges(changes: SimpleChanges): void {
    if (changes?.listingId || changes?.currency || changes?.amount || changes?.source) {
      /* this.monitorQuaraSession();
      this.displayBaseetaPromo(); */
    }
    if (changes) {
      this.isBaseetaSupported =
        BASEETA_SUPPORTED_COUNTRIES.includes(this.country?.toUpperCase() || '') &&
        BASEETA_SUPPORTED_CURRENCIES.includes(this.currency || '') &&
        SHOW_BASEETA_PROMO;

      this.splitAmount = {
        amount: (this.amount || 0) / BASEETA_MAX_INSTALLMENTS,
        currency: this.currency || '',
      };
    }
  }

  public ngAfterViewInit(): void {
    this.isViewInitialized = true;
    // this.displayBaseetaPromo();
  }

  public openQuaraOverlay(): void {
    this.logClickEvent();
    if (this.openQuaraOverlaySubscription) {
      this.openQuaraOverlaySubscription.unsubscribe();
    }

    this.openQuaraOverlaySubscription = this.baseetaOverlayDynamicService.observe
      .pipe(takeUntil(this.destroySubject))
      .subscribe({
        next: (data?: any | null) => {
          this.showingQuaraOverlay = Boolean(data);
          this.changeDetector.markForCheck();
        },
      });
    this.baseetaOverlayDynamicService.open({
      data: {
        drawAmount: this.amount,
        country: this.country,
        currency: this.currency,
        source: this.source,
        mode: 'installments',
      },
      isBrowser: this.akitaRouterQuery.isBrowser,
      // belementRef: this.quaraButtonRef,
    });
  }

  public logClickEvent(): void {
    const trigger =
      this.source === 'product' ? 'Baseeta-product-page' : 'Baseeta-checkout-page';
    logPaymentInfoCardClicked(this.analytics.logEventWrapper, trigger);
  }

  public ngOnDestroy(): void {
    this.destroySubject.next();
    this.destroySubject.complete();

    if (this.subscriptions) {
      this.subscriptions.unsubscribe();
    }
  }
}
