import { inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, throwError, catchError, map } from 'rxjs';
import { getApiEndpoint } from '@app/shared/utils/url.utils';
import { parseApiError } from '@app/shared/models/api/api-error.model';
import { parseTamaraPublicKey, TamaraPublicKey } from '../models/tamara-public-key.model';
import { AkitaAuthQuery } from '../../auth/state/auth.query';
import { Params } from '@angular/router';
import { Product } from '../../products/models/product.model';
import { Address } from '../../addresses/models/address.model';
import { SessionAddressModel } from '../../checkout/models/session-address.model';
import {
  parseTamaraOptionsApiResponse,
  TamaraOptionsApiResponse,
} from '@app/checkout/payment-providers/tamara/models/tamara-options.model';

@Injectable({ providedIn: 'root' })
export class TamaraApiService {
  private readonly http = inject(HttpClient);
  constructor(private readonly akitaAuthQuery: AkitaAuthQuery) {}

  public fetchPublicKey(): Observable<TamaraPublicKey | null> {
    const apiUrl = getApiEndpoint(`api/v2/payment/tamara/publicKey`, true);

    let headers = {};
    // eslint-disable-next-line deprecation/deprecation
    const credentials = btoa(`api:Lax4UpCrx9TAmhea`);
    headers = {
      Authorization: `Basic ${credentials}`,
    };

    return this.http
      .get<any>(
        // Assemble the full API URL
        apiUrl,
        // Add the Options
        {
          responseType: 'json',
          headers: headers,
        }
      )
      .pipe(
        catchError((error: unknown) => throwError(() => parseApiError(error))),
        map((results) => parseTamaraPublicKey(results))
      );
  }

  /**
   * New endpoint for Tamara Options
   * providing user,address and products info
   */
  public checkUserOptions(
    lang: string,
    products: Array<Product>,
    totalAmount: number,
    discount: number,
    address: Address | null, // remove when cleanin delivery flow
    sessionAddress: SessionAddressModel | null,
    email?: string | null,
    name?: string | null,
    phone?: string | null
  ): Observable<TamaraOptionsApiResponse | null> {
    // const countryCode = `${country || 'SA'}`.toUpperCase();
    const apiUrl = getApiEndpoint(`api/v2/payment/tamara/pre-check`, true);

    const headers: Params = {
      'Content-Type': 'application/json; charset=utf-8',
    };
    const accessToken = this.akitaAuthQuery.accessToken;
    if (accessToken) {
      headers.Authorization = `Bearer ${accessToken}`;
    } else {
      // eslint-disable-next-line deprecation/deprecation
      const credentials = btoa(`api:Lax4UpCrx9TAmhea`);
      headers.Authorization = `Basic ${credentials}`;
    }

    const parsedProducts: Array<{ product_id: string; quantity: number }> = new Array(0);
    if (products.length) {
      products.forEach((x) => {
        parsedProducts.push({ product_id: x.id, quantity: x.quantity || 1 });
      });
    }

    const phoneNumber =
      phone || `${address?.phone.countryCode}${address?.phone.national}`;
    const userName = name || `${address?.fullName}`;

    return this.http
      .post<any>(
        // Assemble the full API URL
        apiUrl,
        // Add the Options
        {
          products: parsedProducts,
          amount: totalAmount,
          discount: discount,
          shipping_address: {
            city: `${sessionAddress?.city || address?.city}`,
            address:
              `${sessionAddress?.address} ${sessionAddress?.addressDetails}` ||
              `${address?.address} ${address?.streetName} ${address?.streetNumber}`,
            zip: `${sessionAddress?.postalCode || address?.zipCode}`,
            country: `${sessionAddress?.country || address?.country}`,
          },
          buyer: {
            phone: `${phoneNumber}`,
            email: `${email}`,
            name: `${userName}`,
          },
          language: `${lang}`,
        },

        {
          responseType: 'json',
          headers: headers,
        }
      )
      .pipe(
        catchError((error: unknown) => throwError(() => parseApiError(error))),
        map((results) => parseTamaraOptionsApiResponse(results.available_payment_labels))
      );
  }

  /**
   * previous endpoint to get Tamara options
   */
  public checkOptions(country: string): Observable<TamaraOptionsApiResponse | null> {
    const countryCode = `${country || 'SA'}`.toUpperCase();
    const apiUrl = getApiEndpoint(
      `api/v2/payment/tamara/options?country=${countryCode}`,
      true
    );

    const headers: Params = {
      'Content-Type': 'application/json; charset=utf-8',
    };
    const accessToken = this.akitaAuthQuery.accessToken;
    if (accessToken) {
      headers.Authorization = `Bearer ${accessToken}`;
    } else {
      // eslint-disable-next-line deprecation/deprecation
      const credentials = btoa(`api:Lax4UpCrx9TAmhea`);
      headers.Authorization = `Basic ${credentials}`;
    }

    return this.http
      .get<any>(
        // Assemble the full API URL
        apiUrl,
        // Add the Options
        {
          responseType: 'json',
          headers: headers,
        }
      )
      .pipe(
        catchError((error: unknown) => throwError(() => parseApiError(error))),
        map((results) => parseTamaraOptionsApiResponse(results))
      );
  }
}
