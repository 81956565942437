import { inject, Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, throwError, catchError, map } from 'rxjs';
import { getApiEndpoint } from '@app/shared/utils/url.utils';
import { parseApiError } from '@app/shared/models/api/api-error.model';
import { StartingPriceModel } from '@app/shared/models/api/starting-price.model';

@Injectable({ providedIn: 'root' })
export class SearchStartingPricesApiService {
  private readonly http = inject(HttpClient);
  constructor() {}

  public fetchStartingPrices(
    country?: string | null
  ): Observable<Array<StartingPriceModel>> {
    const credentials = btoa(`api:Lax4UpCrx9TAmhea`); // this.akitaAuthQuery.accessToken || null;
    const apiUrl: string = getApiEndpoint(
      'api/v2/product/price/cheapest?sorting=RANKED_BY_SALES',
      true
    );

    if (!country) {
      return throwError(() => new Error('Missing Country Param'));
    }
    let params = new HttpParams();
    params = params.set('country', country);

    let headers = {};
    if (credentials) {
      headers = {
        Authorization: `Basic ${credentials}`,
      };
    }

    return this.http
      .get<any>(
        // Assemble the full API URL
        apiUrl,
        // Add the Options
        {
          responseType: 'json',
          headers: headers,
          params: params,
        }
      )
      .pipe(
        catchError((error: unknown) => throwError(() => parseApiError(error))),
        map((data) => StartingPriceModel.fromJsonArray(data))
      );
  }
}
