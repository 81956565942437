<ng-container *transloco="let t">
  <span class="text-highlight">{{ t('title_subscribe_newsletter') }}</span>
  <span class="fixed-space-16" aria-hidden="true"></span>
  <div
    class="subscribe-box-component popsy-button pill"
    [class.focus]="inFocus"
    [class.mobileL]="(biggerThan$ | async)?.mobileL"
    (click)="inFocus = true">
    <div class="text">
      <ng-container *ngIf="subscribeType !== 'location'">
        <input
          data-clarity-unmask
          #subscribeField
          type="email"
          inputmode="email"
          class="field subscribe-field"
          [class.focus]="inFocus"
          role="subscribe"
          [attr.aria-label]="t('hint_subscribe')"
          (focus)="inFocus = true"
          (focusout)="inFocus = false"
          [class.error]="
            submitted &&
            (formControl.hasError('required') || formControl.hasError('email'))
          "
          [formControl]="formControl"
          [placeholder]="t('hint_email')"
          (keydown.enter)="onSubscribeToNewsletter()" />
      </ng-container>
      <ng-container *ngIf="subscribeType !== 'location' && queryParams?.text">
        <a
          role="button"
          class="clear-subscribe-button"
          [attr.aria-label]="t('aria_filter_disable')">
          <popsy-icon-remove class="icon closeIcon gray-01"></popsy-icon-remove>
        </a>
      </ng-container>
    </div>

    <popsy-icon-keyboard-arrow-right
      *ngIf="!rtlMode"
      class="subscribe-icon"
      (click)="onSubscribeToNewsletter()"></popsy-icon-keyboard-arrow-right>
    <popsy-icon-keyboard-arrow-left
      *ngIf="rtlMode"
      class="subscribe-icon"
      (click)="onSubscribeToNewsletter()"></popsy-icon-keyboard-arrow-left>
  </div>
  <span class="fixed-space-8" aria-hidden="true"></span>
  <ng-container
    *ngIf="
      submitted && (formControl.hasError('required') || formControl.hasError('email'))
    ">
    <span class="text-highlight small">{{ t('error_email_not_valid') }}</span>
  </ng-container>
  <ng-container
    *ngIf="
      submitted &&
      !(subscribed$ | async) &&
      !(formControl.hasError('required') || formControl.hasError('email'))
    ">
    <span class="text-highlight small">{{ t('error_reset_password') }}</span>
  </ng-container>
  <ng-container *ngIf="subscribed$ | async">
    <span class="text-highlight small">
      {{ t('label_subscribe_newsletter_success') }}
    </span>
  </ng-container>
</ng-container>
