/* eslint-disable max-len */
export const imagesMockData = {
  macbook:
    'https://firebasestorage.googleapis.com/v0/b/popsygae.appspot.com/o/laptop-images%2FMacBook%20A2179%2FMacBook-A2179-1.jpg?alt=media&token=d23c1c83-da0c-4e23-8973-862f4f26b6ff',
  phone:
    'https://firebasestorage.googleapis.com/v0/b/popsygae.appspot.com/o/Smartphone%20Images%2FiPhone%2013%20Pro%20Max%2FGraphite%2FBlack.jpg?alt=media&token=19a9ddd3-4248-490b-ab1e-077f82a64623',
  ipad: 'https://firebasestorage.googleapis.com/v0/b/popsygae.appspot.com/o/Smartphone%20Images%2FiPad%20Pro%203rd%20Gen%2FSilver%2FiPad-Pro-3rd-Gen-Silver-1.jpg?alt=media&token=b52530ac-89f2-4eff-966e-84c6e0c98f45',
  airpod:
    'https://firebasestorage.googleapis.com/v0/b/popsygae.appspot.com/o/Accessories%2FAirPods%203rd%20Gen%20(2021)%2F1.jpg?alt=media&token=bd2d1eb9-284d-4732-be45-436972f45672',
  accessory:
    'https://firebasestorage.googleapis.com/v0/b/popsygae.appspot.com/o/Charger%2FMAGSAFE%20Charger%2F1.jpg?alt=media&token=6f8addcc-752a-4cf8-a366-f6cf1afc074d',
  watch:
    'https://firebasestorage.googleapis.com/v0/b/popsygae.appspot.com/o/watch%2Fapple%2Fwatch-series-se-2023-gps-cellular-40mm%2Fsilver-aluminum%2F2.jpg?alt=media&token=1e020c92-8441-4c6b-adad-f896c5d710d0',
  gaming:
    'https://firebasestorage.googleapis.com/v0/b/popsygae.appspot.com/o/playstation%2FPlayStation-4-Pro%2Fblack%2F1.jpg?alt=media&token=22b5dea9-f4f9-4eed-b8c0-98ae6bc0ac65',
  samsung:
    'https://firebasestorage.googleapis.com/v0/b/popsygae.appspot.com/o/smartphone-images%2Fgalaxy-s21%2Fblack%2F2.jpg?alt=media&token=b9469942-df02-49c3-8064-abefe9418f0c',
  laptop:
    'https://firebasestorage.googleapis.com/v0/b/popsygae.appspot.com/o/laptop-images%2FChromeBook%20100e%2FBlack%2F1.jpg?alt=media&token=ed5f420d-fa00-4cc8-a2b8-6bbecf6c6a73',
  tablet:
    'https://firebasestorage.googleapis.com/v0/b/popsygae.appspot.com/o/tablet%2Fgalaxy-tab-s9-fe-fan-edition%2Fgrey%2F2.jpg?alt=media&token=5c199095-2c29-4116-b696-6a49a6fb0706',
  audio:
    'https://firebasestorage.googleapis.com/v0/b/popsygae.appspot.com/o/Accessories%2FAWEI%20A790BL%20Bluetooth%20Wireless%20Headphone%20With%20Microphone%2F1.jpg?alt=media&token=da863f41-bd59-4916-9b12-7ccb946a9156',
};
