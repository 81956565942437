/* eslint-disable max-len */

export const TRADEIN_FAQ_DATA = [
  {
    open: false,
    question: 'How does the Popsy Trade-in process work?',
    answer: `
              <p class="c8"><span class="c3">It&#39;s simple! Here&#39;s how:</span></p>
    <ol class="c6 lst-kix_tbhxqhymmjd8-0 start" start="1">
      <li class="c4 li-bullet-0">
        <span class="c3">Complete an online assessment of your device to get an instant price offer.</span>
      </li>
      <li class="c4 li-bullet-0">
        <span class="c3"
          >Use our prepaid shipping label to send your device to the refurbisher for free.</span
        >
      </li>
      <li class="c4 li-bullet-0">
        <span class="c3">Once the refurbisher receives and inspects your device:</span>
      </li>
    </ol>
    <ul class="c6 lst-kix_tbhxqhymmjd8-1 start">
      <li class="c8 c10 li-bullet-0">
        <span class="c3">If the condition matches the assessment, you get paid via direct deposit.</span>
      </li>
      <li class="c8 c10 li-bullet-0">
        <span class="c3"
          >If there&#39;s a mismatch, you&rsquo;ll receive a counteroffer to accept or reject. If you reject
          it, the device will be returned to you for free.</span
        >
      </li>
    </ul>  `,
    questionAr: '١. كيف يعمل نظام استبدال الأجهزة في Popsy ؟',
    answerAr: `
    <p class="c17" dir="rtl"><span class="c0">بسيط! إليك كيف:</span></p>
      <ul class="c24 lst-kix_prsvlff0hgx2-0 start"><li class="c8 li-bullet-0" dir="rtl"><span class="c0">أولاً، قم بتقييم جهازك عبر الإنترنت للحصول على عرض سعر فوري.</span></li><li class="c8 li-bullet-0" dir="rtl"><span class="c0">استخدم ملصق الشحن المدفوع مسبقاً لإرسال جهازك إلى مكان التجديد مجاناً.</span></li><li class="c8 li-bullet-0" dir="rtl"><span class="c0">بمجرد أن يتسلم المُجدّد جهازك ويفحصه:</span></li><li class="c4 li-bullet-0" dir="rtl"><span class="c0">إذا كان الجهاز في نفس الحالة التي تم تقييمها، سيتم دفع المبلغ لك عن طريق التحويل المباشر.</span></li><li class="c1 li-bullet-0" dir="rtl"><span class="c0">إذا كان هناك اختلاف، ستتلقى عرضاً مضاداً للموافقة عليه أو رفضه. وإذا رفضت العرض، سيتم إرجاع الجهاز إليك مجاناً.</span></li></ul>
     `,
  },
  {
    open: false,
    question: ' What happens to my data? Is it safe?',
    answer: `
                <p class="c8">
      <span class="c3"
        >Your privacy is our priority. All devices undergo a full data wipe by professional refurbishers to
        ensure your data is securely deleted. However, we recommend:</span
      >
    </p>
    <ul class="c6 lst-kix_97d0c9kvgxh1-0 start">
      <li class="c4 li-bullet-0"><span class="c3">Backing up your data.</span></li>
      <li class="c4 li-bullet-0">
        <span>Removing all accounts and personal information from the device before shipping it.<br /></span
        ><span class="c2">Note: Once sold, we cannot recover any data from your device.</span>
      </li>
    </ul>       
                  `,
    questionAr: '٢. ماذا يحدث لبياناتي؟ هل هي آمنة؟',
    answerAr: `
    <ul class="c24 lst-kix_sfje62tyy0un-0 start"><li class="c10 c18 li-bullet-0" dir="rtl"><span class="c0">خصوصيتك هي أولويتنا. جميع الأجهزة تخضع لعملية مسح كامل للبيانات بواسطة مجددين محترفين لضمان حذف بياناتك بأمان. ومع ذلك، ننصحك بـ:</span></li><li class="c10 c18 li-bullet-0" dir="rtl"><span class="c0">نسخ بياناتك احتياطياً.</span></li><li class="c10 c18 li-bullet-0" dir="rtl"><span class="c0">إزالة جميع الحسابات والمعلومات الشخصية من الجهاز قبل شحنه. ملاحظة: بمجرد بيع الجهاز، لا يمكننا استرجاع بياناتك.</span></li></ul>
    `,
  },
  {
    open: false,
    question: 'How is the price of my device determined?',
    answer: `
               <p class="c8">
      <span class="c3"
        >Our system connects with expert refurbishers to offer you a competitive price. The price depends
        on:</span
      >
    </p>
    <ul class="c6 lst-kix_xyixmdi9rolb-0 start">
      <li class="c4 li-bullet-0">
        <span class="c3">Your answers during the online assessment about your device&#39;s condition.</span>
      </li>
      <li class="c4 li-bullet-0">
        <span class="c3"
          >A physical inspection by the refurbisher upon receipt.<br />If there&#39;s a discrepancy between
          your assessment and their inspection, you&#39;ll get a counteroffer.</span
        >
      </li>
    </ul>           
`,
    questionAr: '٣. كيف يتم تحديد سعر جهازي؟',
    answerAr: `
          <p class="c10" dir="rtl"><span class="c0">نظامنا يتصل بمُجددين محترفين لتقديم عرض سعر تنافسي لك. السعر يعتمد على:</span></p>
          <ul class="c24 lst-kix_6yv1s6nmb4cd-0 start"><li class="c10 c18 li-bullet-0" dir="rtl"><span class="c0">إجاباتك أثناء تقييم الجهاز عبر الإنترنت.</span></li><li class="c10 c18 li-bullet-0" dir="rtl"><span class="c0">فحص مادي للجهاز عند استلامه. إذا كان هناك اختلاف بين التقييم والفحص، ستتلقى عرضاً مضاداً.</span></li></ul>
    `,
  },
  {
    open: false,
    question: 'What if the final quote is lower than the initial offer?',
    answer: `
            <p class="c8">
      <span class="c3"
        >You&rsquo;re in control! If the refurbisher proposes a lower offer due to discrepancies in the
        device&#39;s condition:</span
      >
    </p>
    <ul class="c6 lst-kix_l4bzp6v5t4z-0 start">
      <li class="c4 li-bullet-0">
        <span class="c3">You can accept the counteroffer and proceed with payment.</span>
      </li>
      <li class="c4 li-bullet-0">
        <span class="c3"
          >You can reject the offer, and your device will be shipped back to you free of charge.</span
        >
      </li>
    </ul>  
 `,
    questionAr: '٤. ماذا لو كان السعر النهائي أقل من العرض الأولي؟',
    answerAr: `
    <p class="c10" dir="rtl"><span class="c0">أنت المتحكم! إذا قدم المُجدد عرضاً أقل بسبب اختلاف في حالة الجهاز:</span></p>
    <ul class="c24 lst-kix_ja4084dn3o0s-0 start"><li class="c1 li-bullet-0" dir="rtl"><span class="c0">يمكنك قبول العرض المضاد واستكمال الدفع.</span></li><li class="c1 li-bullet-0" dir="rtl"><span class="c0">يمكنك رفض العرض، وسيتم إعادة جهازك إليك مجاناً.</span></li></ul>
    `,
  },
  {
    open: false,
    question: 'How long does it take to get paid?',
    answer: `
             <p class="c8">
      <span class="c3">Once the refurbisher confirms that your device matches the assessment:</span>
    </p>
    <ul class="c6 lst-kix_hx9xfu1xjdxx-0 start">
      <li class="c4 li-bullet-0"><span class="c3">Payment is processed immediately.</span></li>
      <li class="c4 li-bullet-0">
        <span>Funds will appear in your Popsy wallet or bank account within </span
        ><span class="c13">3-6 business days</span><span class="c3">, depending on your bank.</span>
      </li>
    </ul>
            `,
    questionAr: '٥. كم من الوقت يستغرق الحصول على الدفعة؟',
    answerAr: `
    <p class="c22" dir="rtl"><span class="c0">بمجرد أن يؤكد المُجدد أن جهازك مطابق للتقييم:</span></p>
    <ul class="c24 lst-kix_x8xgskzxaie-0 start"><li class="c1 li-bullet-0" dir="rtl"><span class="c0">سيتم معالجة الدفع فوراً.</span></li><li class="c1 li-bullet-0" dir="rtl"><span class="c0">ستظهر الأموال في محفظتك في Popsy أو حسابك البنكي خلال ٣-٦ أيام عمل، حسب البنك.</span></li></ul>
    `,
  },
  {
    open: false,
    question: ' What types of devices can I trade in?',
    answer: `
    <p class="c8">
      <span class="c3"
        >You can trade in a wide range of smartphones, including older and newer models. If you&#39;re unsure
        whether your device qualifies, simply start the assessment, and we&rsquo;ll let you know.</span
      >
    </p>
    `,
    questionAr: '٦. ما هي أنواع الأجهزة التي يمكن استبدالها؟',
    answerAr: `
    <p class="c10" dir="rtl"><span class="c0">يمكنك استبدال مجموعة واسعة من الهواتف الذكية، بما في ذلك الطرازات القديمة والجديدة. إذا كنت غير متأكد مما </span></p>
    <p class="c10" dir="rtl"><span class="c0">إذا كان جهازك مؤهلاً، فقط ابدأ التقييم و سنعلمكم بذلك.</span></p>
      
    `,
  },
  {
    open: false,
    question: 'How does the free shipping work?',
    answer: `
    <p class="c8">
      <span class="c3"
        >We provide a prepaid shipping label for you to send your device to the refurbisher. Just package your
        device securely, attach the label, and drop it off at the designated shipping center.</span
      >
    </p>
    `,
    questionAr: '٧. كيف يعمل الشحن المجاني؟',
    answerAr: `
      <p class="c10" dir="rtl"><span class="c0">نوفر لك ملصق شحن مدفوع مسبقاً لإرسال جهازك إلى المُجدد. فقط قم بتغليف جهازك بشكل آمن، ضع الملصق عليه، وأرسله إلى مركز الشحن المحدد.</span></p>
    `,
  },
  {
    open: false,
    question: ' Why should I trade in with Popsy instead of selling elsewhere?',
    answer: `
   <p class="c8"><span class="c3">Popsy Trade-in offers:</span></p>
    <ul class="c6 lst-kix_e8pgfz7kvyiq-0 start">
      <li class="c4 li-bullet-0"><span class="c3">Instant, competitive price quotes.</span></li>
      <li class="c4 li-bullet-0"><span class="c3">Free shipping and hassle-free transactions.</span></li>
      <li class="c4 li-bullet-0"><span class="c3">Direct cash payments (not store credit).</span></li>
      <li class="c4 li-bullet-0">
        <span class="c3">A sustainable option to reduce e-waste by refurbishing old devices.</span>
      </li>
    </ul>
    `,
    questionAr: '٨. لماذا يجب أن أستبدل مع Popsy بدلاً من بيعه في مكان آخر؟',
    answerAr: `
      <p class="c10" dir="rtl"><span class="c0">استبدال الأجهزة مع Popsy &nbsp;يقدم:</span></p>
      <ul class="c24 lst-kix_xrerpq4vqk2e-0 start"><li class="c1 li-bullet-0" dir="rtl"><span class="c0">عروض أسعار فورية ومنافسة.</span></li><li class="c1 li-bullet-0" dir="rtl"><span class="c0">شحن مجاني دون متاعب.</span></li><li class="c1 li-bullet-0" dir="rtl"><span class="c0">دفعات نقدية مباشرة (وليس رصيد متجر).</span></li><li class="c1 li-bullet-0" dir="rtl"><span class="c0">خيار مستدام لتقليل النفايات الإلكترونية من خلال تجديد الأجهزة القديمة.</span></li></ul>
    `,
  },
  {
    open: false,
    question: 'Can I trust Popsy with my device?',
    answer: `
     <p class="c8">
      <span class="c3"
        >Absolutely! Popsy partners exclusively with professional refurbishers who are committed to security,
        quality, and integrity. They rigorously follow industry standards to ensure your device is handled
        responsibly, and your data is securely erased. If you&#39;re unsatisfied at any point, you&#39;re free
        to cancel the process and have your device returned for free.</span
      >
    </p>
    `,
    questionAr: '٩. هل يمكنني الوثوق بـ Popsy مع جهازي؟',
    answerAr: `
      <p class="c10" dir="rtl"><span class="c0">بالطبع! تتعاون Popsy حصرياً مع مجددين محترفين يلتزمون بالأمن والجودة والنزاهة. يتبعون معايير صناعية صارمة لضمان التعامل مع جهازك بشكل مسؤول، وحذف بياناتك بأمان. إذا لم تكن راضياً في أي لحظة، يمكنك إلغاء العملية وإعادة جهازك إليك مجاناً.</span></p>
    `,
  },
];
