export class TabbyBuyer {
  public id?: string | null;
  public phone: string;
  public email: string;
  public name: string;
  public dob?: string | null;

  constructor() {
    this.id = null;
    this.phone = '';
    this.email = '';
    this.name = '';
    this.dob = null;
  }
}

export const parseTabbyBuyer = (data?: Record<string, any> | null): TabbyBuyer | null => {
  let result: TabbyBuyer | null = null;
  if (data) {
    result = new TabbyBuyer();
    result.id = data.id || null;
    result.phone = data.phone || '';
    result.email = data.email || '';
    result.name = data.name || '';
    result.dob = data.dob || null;
  }
  return result;
};
