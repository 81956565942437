<ng-container *transloco="let t">
  <section
    class="buyback-highlights"
    [class.widescreen]="(biggerThan$ | async)?.sm"
    [class.desktop]="(biggerThan$ | async)?.mobileL">
    <ul class="list" [class.center-align]="centerAlign">
      <li class="item" [class.center-align]="centerAlign">
        <popsy-icon-trade-icon-1 class="icon" [class.fill-gray-light]="darkMode" />
        <span class="fixed-space-8" aria-hidden="true"></span>
        @if (language === 'ar') {
          <span [class.text-gray-light]="darkMode" class="label">
            احصل على تقدير فوري
          </span>
        } @else {
          <span [class.text-gray-light]="darkMode" class="label"> Instant quote</span>
        }
      </li>

      <span class="fixed-space-16" aria-hidden="true"></span>

      <li class="item" [class.center-align]="centerAlign">
        <popsy-icon-trade-icon-2 class="icon" />
        <span class="fixed-space-8" aria-hidden="true"></span>

        @if (language === 'ar') {
          <span [class.text-gray-light]="darkMode" class="label"> شحن مجاني </span>
        } @else {
          <span [class.text-gray-light]="darkMode" class="label"> Ship for Free</span>
        }
      </li>

      <span class="fixed-space-16" aria-hidden="true"></span>

      <li class="item" [class.center-align]="centerAlign">
        <popsy-icon-trade-icon-3 class="icon" />
        <span class="fixed-space-8" aria-hidden="true"></span>
        @if (language === 'ar') {
          <span [class.text-gray-light]="darkMode" class="label"> سداد سريع </span>
        } @else {
          <span [class.text-gray-light]="darkMode" class="label"> Get Paid Fast </span>
        }
      </li>
    </ul>
  </section>
</ng-container>
