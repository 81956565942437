import { Model } from '../model.interface';
import { PriceModel } from './price.model';

/**
 * Class used to represent a startingPrice Object
 */
export class StartingPriceModel implements Model {
  public id: number | null;
  public brand: string;
  public model: string;
  public category: string;
  public image: string;
  public price: PriceModel;
  public originalPrice: PriceModel;
  public inStock: boolean;

  /**
   * Constructor to initialize the properties
   * - Make sure the properties are initialized (to avoid unexpected undefined values)
   * @constructor
   */
  constructor() {
    this.id = null;
    this.brand = '';
    this.model = '';
    this.category = '';
    this.image = '';
    this.price = new PriceModel();
    this.originalPrice = new PriceModel();
    this.inStock = false;
  }

  /**
   * Helper function to sanitize the data that comes from the Backend.
   *
   * @param data The Raw JSON Object
   * @returns {StartingPriceModel} An instance of the StartingPrice class.
   */
  public static fromJson(data?: Record<string, any> | null): StartingPriceModel | null {
    let startingPrice: StartingPriceModel | null = null;

    if (data) {
      startingPrice = new StartingPriceModel();
      startingPrice.model = data.model;
      startingPrice.id = data.reference.id || null;
      startingPrice.category = data.category || '';
      startingPrice.image = data.image || '';
      startingPrice.brand = data.brand;
      startingPrice.price = PriceModel.fromJson(data.price) || new PriceModel();
      startingPrice.originalPrice =
        PriceModel.fromJson(data.reference?.original_price) || new PriceModel();
      startingPrice.inStock = Boolean(data.in_stock || data.inStock) || false;
    }

    return startingPrice;
  }

  /**
   * Helper function to sanitize an array of data that comes from the Backend.
   * @param data The Raw JSON Array
   * @returns {Array<StartingPriceModel>} An Array of StartingPrice instances.
   */
  public static fromJsonArray(
    data?: Array<Record<string, any>> | null
  ): Array<StartingPriceModel> {
    const startingPrices: Array<StartingPriceModel> = new Array(0);

    // Ensure data is not null, and that is an array
    if (data && data.length > 0) {
      for (const startingPriceData of data) {
        const startingPrice: StartingPriceModel | null =
          StartingPriceModel.fromJson(startingPriceData);
        if (startingPrice) {
          startingPrices.push(startingPrice);
        }
      }
    }

    return startingPrices;
  }
}
