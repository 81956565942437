import { StoreConfig, Store } from '@datorama/akita';
import { Injectable } from '@angular/core';
import {
  AkitaPaymentState,
  createInitialPaymentApiTokenState,
  createInitialPaymentState,
} from '../models/payment.state';
import { ApiError } from '@app/shared/models/api/api-error.model';

import { environment } from '@environments/environment';
import {
  TabbySessionApiResponse,
  parseTabbySessionApiResponse,
} from '@app/checkout/payment-providers/tabby/models/tabby-session.model';
import {
  TamaraPaymentOption,
  parseTamaraPaymentOptionList,
} from '@app/checkout/payment-providers/tamara/models/tamara-payment-option.model';

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'payment', resettable: true })
export class AkitaPaymentStore extends Store<AkitaPaymentState> {
  constructor() {
    super(createInitialPaymentState());
  }

  public setCurrency(currency?: string | null): void {
    this.update({
      currency: currency || null,
      lastUpdated: new Date(),
    });
  }

  public setTabbyPublicKey(publicKey?: string | null): void {
    this.update({
      tabbyPublicKey: `${publicKey || environment.tabby.pubKey || ''}`,
      lastUpdated: new Date(),
    });
  }

  public toggleGetTabbyPublicKey(isLoading?: boolean | null): void {
    this.update({
      fetchingTabbyPublicKey: Boolean(isLoading),
      lastUpdated: new Date(),
    });
  }

  public setGetTabbyPublicKeyError(error?: ApiError | null): void {
    this.update({
      errorFetchingTabbyPublicKey: error || null,
      lastUpdated: new Date(),
    });
  }

  public setTamaraPublicKey(publicKey?: string | null): void {
    this.update({
      tamaraPublicKey: `${publicKey || environment.tamara.pubKey || ''}`,
      lastUpdated: new Date(),
    });
  }

  public toggleGetTamaraPublicKey(isLoading?: boolean | null): void {
    this.update({
      fetchingTamaraPublicKey: Boolean(isLoading),
      lastUpdated: new Date(),
    });
  }

  public setGetTamaraPublicKeyError(error?: ApiError | null): void {
    this.update({
      errorFetchingTamaraPublicKey: error || null,
      lastUpdated: new Date(),
    });
  }

  public setCheckoutDotComPublicKey(publicKey?: string | null): void {
    this.update({
      checkoutDotComPublicKey: `${publicKey || environment.tabby.pubKey || ''}`,
      lastUpdated: new Date(),
    });
  }

  public toggleGetCheckoutDotComPublicKey(isLoading?: boolean | null): void {
    this.update({
      fetchingCheckoutDotComPublicKey: Boolean(isLoading),
      lastUpdated: new Date(),
    });
  }

  public setGetCheckoutDotComPublicKeyError(error?: ApiError | null): void {
    this.update({
      errorFetchingCheckoutDotComPublicKey: error || null,
      lastUpdated: new Date(),
    });
  }

  public toggleGeneratingStripeCheckoutSession(isGenerating?: boolean | null): void {
    this.update({
      generatingStripeCheckoutSession: Boolean(isGenerating),
      lastUpdated: new Date(),
    });
  }

  public setGeneratingStripeCheckoutSessionError(error?: ApiError | null): void {
    this.update({
      errorGeneratingStripeCheckoutSession: error || null,
      lastUpdated: new Date(),
    });
  }

  public setTabbySession(
    listingId?: string | null,
    session?: TabbySessionApiResponse | null
  ): void {
    if (listingId) {
      this.update((state: AkitaPaymentState) => {
        const tabbySessions = { ...(state.tabbySession || {}) };
        tabbySessions[listingId] = parseTabbySessionApiResponse(session) || null;
        return {
          tabbySession: tabbySessions,
          lastUpdated: new Date(),
        };
      });
    }
  }

  public toggleGeneratingTabbySession(
    listingId?: string | null,
    isGenerating?: boolean | null
  ): void {
    if (listingId) {
      this.update((state: AkitaPaymentState) => {
        const generatingTabbySessions = { ...(state.generatingTabbySession || {}) };
        generatingTabbySessions[listingId] = Boolean(isGenerating);
        return {
          generatingTabbySession: generatingTabbySessions,
          lastUpdated: new Date(),
        };
      });
    }
  }

  public setGeneratingTabbySessionError(
    listingId?: string | null,
    error?: ApiError | null
  ): void {
    if (listingId) {
      this.update((state: AkitaPaymentState) => {
        const errorGeneratingTabbySessions = {
          ...(state.errorGeneratingTabbySession || {}),
        };
        errorGeneratingTabbySessions[listingId] = error || null;
        return {
          errorGeneratingTabbySession: errorGeneratingTabbySessions,
          lastUpdated: new Date(),
        };
      });
    }
  }

  public setTamaraPaymentOptions(
    countryCode?: string | null,
    options?: Array<TamaraPaymentOption> | null
  ): void {
    if (countryCode) {
      this.update((state: AkitaPaymentState) => {
        const paymentOptions = { ...(state.tamaraPaymentOptions || {}) };
        paymentOptions[countryCode] = parseTamaraPaymentOptionList(options) || null;
        return {
          tamaraPaymentOptions: paymentOptions,
          lastUpdated: new Date(),
        };
      });
    }
  }

  public toggleFetchingTamaraPaymentOptions(
    countryCode?: string | null,
    isFetching?: boolean | null
  ): void {
    if (countryCode) {
      this.update((state: AkitaPaymentState) => {
        const fetchingTamaraPaymentOptions = {
          ...(state.fetchingTamaraPaymentOptions || {}),
        };
        fetchingTamaraPaymentOptions[countryCode] = Boolean(isFetching);
        return {
          fetchingTamaraPaymentOptions: fetchingTamaraPaymentOptions,
          lastUpdated: new Date(),
        };
      });
    }
  }

  public setFetchingTamaraPaymentOptionsError(
    countryCode?: string | null,
    error?: ApiError | null
  ): void {
    if (countryCode) {
      this.update((state: AkitaPaymentState) => {
        const errorFetchingTamaraPaymentOptions = {
          ...(state.errorFetchingTamaraPaymentOptions || {}),
        };
        errorFetchingTamaraPaymentOptions[countryCode] = error || null;
        return {
          errorFetchingTamaraPaymentOptions: errorFetchingTamaraPaymentOptions,
          lastUpdated: new Date(),
        };
      });
    }
  }

  public setApplePaySession(session?: any | null): void {
    this.update({
      applePaySession: session || null,
      lastUpdated: new Date(),
    });
  }

  public toggleValidatingApplePaySession(isValidating?: boolean | null): void {
    this.update({
      validatingApplePaySession: Boolean(isValidating),
      lastUpdated: new Date(),
    });
  }

  public setValidatingApplePaySessionError(error?: ApiError | null): void {
    this.update({
      errorValidatingApplePaySession: error || null,
      lastUpdated: new Date(),
    });
  }

  public setStripeApiToken(currency: string, token?: string | null): void {
    this.update((state: AkitaPaymentState) => {
      const stateApiTokens = { ...state.apiTokens };
      stateApiTokens[currency] =
        stateApiTokens[currency] || createInitialPaymentApiTokenState(currency);
      stateApiTokens[currency].stripeApiToken = token || null;

      return {
        apiTokens: stateApiTokens,
        lastUpdated: new Date(),
      };
    });
  }

  public toggleGetStripeApiToken(currency: string, isLoading?: boolean | null): void {
    this.update((state: AkitaPaymentState) => {
      const stateApiTokens = { ...state.apiTokens };
      stateApiTokens[currency] =
        stateApiTokens[currency] || createInitialPaymentApiTokenState(currency);
      stateApiTokens[currency].gettingStripeApiToken = Boolean(isLoading);

      return {
        apiTokens: stateApiTokens,
        lastUpdated: new Date(),
      };
    });
  }

  public setGetStripeApiTokenError(currency: string, error?: ApiError | null): void {
    this.update((state: AkitaPaymentState) => {
      const stateApiTokens = { ...state.apiTokens };
      stateApiTokens[currency] =
        stateApiTokens[currency] || createInitialPaymentApiTokenState(currency);
      stateApiTokens[currency].getStripeApiTokenError = error || null;

      return {
        apiTokens: stateApiTokens,
        lastUpdated: new Date(),
      };
    });
  }

  public setSetupIntentSecret(currency: string, token?: string | null): void {
    this.update((state: AkitaPaymentState) => {
      const setupIntentSecrets = { ...state.setupIntentSecrets };
      setupIntentSecrets[currency] = token || null;

      return {
        setupIntentSecrets: setupIntentSecrets,
        lastUpdated: new Date(),
      };
    });
  }

  public toggleGetSetupIntentSecret(currency: string, isLoading?: boolean | null): void {
    this.update((state: AkitaPaymentState) => {
      const gettingSetupIntentSecrets = { ...state.gettingSetupIntentSecrets };
      gettingSetupIntentSecrets[currency] = Boolean(isLoading);

      return {
        gettingSetupIntentSecrets: gettingSetupIntentSecrets,
        lastUpdated: new Date(),
      };
    });
  }

  public setGetSetupIntentSecretError(currency: string, error?: ApiError | null): void {
    this.update((state: AkitaPaymentState) => {
      const gettingSetupIntentSecretsError = { ...state.gettingSetupIntentSecretsError };
      gettingSetupIntentSecretsError[currency] = error || null;

      return {
        gettingSetupIntentSecretsError: gettingSetupIntentSecretsError,
        lastUpdated: new Date(),
      };
    });
  }
}
