import { guid } from '@datorama/akita';
import { SessionAddressModel } from '../../checkout/models/session-address.model';
import { PhoneInfo, parsePhoneInfo } from './phone.model';

export class UserDetails {
  public id?: string;
  public description: string;
  public defaultDetails: boolean;
  public address: SessionAddressModel;
  public contactDetails: PhoneInfo;
  public fullName: string;
  public nationalId: string;
  public email: string;

  constructor() {
    this.id = guid();
    this.description = '';
    this.defaultDetails = false;
    this.address = new SessionAddressModel();
    this.contactDetails = new PhoneInfo();
    this.fullName = '';
    this.nationalId = '';
    this.email = '';
  }
}

export const parseUserDetails = (data?: any | null): UserDetails => {
  const out = new UserDetails();
  if (data) {
    out.id = `${data.id || guid()}`;
    out.description = `${data.description || ''}`;
    out.defaultDetails = !!data.defaultDetails || !!data.default_details || false;
    out.address = SessionAddressModel.fromJson(data.address);
    out.contactDetails = parsePhoneInfo(data.contactDetails || data.contact_details);
    out.fullName = `${data.full_name || data.fullName || ''}`;
    out.nationalId = `${data.national_id || data.nationalId || ''}`;
    out.email = `${data.email || ''}`;
  }
  return out;
};

export const parseUserDetailsList = (data?: Array<any> | null): Array<UserDetails> => {
  const out: Array<UserDetails> = [];
  if (data) {
    data.forEach((item) => {
      out.push(parseUserDetails(item));
    });
  }
  return out;
};
