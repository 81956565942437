import { Params } from '@angular/router';
import {
  TabbyBuyer,
  parseTabbyBuyer,
} from '@app/checkout/payment-providers/tabby/models/tabby-buyer.model';
import {
  TabbyMerchantUrls,
  parseTabbyMerchantUrls,
} from '@app/checkout/payment-providers/tabby/models/tabby-configuration.model';
import {
  TabbyShippingAddress,
  parseTabbyShippingAddress,
} from '@app/checkout/payment-providers/tabby/models/tabby-shipping-address.model';

export class TabbySessionApiRequest {
  public listingId: string;
  public products: Array<any>;
  public language: string;
  public amount: number;
  public shippingAddress: TabbyShippingAddress;
  public buyer: TabbyBuyer;
  public merchantUrls: TabbyMerchantUrls;
  public discountAmount?: number | null;

  constructor() {
    this.listingId = '';
    this.products = new Array(0);
    this.language = '';
    this.amount = 0;
    this.shippingAddress = new TabbyShippingAddress();
    this.buyer = new TabbyBuyer();
    this.merchantUrls = new TabbyMerchantUrls();
    this.discountAmount = null;
  }
}

export const parseTabbySessionApiRequest = (
  data?: any | null
): TabbySessionApiRequest => {
  const request = new TabbySessionApiRequest();
  if (data) {
    request.listingId = data.listing_id || data.listingId || '';
    request.products = data.products;
    request.language = data.language || '';
    request.amount = Number(`${data.amount || '0'}`);
    request.shippingAddress =
      parseTabbyShippingAddress(data.shipping_address || data.shippingAddress) ||
      new TabbyShippingAddress();
    request.buyer = parseTabbyBuyer(data.buyer) || new TabbyBuyer();
    request.merchantUrls =
      parseTabbyMerchantUrls(data.merchant_urls || data.merchantUrls) ||
      new TabbyMerchantUrls();
    request.discountAmount = Number(
      `${data.discount_amount || data.discountAmount || '0'}`
    );
  }
  return request;
};

export const generateTabbySessionApiRequest = (
  request: TabbySessionApiRequest
): Params => ({
  language: request.language || undefined,
  listing_id: `${request.listingId || ''}` || undefined,
  products: request.products || undefined,
  amount: Number(`${request.amount || 0}`),
  discount: Number(`${request.discountAmount || 0}` || undefined),
  shipping_address: {
    city: `${request.shippingAddress?.city || ''}` || undefined,
    address: `${request.shippingAddress?.address || ''}` || undefined,
    zip: `${request.shippingAddress?.zip || ''}` || undefined,
    country: `${request.shippingAddress?.country || ''}` || undefined,
  },
  buyer: {
    // testing not sending buyer details
    /* id: `${request.buyer?.id || ''}` || undefined,
    phone: `${request.buyer?.phone || ''}` || undefined,
    email: `${request.buyer?.email || ''}` || undefined,
    name: `${request.buyer?.name || ''}` || undefined,
    dob: `${request.buyer?.dob || ''}` || undefined, */
  },
  merchant_urls: {
    success: `${request?.merchantUrls?.success || ''}` || undefined,
    cancel: `${request?.merchantUrls?.cancel || ''}` || undefined,
    failure: `${request?.merchantUrls?.failure || ''}` || undefined,
  },
});
