import {
  parseTamaraPaymentOptionList,
  TamaraPaymentOption,
} from './tamara-payment-option.model';

export class TamaraOptionsApiResponse {
  public options: Array<TamaraPaymentOption>;

  constructor() {
    this.options = new Array(0);
  }
}

export const parseTamaraOptionsApiResponse = (
  data?: Array<Record<string, any>> | null
): TamaraOptionsApiResponse | null => {
  let result: TamaraOptionsApiResponse | null = null;
  if (data) {
    result = new TamaraOptionsApiResponse();
    result.options = parseTamaraPaymentOptionList(data);
  }
  return result;
};
