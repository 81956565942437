import { ApiError } from '@app/shared/models/api/api-error.model';
import { environment } from '@environments/environment';
import { TabbySessionApiResponse } from '@app/checkout/payment-providers/tabby/models/tabby-session.model';
import { TamaraPaymentOption } from '@app/checkout/payment-providers/tamara/models/tamara-payment-option.model';

export interface AkitaPaymentApiTokenState {
  stripeApiToken: string | null;
  gettingStripeApiToken: boolean;
  getStripeApiTokenError: ApiError | null;
}

export interface AkitaPaymentState {
  currency: string | null;

  apiTokens: {
    [currency: string]: AkitaPaymentApiTokenState;
  };

  setupIntentSecrets: {
    [currency: string]: string | null;
  };

  gettingSetupIntentSecrets: {
    [currency: string]: boolean;
  };

  gettingSetupIntentSecretsError: {
    [currency: string]: ApiError | null;
  };

  tabbyPublicKey: string;
  fetchingTabbyPublicKey: boolean;
  errorFetchingTabbyPublicKey: ApiError | null;

  tamaraPublicKey: string;
  fetchingTamaraPublicKey: boolean;
  errorFetchingTamaraPublicKey: ApiError | null;

  checkoutDotComPublicKey: string | null;
  fetchingCheckoutDotComPublicKey: boolean;
  errorFetchingCheckoutDotComPublicKey: ApiError | null;

  applePaySession: any | null;
  validatingApplePaySession: boolean;
  errorValidatingApplePaySession: ApiError | null;

  tabbySession: {
    [productIds: string]: TabbySessionApiResponse | null;
  };
  generatingTabbySession: {
    [productIds: string]: boolean;
  };
  errorGeneratingTabbySession: {
    [productIds: string]: ApiError | null;
  };

  tamaraPaymentOptions: {
    [country: string]: Array<TamaraPaymentOption>;
  };
  fetchingTamaraPaymentOptions: {
    [listingId: string]: boolean;
  };
  errorFetchingTamaraPaymentOptions: {
    [listingId: string]: ApiError | null;
  };

  generatingStripeCheckoutSession: boolean;
  errorGeneratingStripeCheckoutSession: ApiError | null;

  lastUpdated: Date;
}

export function createInitialPaymentApiTokenState(
  currency?: string | null
): AkitaPaymentApiTokenState {
  let token = environment.stripe.country.us;
  if (`${currency || ''}`.toUpperCase() === 'BRL') {
    token = environment.stripe.country.br;
  }

  return {
    stripeApiToken: token,
    gettingStripeApiToken: false,
    getStripeApiTokenError: null,
  };
}

export function createInitialPaymentState(): AkitaPaymentState {
  return {
    currency: null,

    apiTokens: {
      BRL: createInitialPaymentApiTokenState('BRL'),
      SAR: createInitialPaymentApiTokenState('SAR'),
    },
    setupIntentSecrets: {},
    gettingSetupIntentSecrets: {},
    gettingSetupIntentSecretsError: {},

    tabbyPublicKey: environment.tabby.pubKey,
    fetchingTabbyPublicKey: false,
    errorFetchingTabbyPublicKey: null,

    tamaraPublicKey: environment.tamara.pubKey,
    fetchingTamaraPublicKey: false,
    errorFetchingTamaraPublicKey: null,

    checkoutDotComPublicKey: null,
    fetchingCheckoutDotComPublicKey: false,
    errorFetchingCheckoutDotComPublicKey: null,

    applePaySession: null,
    validatingApplePaySession: false,
    errorValidatingApplePaySession: null,

    tabbySession: {},
    generatingTabbySession: {},
    errorGeneratingTabbySession: {},

    tamaraPaymentOptions: {},
    fetchingTamaraPaymentOptions: {},
    errorFetchingTamaraPaymentOptions: {},

    generatingStripeCheckoutSession: false,
    errorGeneratingStripeCheckoutSession: null,

    lastUpdated: new Date(),
  };
}
