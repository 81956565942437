import { Injectable } from '@angular/core';
import { Address } from '@app/akita/api/addresses/models/address.model';
import { parseTabbySessionApiRequest } from '@app/akita/api/payments/models/tabby-session-api-config.model';
import { AkitaPaymentService } from '@app/akita/api/payments/state/payment.service';
import {
  generateProductUrlSlug,
  Product,
} from '@app/akita/api/products/models/product.model';
import { AkitaRouterQuery } from '@app/akita/router/state/router.query';
import { PriceModel } from '@app/shared/models/api/price.model';
import { UserModel } from '@app/shared/models/api/user.model';
import { SentryUtil } from '@app/shared/utils/sentry.util';
import { getCurrentHost } from '@app/shared/utils/url.utils';
import {
  BehaviorSubject,
  catchError,
  distinctUntilChanged,
  EMPTY,
  map,
  Observable,
  of,
  Subscription,
  tap,
  throwError,
} from 'rxjs';
import { TabbyCard, TabbyCardOptions } from '../models/tabby-card.model';
import { TabbyConfiguration } from '../models/tabby-configuration.model';
import { TabbyInstance } from '../models/tabby-instance.model';
import {
  parseTabbyOnChangeResponse,
  TabbyOnChangeResponse,
} from '../models/tabby-on-change-response.model';
import { TabbyPromo, TabbyPromoOptions } from '../models/tabby-promo.model';
import { TabbySessionApiResponse } from '../models/tabby-session.model';
import { TabbyCardScriptTag } from './tabby-card-script-tag.service';
import { TabbyPromoScriptTag } from './tabby-promo-script-tag.service';
import { TabbyScriptTag } from './tabby-script-tag.service';
import { PhoneInfo } from '@app/akita/api/addresses/models/phone.model';
import { AkitaCheckoutQuery } from '@app/akita/api/checkout/state/checkout.query';
import { SessionAddressModel } from '@app/akita/api/checkout/models/session-address.model';

export const TABBY_SUPPORTED_COUNTRIES = ['SA', 'AE', 'KW', 'QA', 'BH'];
export const TABBY_SUPPORTED_CURRENCIES = ['SAR', 'AED', 'KWD', 'QAR', 'BHD'];
/* export const TABBY_MAX_ALLOWED_AMOUNT = 1400; */

@Injectable({ providedIn: 'root' })
export class TabbyActionsService {
  public relaunchTabby: boolean;
  public tabbyDialogVisible: boolean;

  private readonly configSubject: BehaviorSubject<TabbyConfiguration | null>;
  public readonly config$: Observable<TabbyConfiguration | null>;

  private readonly onTabbyChangeSubject: BehaviorSubject<TabbyOnChangeResponse | null>;
  public readonly onTabbyChange$: Observable<TabbyOnChangeResponse | null>;

  private readonly onTabbyCloseSubject: BehaviorSubject<boolean>;
  public readonly onTabbyClose$: Observable<boolean>;

  constructor(
    private readonly akitaPaymentService: AkitaPaymentService,
    private readonly tabbyScriptTag: TabbyScriptTag,
    private readonly tabbyPromoScriptTag: TabbyPromoScriptTag,
    private readonly tabbyCardScriptTag: TabbyCardScriptTag,
    private readonly akitaRouterQuery: AkitaRouterQuery,
    private readonly akitaCheckoutQuery: AkitaCheckoutQuery
  ) {
    this.configSubject = new BehaviorSubject(null as any);
    this.config$ = this.configSubject
      .asObservable()
      .pipe(distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b)));

    this.onTabbyChangeSubject = new BehaviorSubject(null as any);
    this.onTabbyChange$ = this.onTabbyChangeSubject
      .asObservable()
      .pipe(distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b)));

    this.onTabbyCloseSubject = new BehaviorSubject(false as any);
    this.onTabbyClose$ = this.onTabbyCloseSubject
      .asObservable()
      .pipe(distinctUntilChanged());

    this.tabbyDialogVisible = false;
    this.relaunchTabby = false;
  }

  public get tabbyPaymentOptions(): TabbyOnChangeResponse | null {
    return this.onTabbyChangeSubject.value || null;
  }

  private generateConfiguration(
    apiKey: string,
    sessionId: string | null,
    lang: string,
    products: Array<Product>,
    user: UserModel,
    address: Address,
    email?: string | null,
    totalAmount?: number | null
  ): TabbyConfiguration | null {
    if (TABBY_SUPPORTED_CURRENCIES.includes(products[0].price.currency)) {
      const addressPhone = `+${address.phone.countryCode || ''}${
        address.phone.areaCode || ''
      }${address.phone.national || ''}`;

      const payment: any = {
        amount: `${totalAmount || products[0].price.amount || 0}`,
        buyer: {
          id: `${user.id || ''}`,
          email: `${email || user.email || ''}`,
          name: `${address.fullName || user.firstName || ''}`,
          phone: `${addressPhone || user.phone}`,
        },
        customer: {
          id: `${user.id || ''}`,
          email: `${email || user.email || ''}`,
          name: `${address.fullName || user.firstName || ''}`,
          phone: `${addressPhone || user.phone}`,
        },
        buyer_history: {
          loyalty_level: 0,
          registered_since: `${user.createdAt.toISOString()}`,
          wishlist_count: 0,
          is_social_networks_connected: false,
          is_phone_number_verified: Boolean(user.smsVerifiedAt),
          is_email_verified: true,
        },
        currency: `${products[0].price.currency}`.toUpperCase(),
        description: 'Cell Phone',
        order: {
          items: products.map(
            (product) =>
              <any>{
                title: `${product.title || 'iPhone'}`,
                // eslint-disable-next-line @typescript-eslint/no-magic-numbers
                description: `${product.description || 'phone'}`.slice(0, 100) + '...',
                quantity: product.quantity,
                unit_price: product.price.amount,
                discount_amount: 0,
                reference_id: `${product.id}`,
                image_url: `${product.images[0] || ''}`,
                product_url: `https://www.popsy.app/p/${generateProductUrlSlug(
                  product,
                  product.language
                )}`,
                category: 'phones',
                color: `${product.color || ''}`,
                size_type: 'GB',
                size: `${product.storage || ''}`,
                brand: `${product.brand || ''}`,
              }
          ),
          reference_id: 'Popsy Phone Purchase', // Order ID??
          updated_at: `${new Date().toISOString()}`,
          tax_amount: '0.00',
          shipping_amount: '0.00',
          discount_amount: '0.00',
        },
        order_history: [],
        shipping_address: {
          city: `${address.city || ''}`,
          address: `${address.streetName || ''}`,
          zip: `${address.zipCode || ''}`,
        },
      };

      let merchantCode = 'PopsyKSA';
      if (products[0].price.currency === 'AED') {
        merchantCode = 'PopsyUAE';
      }

      return {
        sessionId: sessionId || undefined,
        merchantCode: merchantCode,
        nodeId: 'tabby-checkout',
        apiKey: apiKey,
        lang: lang,
        payment,
        // merchantUrls: {
        //   success: `https://www.popsy.app/delivery/new/${listing.id}/tabby/success`,
        //   cancel: `https://www.popsy.app/delivery/new/${listing.id}/tabby/cancel`,
        //   failure: `https://www.popsy.app/delivery/new/${listing.id}/tabby/failure`,
        // },
        onChange: (data: TabbyOnChangeResponse) => {
          const parsedResponse = parseTabbyOnChangeResponse(data as any);
          this.onTabbyChangeSubject.next(parsedResponse);
        },
        onClose: () => {
          this.relaunchTabby = true;
          this.tabbyDialogVisible = false;
          this.onTabbyCloseSubject.next(true);
          this.reCreate();
        },
      };
    } else {
      return null;
    }
  }

  public initializeTabbyRedirect(
    lang: string,
    sessionId: string,
    products: Array<Product>,
    totalPrice: PriceModel,
    user: UserModel,
    userName: string,
    address: SessionAddressModel,
    email?: string | null,
    sessionPhone?: PhoneInfo | null,
    recreateSession?: boolean | null
  ): Observable<TabbySessionApiResponse | null> {
    const country = `${products[0]?.country || ''}`.toUpperCase();
    if (TABBY_SUPPORTED_COUNTRIES.includes(country)) {
      const host = getCurrentHost();
      const localeFragment = this.akitaRouterQuery.localeFragmentToString;

      const discount = this.akitaCheckoutQuery.discount?.amount || 0;

      let phone: string | null = null;
      if (sessionPhone) {
        phone = sessionPhone.display;
      } else if (user?.phone) {
        phone = user.phone;
      }

      let total = Number(`${totalPrice.amount || 0}`);
      if (discount && this.akitaCheckoutQuery.gotDiscountAfterLocked) {
        total = total - discount;
      }

      const queryParamsList = new Array(0);

      const discountCode = `${
        this.akitaCheckoutQuery.discount?.code || ''
      }`.toUpperCase();
      if (discountCode) {
        queryParamsList.push(`dc=${discountCode}`);
      }

      const queryParamsToKeep = this.akitaRouterQuery.queryParamsToKeep;
      for (const paramName of Object.keys(queryParamsToKeep)) {
        if (paramName === 'dc' && discountCode) {
          continue;
        }

        queryParamsList.push(`${paramName}=${queryParamsToKeep[paramName]}`);
      }

      const queryParams =
        queryParamsList.length > 0 ? `?${queryParamsList.join('&')}` : '';

      const parsedProducts = products.map(
        (item) => <any>{ product_id: item.id, quantity: item.quantity }
      );

      return this.akitaPaymentService.generateTabbySession(
        parseTabbySessionApiRequest({
          language: lang,
          products: parsedProducts,
          amount: total,
          discountAmount: discount || null,
          shipping_address: {
            city: `${address?.city || ''}`,
            address: `${address?.address || ''}`,
            zip: `${address?.postalCode || ''}`,
            country: `${address?.country || ''}`,
          },
          buyer: {
            id: `${user?.id || ''}`,
            phone: `${phone || ''}`,
            email: `${email || user?.email || ''}`,
            name: `${userName || user?.firstName || ''}`,
            // dob: `${user?.dob || ''}`,
          },
          merchant_urls: {
            success: `${host}${localeFragment}/checkout/${sessionId}/tabby-success${queryParams}`,
            cancel: `${host}${localeFragment}/checkout/${sessionId}/tabby-cancel${queryParams}`,
            failure: `${host}${localeFragment}/checkout/${sessionId}/tabby-failure${queryParams}`,
          },
        }),
        recreateSession
      );
    } else {
      return of(null);
    }
  }

  public initializeTabbyRedirectAsync(
    lang: string,
    sessionId: string,
    products: Array<Product>,
    totalPrice: PriceModel,
    user: UserModel,
    userName: string,
    address: SessionAddressModel,
    email?: string | null,
    sessionPhone?: PhoneInfo | null,
    recreateSession?: boolean | null
  ): Subscription {
    return this.initializeTabbyRedirect(
      lang,
      sessionId,
      products,
      totalPrice,
      user,
      userName,
      address,
      email,
      sessionPhone,
      recreateSession
    ).subscribe({
      next: () => {},
      error: () => {},
    });
  }

  public initializeTabby(
    apiKey: string,
    sessionId: string | null,
    lang: string,
    products: Array<Product>,
    user: UserModel,
    address: Address,
    email?: string | null,
    totalAmount?: number | null
  ): Observable<any> {
    const country = `${products[0]?.country || ''}`.toUpperCase();
    if (TABBY_SUPPORTED_COUNTRIES.includes(country)) {
      return this.tabbyScriptTag.promiseInstance().pipe(
        catchError((error: unknown) => {
          this.relaunchTabby = false;

          SentryUtil.addBreadcrumb({
            category: 'Tabby',
            message: `Failed to launch Tabby`,
            level: 'error',
            type: 'sentry',
          });
          SentryUtil.reportException(error, false);

          return throwError(() => error);
        }),
        tap((instance?: TabbyInstance | null) => {
          if (instance) {
            const config = this.generateConfiguration(
              apiKey,
              sessionId,
              lang,
              products,
              user,
              address,
              email,
              totalAmount
            );
            this.configSubject.next(config);
            if (config) {
              try {
                this.relaunchTabby = false;
                instance.init(config);
                instance.create();
              } catch (err: unknown) {
                SentryUtil.addBreadcrumb({
                  category: 'Tabby',
                  message: `Failed to initialize Tabby`,
                  level: 'error',
                  type: 'sentry',
                });
                SentryUtil.reportException(err, false);
              }
            } else {
              this.relaunchTabby = false;
              SentryUtil.addBreadcrumb({
                category: 'Tabby',
                message: `Tabby Not Supported for this products: ${products[0].country} `,
                level: 'info',
                type: 'sentry',
              });
            }
          }
        })
      );
    } else {
      return EMPTY;
    }
  }

  public initializeTabbyAsync(
    apiKey: string,
    sessionId: string | null,
    lang: string,
    products: Array<Product>,
    user: UserModel,
    address: Address,
    email?: string | null,
    totalAmount?: number | null
  ): Subscription {
    return this.initializeTabby(
      apiKey,
      sessionId,
      lang,
      products,
      user,
      address,
      email,
      totalAmount
    ).subscribe({
      next: () => {},
      error: () => {},
    });
  }

  public launchTabbyCheckout(option?: string | null): Subscription {
    return this.tabbyScriptTag.promiseInstance().subscribe({
      next: (instance?: TabbyInstance | null) => {
        if (instance && !this.tabbyDialogVisible) {
          let product: string = instance.PAYLATER;
          if (option === 'TABBY_INSTALLMENTS') {
            product = instance.INSTALLMENTS;
          } else if (option === 'TABBY_PAY_LATER') {
            product = instance.PAYLATER;
          } else if (option === 'TABBY_MONTLY_BILLING') {
            product = instance.MONTHLYBILLING;
          }

          instance.launch({ product: product });

          if (this.akitaRouterQuery.isBrowser) {
            try {
              this.tabbyDialogVisible = true;
              (window as any).document.querySelector('#tabby-checkout').style.display =
                '';
            } catch (err) {
              this.tabbyDialogVisible = false;
            }
          }
        }
      },
      error: (error: unknown) => {
        SentryUtil.addBreadcrumb({
          category: 'Tabby',
          message: `Failed to launch the Tabby Checkout`,
          level: 'error',
          type: 'sentry',
        });
        SentryUtil.reportException(error, false);
      },
    });
  }

  public reCreate(): Subscription {
    return this.tabbyScriptTag.promiseInstance().subscribe({
      next: (instance?: TabbyInstance | null) => {
        if (instance) {
          instance.create();
        }
      },
      error: (error: unknown) => {
        SentryUtil.addBreadcrumb({
          category: 'Tabby',
          message: `Failed to re-create the Tabby Checkout session`,
          level: 'error',
          type: 'sentry',
        });
        SentryUtil.reportException(error, false);
      },
    });
  }

  public destroy(): Subscription {
    return this.tabbyScriptTag.promiseInstance().subscribe({
      next: (instance?: TabbyInstance | null) => {
        if (instance) {
          instance.destroy();
        }
      },
      error: (error: unknown) => {
        SentryUtil.addBreadcrumb({
          category: 'Tabby',
          message: `Failed to destroy the Tabby Checkout`,
          level: 'error',
          type: 'sentry',
        });
        SentryUtil.reportException(error, false);
      },
    });
  }

  public displayTabbyPromo(options: TabbyPromoOptions): Observable<TabbyPromo | null> {
    if (this.akitaRouterQuery.isBrowser) {
      return this.tabbyPromoScriptTag.promiseInstance().pipe(
        map(() => {
          try {
            return new (window as any).TabbyPromo(options);
          } catch (err) {
            return null;
          }
        })
      );
    } else {
      return of(null);
    }
  }

  public displayTabbyCard(options: TabbyCardOptions): Observable<TabbyCard | null> {
    if (this.akitaRouterQuery.isBrowser) {
      return this.tabbyCardScriptTag.promiseInstance().pipe(
        map(() => {
          try {
            return new (window as any).TabbyCard(options);
          } catch (err) {
            return null;
          }
        })
      );
    } else {
      return of(null);
    }
  }

  public navigateToTabbyCheckout(session?: TabbySessionApiResponse | null): boolean {
    const paymentUrl = this.detectTabbyPaymentUrl(session || null);
    if (paymentUrl && this.akitaRouterQuery.isBrowser) {
      try {
        (window as any).location.href = paymentUrl;
        return true;
      } catch (err) {
        return false;
      }
    } else {
      return false;
    }
  }

  private detectTabbyPaymentUrl(session: TabbySessionApiResponse | null): string | null {
    const installments = session?.configuration?.availableProducts?.installments;
    const payLater = session?.configuration?.availableProducts?.payLater;
    if (installments && (installments?.length || 0) > 0) {
      const product = installments[0];
      return product.webUrl;
    } else if (payLater && (payLater?.length || 0) > 0) {
      const product = payLater[0];
      return product.webUrl;
    } else {
      return null;
    }
  }
}
