import { Injectable } from '@angular/core';
import { AkitaRouterQuery } from '@app/akita/router/state/router.query';
import { Observable, Observer } from 'rxjs';
import {
  DEFAULT_CARD_PAYMENT_PROVIDER,
  CardPaymentProviders,
} from '../../payments/models/payment-providers.model';
import { ExperimentCashOnDeliveryMode } from '../models/experiment-cod.enum';

const CHECKOUT_EXPERIMENT = 'checkout'; // Shopify vs Native for UAE
const PAYMENT_PROVIDER = 'payment-provider'; // Checkout.com vs Stripe for GCC
const TAMARA_PAY_NOW_EXPERIMENT = 'tamara-experiment'; // Tamara only PayNow

const LEAVE_ORDER_SURVEY_QUESTION = 'leave-survey-question';
const FEATURED_CASH_ON_DELIVERY = 'cod';
const FEATURED_CASH_ON_DELIVERY_EXPERIMENT = 'cod-experiment';
// new experiment for new checkout flow
const CHECKOUT_FLOW = 'checkout-flow';
const ADDRESS_SEARCH = 'address-search';

export interface GoogleOptimizeExperiments {
  ExperimentCheckout?: string | null;
  PaymentProvider?: CardPaymentProviders | null;
  TamaraPayNowExperiment?: string | null;

  LeaveOrderSurveyQuestion?: string | null;
  FeatureCashOnDelivery?: boolean | null;
  ExperimentCashOnDelivery?: ExperimentCashOnDeliveryMode | null;
  CheckoutFlow?: string | null;
  ExpressCheckout?: boolean;
  AddressSearch?: boolean;
}

/**
 * Class to handle Search parameters
 */
@Injectable({
  providedIn: 'root',
})
export class GoogleOptimizeService {
  private readonly isBrowser: boolean;

  private readonly initialValues: {
    [key: string]: any;
  };

  constructor(private readonly akitaRouterQuery: AkitaRouterQuery) {
    this.isBrowser = this.akitaRouterQuery.isBrowser;

    this.initialValues = {
      'tamara-experiment': 'original',
      'checkout-flow': 'CHECKOUT_SESSION',
      'address-search': true,
    };

    if (this.isBrowser) {
      const attributes = document.body.attributes;

      for (let i = 0; i < attributes.length; i += 1) {
        const attribute = attributes[i];
        if (attribute.name === PAYMENT_PROVIDER) {
          let provider = DEFAULT_CARD_PAYMENT_PROVIDER;
          if (attribute.value === 'STRIPE_V2') {
            provider = 'STRIPE_V2';
          } else if (attribute.value === 'CHECKOUT') {
            provider = 'CHECKOUT';
          }

          this.initialValues = {
            ...this.initialValues,
            [PAYMENT_PROVIDER]: provider,
          };
        } else if (attribute.name === CHECKOUT_FLOW) {
          let flow = 'CHECKOUT_SESSION';
          if (attribute.value === 'DELIVERY_FLOW') {
            flow = 'DELIVERY_FLOW';
          } else if (attribute.value === 'CHECKOUT_SESSION') {
            flow = 'CHECKOUT_SESSION';
          } else if (attribute.value === 'CHECKOUT_SESSION_NOEXPRESS') {
            flow = 'CHECKOUT_SESSION_NOEXPRESS';
          }

          this.initialValues = {
            ...this.initialValues,
            [CHECKOUT_FLOW]: flow,
          };
        } else if (attribute.name === ADDRESS_SEARCH) {
          this.initialValues = {
            ...this.initialValues,
            [ADDRESS_SEARCH]: attribute.value === 'true' ? true : false,
          };
        } else if (attribute.name === TAMARA_PAY_NOW_EXPERIMENT) {
          this.initialValues = {
            ...this.initialValues,
            [TAMARA_PAY_NOW_EXPERIMENT]: `${attribute.value || ''}`,
          };
        } else if (attribute.name === LEAVE_ORDER_SURVEY_QUESTION) {
          this.initialValues = {
            ...this.initialValues,
            [LEAVE_ORDER_SURVEY_QUESTION]: `${attribute.value || ''}`,
          };
        } else if (attribute.name === FEATURED_CASH_ON_DELIVERY) {
          this.initialValues = {
            ...this.initialValues,
            [FEATURED_CASH_ON_DELIVERY]: `${attribute.value || ''}`,
          };
        } else if (attribute.name === FEATURED_CASH_ON_DELIVERY_EXPERIMENT) {
          this.initialValues = {
            ...this.initialValues,
            [FEATURED_CASH_ON_DELIVERY_EXPERIMENT]: `${attribute.value || ''}`,
          };
        } else if (attribute.name === CHECKOUT_EXPERIMENT) {
          this.initialValues = {
            ...this.initialValues,
            [CHECKOUT_EXPERIMENT]: `${attribute.value || ''}`,
          };
        }
      }
    }
  }

  public monitorExperiments(): Observable<GoogleOptimizeExperiments> {
    return new Observable((observer: Observer<GoogleOptimizeExperiments>) => {
      // send Initial values
      observer.next({
        PaymentProvider:
          this.initialValues[PAYMENT_PROVIDER] || DEFAULT_CARD_PAYMENT_PROVIDER,
        TamaraPayNowExperiment: this.initialValues[TAMARA_PAY_NOW_EXPERIMENT] as string,
        LeaveOrderSurveyQuestion: this.initialValues[LEAVE_ORDER_SURVEY_QUESTION],
        FeatureCashOnDelivery: this.initialValues[FEATURED_CASH_ON_DELIVERY] !== 'false',
        ExperimentCashOnDelivery:
          this.initialValues[FEATURED_CASH_ON_DELIVERY_EXPERIMENT],
        CheckoutFlow: this.initialValues[CHECKOUT_FLOW],
        AddressSearch: this.initialValues['address-search'],
      });

      if (this.isBrowser) {
        try {
          const mutationObserver = new MutationObserver((mutations: MutationRecord[]) => {
            const experiments: GoogleOptimizeExperiments = {};
            for (const mutation of mutations) {
              if (mutation.attributeName === PAYMENT_PROVIDER) {
                const attrValue = (document?.body?.attributes as any)[PAYMENT_PROVIDER]
                  ?.value;
                let provider = DEFAULT_CARD_PAYMENT_PROVIDER;

                if (attrValue === 'STRIPE_V2') {
                  provider = 'STRIPE_V2';
                } else if (attrValue === 'CHECKOUT') {
                  provider = 'CHECKOUT';
                }
                (experiments as any).PaymentProvider = provider;
              }
              if (mutation.attributeName === CHECKOUT_FLOW) {
                const attrValue = (document?.body?.attributes as any)[CHECKOUT_FLOW]
                  ?.value;
                let flow = 'CHECKOUT_SESSION';

                if (attrValue === 'DELIVERY_FLOW') {
                  flow = 'DELIVERY_FLOW';
                } else if (attrValue === 'CHECKOUT_SESSION') {
                  flow = 'CHECKOUT_SESSION';
                } else if (attrValue === 'CHECKOUT_SESSION_NOEXPRESS') {
                  flow = 'CHECKOUT_SESSION_NOEXPRESS';
                }
                (experiments as any).CheckoutFlow = flow;
              }

              if (mutation.attributeName === ADDRESS_SEARCH) {
                const attrValue = (document?.body?.attributes as any)[ADDRESS_SEARCH]
                  ?.value;

                (experiments as any).AddressSearch = attrValue === 'true' ? true : false;
              }

              if (mutation.attributeName === CHECKOUT_EXPERIMENT) {
                const attrValue = (document?.body?.attributes as any)[CHECKOUT_EXPERIMENT]
                  ?.value;
                (experiments as any).ExperimentCheckout = `${attrValue || ''}`;
              }
              if (mutation.attributeName === TAMARA_PAY_NOW_EXPERIMENT) {
                const attrValue = (document?.body?.attributes as any)[
                  TAMARA_PAY_NOW_EXPERIMENT
                ]?.value;

                (experiments as any).TamaraPayNowExperiment = `${attrValue || ''}`;
              }
              if (mutation.attributeName === LEAVE_ORDER_SURVEY_QUESTION) {
                const attrValue = (document?.body?.attributes as any)[
                  LEAVE_ORDER_SURVEY_QUESTION
                ]?.value;
                (experiments as any).LeaveOrderSurveyQuestion = `${attrValue || ''}`;
              }
              if (mutation.attributeName === FEATURED_CASH_ON_DELIVERY) {
                const attrValue = (document?.body?.attributes as any)[
                  FEATURED_CASH_ON_DELIVERY
                ]?.value;
                (experiments as any).FeatureCashOnDelivery =
                  `${attrValue || ''}` !== 'false';
              }
              if (mutation.attributeName === FEATURED_CASH_ON_DELIVERY_EXPERIMENT) {
                const attrValue = (document?.body?.attributes as any)[
                  FEATURED_CASH_ON_DELIVERY_EXPERIMENT
                ]?.value;
                (experiments as any).ExperimentCashOnDelivery = `${attrValue || ''}`;
              }
            }
            observer.next(experiments);
          });
          mutationObserver.observe(document.body, {
            attributes: true,
            childList: true,
            subtree: true,
          });
        } catch (error) {
          // MutationObserver not supported
        }
      }
    });
  }
}
