export class TabbyShippingAddress {
  public city: string;
  public country: string;
  public address: string;
  public zip?: string | null;

  constructor() {
    this.city = '';
    this.country = '';
    this.address = '';
    this.zip = null;
  }
}

export const parseTabbyShippingAddress = (
  data?: Record<string, any> | null
): TabbyShippingAddress | null => {
  let result: TabbyShippingAddress | null = null;
  if (data) {
    result = new TabbyShippingAddress();
    result.city = data.city || '';
    result.address = data.address || '';
    result.zip = data.zip || null;
    result.country = data.country || '';
  }
  return result;
};
