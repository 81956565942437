import { parseTabbyInstallmentList, TabbyInstallment } from './tabby-installment.model';

export class TabbyProducts {
  public installments: Array<TabbyInstallment>;
  public payLater: Array<any>;

  constructor() {
    this.installments = new Array(0);
    this.payLater = new Array(0);
  }
}

export const parseTabbyProducts = (
  data?: Record<string, any> | null
): TabbyProducts | null => {
  let result: TabbyProducts | null = null;
  if (data) {
    result = new TabbyProducts();
    result.installments = parseTabbyInstallmentList(data.installments);
    result.payLater = data.pay_later || data.payLater || new Array(0);
  }
  return result;
};
