import {
  Component,
  OnDestroy,
  ChangeDetectionStrategy,
  signal,
  Signal,
  AfterViewInit,
  effect,
  WritableSignal,
} from '@angular/core';
import { TranslocoModule } from '@ngneat/transloco';
import { map, Observable, Subject, Subscription, switchMap } from 'rxjs';
import { AkitaConfigurationQuery } from '@app/akita/configuration/state/configuration.query';
import { AkitaScreenQuery } from '@app/akita/screen/state/screen.query';
import { ScreenSizeInfoState } from '@app/akita/screen/state/screen.store';
import { AkitaRouterQuery } from '@app/akita/router/state/router.query';
import { BidiModule } from '@angular/cdk/bidi';
import { AsyncPipe, CommonModule } from '@angular/common';
import { AkitaProductsQuery } from '@app/akita/api/products/state/products.query';
import { Params, RouterModule } from '@angular/router';
import { TRADEIN_FAQ_DATA } from './frequent-faq.data';
import { FooterComponent } from '@app/shared/components/footer/footer.component';
import { AkitaBuybackQuery } from '@app/akita/api/buyback/state/buyback.query';
import { toSignal } from '@angular/core/rxjs-interop';
import { BuybackSearchFilters } from '@app/akita/api/buyback/models/buyback-search.filters.model';
import { AkitaBuybackService } from '@app/akita/api/buyback/state/buyback.service';
import { BuybackOfferModel } from '@app/akita/api/buyback/models/buyback.offer.model';
import { AmountPipe } from '@app/shared/pipes/amount.pipe';
import { logBeginBuybackFlow } from '@app/core/services/google-analytics.lazy';
import { GoogleAnalyticsService } from '@app/core/services/google-analytics.service';
import { MSClarityService } from '@app/shared/services/msclarity.service';
import { BuybackHighlightsComponent } from '../../components/buyback-highlights/buyback-highlights.component';
import { ChevronDownIconComponent } from '../../../shared/components/icons/generated/chevron-down/chevron-down.component';
import { NavigationBarComponent } from '../../../home-new/components/navigation-bar/navigation-bar.component';
import { AkitaRemoteConfigQuery } from '@app/akita/api/remote-config/state/remote-config.query';

export const SUPPORTED_BUYBACK_COUNTRIES = ['SA', 'AE'];

@Component({
  selector: 'app-buyback-home',
  templateUrl: './buyback-home.template.html',
  styleUrls: ['./buyback-home.style.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    BidiModule,
    FooterComponent,
    AsyncPipe,
    RouterModule,
    TranslocoModule,
    AmountPipe,
    BuybackHighlightsComponent,
    ChevronDownIconComponent,
    NavigationBarComponent,
  ],
})
export class BuybackHomeComponent implements OnDestroy, AfterViewInit {
  public lang: string;
  public country: Signal<string | null>;
  public isSupportedCountry: WritableSignal<boolean> = signal(false);

  public lessThan$: Observable<ScreenSizeInfoState>;
  public biggerThan: Signal<ScreenSizeInfoState | null>;
  public inFullScreenMode$: Observable<boolean>;

  public enabledBuybackFeature: Signal<boolean | null> = signal(null);

  public buybackProductOffers: Signal<Array<BuybackOfferModel> | null> = signal(null);
  public isSubmitting: Signal<boolean | null> = signal(null);

  public readonly queryParams$: Observable<Params>;

  public frequentFAQData = TRADEIN_FAQ_DATA;

  private readonly destroySubject: Subject<void>;
  private readonly subscriptions: Subscription = new Subscription();

  constructor(
    private readonly akitaConfigurationQuery: AkitaConfigurationQuery,
    private readonly akitaProductsQuery: AkitaProductsQuery,
    private readonly akitaScreenQuery: AkitaScreenQuery,
    private readonly akitaRouterQuery: AkitaRouterQuery,
    private readonly akitaBuybackQuery: AkitaBuybackQuery,
    private readonly akitaBuybackService: AkitaBuybackService,
    private readonly analyticsService: GoogleAnalyticsService,
    private readonly msClarityService: MSClarityService,
    private readonly akitaRemoteConfigQuery: AkitaRemoteConfigQuery
  ) {
    this.destroySubject = new Subject();

    this.enabledBuybackFeature = toSignal(
      this.akitaRemoteConfigQuery.selectEnableBuybackFeature(),
      {
        initialValue: null,
      }
    );

    this.isSubmitting = toSignal(this.akitaBuybackQuery.selectIsSubmitting(), {
      initialValue: null,
    });

    this.akitaBuybackService.reset();

    this.lessThan$ = this.akitaScreenQuery.selectLessThan();
    this.biggerThan = toSignal(this.akitaScreenQuery.selectBiggerThan(), {
      initialValue: null,
    });
    this.queryParams$ = this.akitaRouterQuery.selectQueryParams();

    this.inFullScreenMode$ = this.akitaRouterQuery.selectInFullScreenMode();

    this.country = toSignal(this.akitaRouterQuery.selectCountry(), {
      initialValue: null,
    });
    effect(
      () => {
        if (
          this.country() !== null &&
          SUPPORTED_BUYBACK_COUNTRIES.includes(this.country() || '')
        ) {
          this.isSupportedCountry.set(true);
        }
      },
      { allowSignalWrites: true }
    );

    const languageCode = this.akitaConfigurationQuery.languageCode;
    this.lang = (languageCode.locale || 'EN').toUpperCase();

    // only iphones for now -> show the most expensive one
    this.buybackProductOffers = toSignal(
      this.akitaProductsQuery.selectCountry().pipe(
        switchMap((country) => {
          if (!SUPPORTED_BUYBACK_COUNTRIES.includes(country)) {
            return [];
          }
          const filters = {
            ...new BuybackSearchFilters(),
            country,
          };

          return this.akitaBuybackQuery.selectProductOffers(filters).pipe(
            map((offers) => {
              // sort by price field
              offers?.sort((a, b) => (b.price?.amount || 0) - (a.price?.amount || 0));
              const reduced = offers?.reduce((acc: any, product) => {
                // Add the first product of each category
                if (!acc.some((p: any) => p.model === product.model)) {
                  acc.push(product);
                }
                return acc.slice(0, 1);
              }, []);
              return reduced || [];
            })
          );
        })
      ),
      {
        initialValue: null,
      }
    );
  }

  public ngAfterViewInit(): void {
    const buybackFilters = {
      ...new BuybackSearchFilters(),
      country: this.akitaProductsQuery.country,
    };

    this.subscriptions.add(
      this.akitaBuybackService.searchBuybackProducts(buybackFilters).subscribe({
        next: () => {},
        error: () => {},
      })
    );
  }

  public getInfoFragment(page: string): Array<string> {
    const fragment = this.akitaRouterQuery.homeUrlFragment;
    fragment.push(page);
    return fragment;
  }

  public getBuybackSellFragment(): Array<string> {
    const fragment = this.akitaRouterQuery.homeUrlFragment;
    fragment.push('buyback', 'sell');
    logBeginBuybackFlow(this.analyticsService.logEventWrapper, 'begin');
    this.msClarityService.setTag('buyback-flow', 'start-flow');
    this.msClarityService.upgradeSession('buyback-flow-session');

    return fragment;
  }

  public ngOnDestroy(): void {
    this.destroySubject.next();
    this.destroySubject.complete();

    this.subscriptions.unsubscribe();
  }
}
