import { PriceModel } from '@app/shared/models/api/price.model';

export class TamaraSupportedInstallment {
  public instalments: number;
  public minLimit: PriceModel;
  public maxLimit: PriceModel;

  constructor() {
    this.instalments = 0;
    this.minLimit = new PriceModel();
    this.maxLimit = new PriceModel();
  }
}

export const parseTamaraSupportedInstallment = (
  data?: Record<string, any> | null
): TamaraSupportedInstallment | null => {
  let option: TamaraSupportedInstallment | null = null;
  if (data) {
    option = new TamaraSupportedInstallment();
    option.instalments = data?.instalments || 0;
    option.minLimit =
      PriceModel.fromJson(data?.min_limit || data?.minLimit) || new PriceModel();
    option.maxLimit =
      PriceModel.fromJson(data?.max_limit || data?.maxLimit) || new PriceModel();
  }
  return option;
};

export const parseTamaraSupportedInstallmentList = (
  data?: Array<Record<string, any>> | null
): Array<TamaraSupportedInstallment> => {
  const installmentOptions = new Array(0);
  if (data) {
    for (const item of data) {
      const option = parseTamaraSupportedInstallment(item);
      if (option) {
        installmentOptions.push(option);
      }
    }
  }
  return installmentOptions;
};
