import { BuyBackStepOption } from './buyback.state';

export const BUYBACK_SEARCH_FILTERS_MULTIPLE_ALLOWED = [
  'brand',
  'model',
  'storage',
  'color',
  'cpu',
  'graphics',
  'ram',
  'launch_date',
  'screen_size',
  'storage_type',
  'category',
  'series',
];

export const normalizeFilterValue = (name?: string | null | unknown): string => {
  let out = `${name || ''}`;

  if (out === 'RED') {
    out = '(PRODUCT)RED';
  } else if (out === 'JET_BLACK') {
    out = 'JET BLACK';
  }
  return out;
};

export class BuybackSearchFilters {
  /* public page?: number | null;
  public limit?: number | null; */
  public currency?: string | null;

  public country?: string | null;
  public category?: Array<string> | null;
  public brand?: Array<string> | null;
  public model?: Array<string> | null;
  public series?: Array<string> | null;
  public storage?: Array<string> | null;
  public color?: Array<string> | null;
  public language?: string | null;
  public cpu?: Array<string> | null;
  public graphics?: Array<string> | null;
  public launch_date?: Array<number> | null;
  public ram?: Array<string> | null;
  public screen_size?: Array<string> | null;
  public storage_type?: Array<string> | null;

  public condition?: string | null;

  constructor() {
    this.country = null;
    this.category = null;
    this.brand = null;
    this.model = null;
    this.series = null;
    this.storage = null;
    this.color = null;
    this.language = null;

    this.cpu = null;
    this.graphics = null;
    this.launch_date = null;
    this.ram = null;
    this.screen_size = null;
    this.storage_type = null;
  }
}

export const parseBuybackSearchFilters = (
  data?: Record<string, any> | null
): BuybackSearchFilters => {
  const filters = new BuybackSearchFilters();
  if (data) {
    filters.currency = data.currency || null;

    filters.country = data.country || null;
    filters.category = data.category || null;
    filters.brand = data.brand || null;
    filters.model = data.model || null;
    filters.series = data.series || null;
    filters.storage = data.storage || data.storages || null;
    filters.color = data.color || null;
    filters.language = data.language || null;

    filters.cpu = data.cpu || null;
    filters.graphics = data.graphics || null;
    filters.launch_date = data.launch_date || null;
    filters.ram = data.ram || null;
    filters.screen_size = data.screen_size || null;
    filters.storage_type = data.storage_type || null;
  }
  return filters;
};

export const parseBuybackSearchFiltersFromStepOption = (
  data?: Array<BuyBackStepOption> | null
): BuybackSearchFilters => {
  const filters = new BuybackSearchFilters();
  if (data) {
    for (const item of data) {
      const key = Object.keys(item)[0];
      const value = item[key];
      if (key === 'country') {
        filters.country = value as string;
      } else if (key === 'category') {
        filters.category = [...[], value as string];
      } else if (key === 'brand') {
        filters.brand = [...[], value as string];
      } else if (key === 'model') {
        filters.model = [...[], value as string];
      } else if (key === 'series') {
        filters.series = [...[], value as string];
      } else if (key === 'storages') {
        filters.storage = [...[], value as string];
      } else if (key === 'color') {
        filters.color = [...[], value as string];
      } else if (key === 'language') {
        filters.language = value as string;
      } else if (key === 'cpu') {
        filters.cpu = [...[], value as string];
      } else if (key === 'graphics') {
        filters.graphics = [...[], value as string];
      } else if (key === 'ram') {
        filters.ram = [...[], value as string];
      } else if (key === 'screen_size') {
        filters.screen_size = [...[], value as string];
      } else if (key === 'storage_type') {
        filters.storage_type = [...[], value as string];
      } else if (key === 'condition') {
        filters.condition = value as string;
      }
    }

    // conditions
    const functionality = data.find((formValue) => formValue['functionality'])?.[
      'functionality'
    ];
    const screenCondition = data.find((formValue) => formValue['screenCondition'])?.[
      'screenCondition'
    ];
    const deviceCondition = data.find((formValue) => formValue['deviceCondition'])?.[
      'deviceCondition'
    ];

    /* const screenCondition = data.find((item) => item.screenCondition as string);
    const deviceCondition = data.find((item) => item.deviceCondition as string); */

    if (functionality === 'No') {
      filters.condition = 'BROKEN';
    } else if (
      functionality === 'yes' &&
      (screenCondition === 'cracked' ||
        screenCondition === 'moderate_scratches' ||
        deviceCondition === 'cracked' ||
        deviceCondition === 'moderate_scratches')
    ) {
      filters.condition = 'FAIR';
    } else if (
      functionality === 'yes' &&
      (screenCondition === 'minor_scratches' || deviceCondition === 'minor_scratches')
    ) {
      filters.condition = 'GOOD';
    } else if (
      functionality === 'yes' &&
      (screenCondition === 'flawless' || deviceCondition === 'flawless')
    ) {
      filters.condition = 'EXCELLENT';
    }

    console.log(functionality, screenCondition, deviceCondition, filters.condition);
  }
  return filters;
};

export const generateBuybackFiltersID = (
  filters?: BuybackSearchFilters | null
): string => {
  let path = '';
  if (filters) {
    for (const key of Object.keys(filters || {})) {
      if (key !== 'page') {
        const value = (filters as any)[key];
        if ((!Array.isArray(value) && value) || (Array.isArray(value) && value.length)) {
          if (BUYBACK_SEARCH_FILTERS_MULTIPLE_ALLOWED.includes(`${key || '-'}`)) {
            path = `${path}&${key}=${(value as Array<string>).join('|')}`;
          } else {
            path = `${path}&${key}=${value}`;
          }
        }
      }
    }
  }
  return path;
};
