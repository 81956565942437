import { inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, throwError, catchError, map } from 'rxjs';
import { getApiEndpoint, getCurrentHost } from '@app/shared/utils/url.utils';
import { parseApiError } from '@app/shared/models/api/api-error.model';
import {
  CheckoutDotComPublicKey,
  parseCheckoutDotComPublicKey,
} from '../models/checkout.com-public-key.model';
import { AkitaAuthQuery } from '../../auth/state/auth.query';

@Injectable({ providedIn: 'root' })
export class CheckoutDotComApiService {
  private readonly http = inject(HttpClient);
  constructor(private readonly akitaAuthQuery: AkitaAuthQuery) {}

  public fetchPublicKey(): Observable<CheckoutDotComPublicKey | null> {
    const apiUrl = getApiEndpoint(`api/v2/payment/checkout/publicKey`, true);

    let headers = {};
    // eslint-disable-next-line deprecation/deprecation
    const credentials = btoa(`api:Lax4UpCrx9TAmhea`);
    headers = {
      Authorization: `Basic ${credentials}`,
    };

    return this.http
      .get<any>(
        // Assemble the full API URL
        apiUrl,
        // Add the Options
        {
          responseType: 'json',
          headers: headers,
        }
      )
      .pipe(
        catchError((error: unknown) => throwError(() => parseApiError(error))),
        map((results) => parseCheckoutDotComPublicKey(results))
      );
  }

  public validateApplePay(appleID: string): Observable<any | null> {
    const accessToken = this.akitaAuthQuery.accessToken || null;
    const apiUrl = getApiEndpoint(`api/v2/payment/applepay/startSession`, true);

    let headers = {};
    if (accessToken) {
      headers = {
        Authorization: `Bearer ${accessToken}`,
      };
    }

    let currentHost = `${getCurrentHost() || 'www.popsy.app'}`;
    currentHost = currentHost.replace('http://', '').replace('https://', '');

    const portsIndex = currentHost.indexOf(':');
    if (portsIndex !== -1) {
      currentHost = currentHost.slice(0, portsIndex);
    }

    return this.http
      .post<any>(
        // Assemble the full API URL
        apiUrl,
        {
          merchantIdentifier: appleID,
          displayName: 'Popsy',
          initiative: 'web',
          initiativeContext: `${currentHost}`,
        },
        // Add the Options
        {
          responseType: 'json',
          headers: headers,
        }
      )
      .pipe(
        catchError((error: unknown) => throwError(() => parseApiError(error))),
        map((results) => results)
      );
  }
}
