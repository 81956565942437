<ng-container *transloco="let t">
  <div
    class="allowed-payment-cards"
    [class.footer-mode]="footerMode"
    [class.express-mode]="expressMode">
    <div class="flex flex-col align-start justify-center full-width text">
      @if (!expressMode) {
        <h2 class="title margin-0-auto">{{ t('title_secure_payment_options') }}</h2>
        <span class="fixed-space-8" aria-hidden="true"></span>
      }
      <div class="card-icons">
        @if (displayBaseeta() === true) {
          <popsy-baseeta-badge [size]="'small'" />
        }

        @if (displayTabby$ | async) {
          <popsy-tabby-badge [size]="'small'" />
        }

        @if (displayTamara$ | async) {
          <popsy-tamara-badge [size]="'small'" />
        }

        @if (displayQuara() === true) {
          <popsy-quara-badge [size]="'small'" />
        }

        <!--  <popsy-icon-paypal class="icon tabby" aria-hidden="true"></popsy-icon-paypal> -->

        @if (displaySTC$ | async) {
          <popsy-icon-stc-pay class="icon stc" aria-hidden="true"></popsy-icon-stc-pay>
        }

        <popsy-icon-apple-pay class="icon" aria-hidden="true"></popsy-icon-apple-pay>

        <popsy-icon-google-pay
          class="icon google"
          aria-hidden="true"></popsy-icon-google-pay>

        @if (displayMada$ | async) {
          <popsy-icon-mada class="icon mada" aria-hidden="true"></popsy-icon-mada>
        }

        <popsy-icon-visa class="icon" aria-hidden="true"></popsy-icon-visa>

        <popsy-icon-mastercard class="icon" aria-hidden="true"></popsy-icon-mastercard>

        <popsy-icon-amex class="icon" aria-hidden="true"></popsy-icon-amex>
      </div>
    </div>
  </div>
  <span class="fixed-space-16" aria-hidden="true"></span>
</ng-container>
