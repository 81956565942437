import { PriceModel } from '@app/shared/models/api/price.model';
import { parseTamaraSupportedInstallmentList } from './tamara-supported-installment.model';

export class TamaraPaymentOption {
  public name: string;
  public description: string;
  public descriptionArabic: string;
  public minLimit: PriceModel;
  public maxLimit: PriceModel;
  public instalment: number;
  public supportedInstalments: Array<{
    instalments: number;
    minLimit: PriceModel;
    maxLimit: PriceModel;
  }>;

  constructor() {
    this.name = '';
    this.description = '';
    this.descriptionArabic = '';
    this.instalment = 0;
    this.minLimit = new PriceModel();
    this.maxLimit = new PriceModel();
    this.supportedInstalments = new Array(0);
  }
}

export const parseTamaraPaymentOption = (
  data?: Record<string, any> | null
): TamaraPaymentOption | null => {
  let option: TamaraPaymentOption | null = null;
  if (data) {
    option = new TamaraPaymentOption();
    option.name = data.name || data.payment_type || '';
    option.description = data.description || '';
    option.descriptionArabic = data.description_ar || data.descriptionArabic || '';
    option.minLimit =
      PriceModel.fromJson(data.min_limit || data.minLimit) || new PriceModel();
    option.maxLimit =
      PriceModel.fromJson(data.max_limit || data.maxLimit) || new PriceModel();
    option.supportedInstalments = parseTamaraSupportedInstallmentList(
      data.supported_instalments || data.supportedInstalments
    );
    option.instalment = data.instalment;
  }
  return option;
};

export const parseTamaraPaymentOptionList = (
  data?: Array<Record<string, any>> | null
): Array<TamaraPaymentOption> => {
  const optionList = new Array(0);
  if (data) {
    for (const item of data) {
      const option = parseTamaraPaymentOption(item);
      if (option) {
        optionList.push(option);
      }
    }
  }
  return optionList;
};
