/* eslint-disable prettier/prettier */
/* eslint-disable max-len */
/**
 * This file is autogenerated by bootstrap (DO NOT modify directly)
 */
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Input,
  OnChanges,
  Renderer2,
  SimpleChanges,
} from '@angular/core';
import { BaseIconComponent } from '@app/shared/components/icons/base-icon/base-icon.component';

@Component({
  standalone: true,
  selector: 'popsy-icon-quara',
  templateUrl: '../../../../../../assets/payment-icons/dark/quara.svg',
  styleUrls: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class QuaraIconComponent
  extends BaseIconComponent
  implements AfterViewInit, OnChanges
{
  @Input() public aspectRatio?: 'fill' | null;

  constructor(
    private readonly element: ElementRef,
    private readonly renderer: Renderer2
  ) {
    super();
  }
  public ngOnChanges(changes: SimpleChanges): void {
    if (changes?.aspectRatio) {
      this.applyAspectRatio();
    }
  }

  public ngAfterViewInit(): void {
    this.applyAspectRatio();
  }

  private applyAspectRatio(): void {
    if (this.element?.nativeElement?.firstChild) {
      if (this.aspectRatio === 'fill') {
        if (
          this.renderer?.setAttribute &&
          this.element?.nativeElement?.firstChild?.setAttribute &&
          typeof this.renderer?.setAttribute === 'function'
        ) {
          this.renderer.setAttribute(
            this.element.nativeElement.firstChild,
            'preserveAspectRatio',
            'xMinYMin slice'
          );
        }
      } else {
        if (
          this.renderer?.removeAttribute &&
          this.element?.nativeElement?.firstChild?.removeAttribute &&
          typeof this.renderer?.removeAttribute === 'function'
        ) {
          this.renderer.removeAttribute(
            this.element.nativeElement.firstChild,
            'preserveAspectRatio'
          );
        }
      }
    }
  }
}
