import { inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, throwError, catchError, map } from 'rxjs';
import { getApiEndpoint } from '@app/shared/utils/url.utils';
import { parseApiError } from '@app/shared/models/api/api-error.model';
import { parseTabbyPublicKey, TabbyPublicKey } from '../models/tabby-public-key.model';
import {
  generateTabbySessionApiRequest,
  TabbySessionApiRequest,
} from '../models/tabby-session-api-config.model';
import { AkitaAuthQuery } from '../../auth/state/auth.query';
import { Params } from '@angular/router';
import {
  parseTabbySessionApiResponse,
  TabbySessionApiResponse,
} from '@app/checkout/payment-providers/tabby/models/tabby-session.model';

@Injectable({ providedIn: 'root' })
export class TabbyApiService {
  private readonly http = inject(HttpClient);
  constructor(private readonly akitaAuthQuery: AkitaAuthQuery) {}

  public fetchPublicKey(): Observable<TabbyPublicKey | null> {
    const apiUrl = getApiEndpoint(`api/v2/payment/tabby/publicKey`, true);

    let headers = {};
    // eslint-disable-next-line deprecation/deprecation
    const credentials = btoa(`api:Lax4UpCrx9TAmhea`);
    headers = {
      Authorization: `Basic ${credentials}`,
    };

    return this.http
      .get<any>(
        // Assemble the full API URL
        apiUrl,
        // Add the Options
        {
          responseType: 'json',
          headers: headers,
        }
      )
      .pipe(
        catchError((error: unknown) => throwError(() => parseApiError(error))),
        map((results) => parseTabbyPublicKey(results))
      );
  }

  public generateSession(
    config: TabbySessionApiRequest
  ): Observable<TabbySessionApiResponse | null> {
    let apiUrl = getApiEndpoint(`api/v2/payment/tabby/session`, true);

    const headers: Params = {
      'Content-Type': 'application/json; charset=utf-8',
    };
    const accessToken = this.akitaAuthQuery.accessToken;
    if (accessToken) {
      headers.Authorization = `Bearer ${accessToken}`;
    } else {
      // eslint-disable-next-line deprecation/deprecation
      const credentials = btoa(`api:Lax4UpCrx9TAmhea`);
      headers.Authorization = `Basic ${credentials}`;
      apiUrl = apiUrl + `/guest`;
    }

    return this.http
      .post<any>(
        // Assemble the full API URL
        apiUrl,
        generateTabbySessionApiRequest(config),
        // Add the Options
        {
          responseType: 'json',
          headers: headers,
        }
      )
      .pipe(
        catchError((error: unknown) => throwError(() => parseApiError(error))),
        map((results) => parseTabbySessionApiResponse(results))
      );
  }
}
