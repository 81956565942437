export class TabbyPublicKey {
  public publicToken: string;

  constructor() {
    this.publicToken = '';
  }
}

export const parseTabbyPublicKey = (data?: any | null): TabbyPublicKey | null => {
  let parsed: TabbyPublicKey | null = null;
  if (data) {
    parsed = new TabbyPublicKey();
    parsed.publicToken = data.public_token || data.publicToken || '';
  }
  return parsed;
};
