import { PopsyDateParser } from '@app/shared/utils/api-date.parser';
import {
  TabbyInstallmentInfo,
  parseTabbyInstallmentInfoList,
} from './tabby-installment-info.model';

export class TabbyInstallment {
  public amountToPay: string;
  public downpayment: string;
  public downpaymentPercent: string;
  public id: number;
  public installmentPeriod: string;
  public installments: Array<TabbyInstallmentInfo>;
  public installmentsCount: number;
  public nextPaymentDate: Date;
  public payAfterDelivery: boolean;
  public payPerInstallment: string;
  public serviceFee: string;
  public webUrl: string;

  constructor() {
    this.amountToPay = '';
    this.downpayment = '';
    this.downpaymentPercent = '';
    this.id = 0;
    this.installmentPeriod = '';
    this.installments = new Array(0);
    this.installmentsCount = 0;
    this.nextPaymentDate = new Date();
    this.payAfterDelivery = false;
    this.payPerInstallment = '';
    this.serviceFee = '';
    this.webUrl = '';
  }
}

export const parseTabbyInstallment = (
  data?: Record<string, any> | null
): TabbyInstallment | null => {
  let result: TabbyInstallment | null = null;
  if (data) {
    result = new TabbyInstallment();
    result.amountToPay = data.amount_to_pay || data.amountToPay || '';
    result.downpayment = data.downpayment || '';
    result.downpaymentPercent = data.downpayment_percent || data.downpaymentPercent || '';
    result.id = data.id || 0;
    result.installmentPeriod = data.installment_period || data.installmentPeriod || '';
    result.installments = parseTabbyInstallmentInfoList(data.installments);
    result.installmentsCount = data.installments_count || data.installmentsCount || 0;
    result.payAfterDelivery = data.pay_after_delivery || data.payAfterDelivery || false;
    result.payPerInstallment = data.pay_per_installment || data.payPerInstallment || '';
    result.serviceFee = data.service_fee || data.serviceFee || '';
    result.webUrl = data.web_url || data.webUrl || '';

    result.nextPaymentDate =
      PopsyDateParser.parseApiDate('next_payment_date', 'nextPaymentDate', data) ||
      new Date();
  }
  return result;
};

export const parseTabbyInstallmentList = (
  data?: Array<Record<string, any>> | null
): Array<TabbyInstallment> => {
  const results = new Array(0);
  if ((data?.length || 0) > 0) {
    for (const item of data || []) {
      const parsed = parseTabbyInstallment(item);
      if (parsed) {
        results.push(parsed);
      }
    }
  }
  return results;
};
