import { Injectable, NgZone } from '@angular/core';
import { parseApiError } from '@app/shared/models/api/api-error.model';
import { applyTransaction, logAction } from '@datorama/akita';
import { Observable, Subscription, throwError, catchError, tap, of } from 'rxjs';
import { SearchStartingPricesApiService } from '../services/search-starting-prices.service';
import { AkitaStartingPricesStore } from './starting-prices.store';
import { StartingPriceModel } from '@app/shared/models/api/starting-price.model';
import { AkitaProductsQuery } from '../../products/state/products.query';

@Injectable({ providedIn: 'root' })
export class AkitaStartingPricesService {
  constructor(
    private readonly zone: NgZone,
    private readonly store: AkitaStartingPricesStore,
    private readonly searchStartingPricesApiService: SearchStartingPricesApiService,
    private readonly akitaProductsQuery: AkitaProductsQuery
  ) {}

  public findStartingPricesAsync(country: string | null): Subscription {
    return this.findStartingPrices(country).subscribe({
      next: () => {},
      error: () => {},
    });
  }

  public findStartingPrices(
    country: string | null
  ): Observable<Array<StartingPriceModel>> {
    // check for existing values on state
    if (
      this.store.getValue().fetchingStartingPrices ||
      (this.store.getValue().startingPrices &&
        this.store.getValue().startingPrices[country || ''])
    ) {
      return of(this.store.getValue().startingPrices[country || '']);
    }

    const validLocale = this.akitaProductsQuery.validateLocale(null, country);
    country = validLocale.country;

    this.zone.run(() => {
      applyTransaction(() => {
        logAction('findStartingPrices()');
        this.store.setErrorFetchingStartingPrices(null);
        this.store.toggleFetchingStartingPrices(true);
      });
    });

    return this.searchStartingPricesApiService.fetchStartingPrices(country).pipe(
      catchError((error: unknown) => {
        const parsed = parseApiError(error);
        this.zone.run(() => {
          applyTransaction(() => {
            logAction('findStartingPrices() - error');
            this.store.setErrorFetchingStartingPrices(parsed);
            this.store.toggleFetchingStartingPrices(false);
          });
        });
        return throwError(() => parsed);
      }),
      tap((response: Array<StartingPriceModel>) => {
        this.zone.run(() => {
          applyTransaction(() => {
            logAction('findStartingPrices() - done');
            this.store.setStartingPrices(response, country || '');
            this.store.toggleFetchingStartingPrices(false);
          });
        });
      })
    );
  }
}
