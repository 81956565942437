import { Injectable, NgZone } from '@angular/core';
import { applyTransaction, logAction } from '@datorama/akita';
import { Observable, throwError, Subscription, of, tap, catchError } from 'rxjs';
import { AkitaPaymentStore } from './payment.store';
import { parseApiError } from '@app/shared/models/api/api-error.model';
import { configureScope } from '@sentry/browser';
import { AkitaPaymentQuery } from './payment.query';
import { TabbyPublicKey } from '../models/tabby-public-key.model';
import { TabbyApiService } from '../services/tabby.service';
import { TabbySessionApiRequest } from '../models/tabby-session-api-config.model';
import { CheckoutDotComPublicKey } from '../models/checkout.com-public-key.model';
import { CheckoutDotComApiService } from '../services/checkout.com.service';
import { TamaraApiService } from '../services/tamara.service';
import { TamaraPublicKey } from '../models/tamara-public-key.model';
import { Product } from '../../products/models/product.model';
import { SessionAddressModel } from '../../checkout/models/session-address.model';
import { TabbySessionApiResponse } from '@app/checkout/payment-providers/tabby/models/tabby-session.model';
import { TamaraOptionsApiResponse } from '@app/checkout/payment-providers/tamara/models/tamara-options.model';
import { TAMARA_SUPPORTED_COUNTRIES } from '@app/checkout/payment-providers/tamara/services/tamara-actions.service';
import { SentryUtil } from '@app/shared/utils/sentry.util';

@Injectable({ providedIn: 'root' })
export class AkitaPaymentService {
  constructor(
    private readonly zone: NgZone,
    private readonly store: AkitaPaymentStore,
    private readonly query: AkitaPaymentQuery,
    private readonly tabbyApiService: TabbyApiService,
    private readonly tamaraApiService: TamaraApiService,
    private readonly checkoutDotComApiService: CheckoutDotComApiService
  ) {}

  public reset(): void {
    this.zone.run(() => {
      applyTransaction(() => {
        logAction('reset()');
        this.store.reset();
      });
    });
  }

  public setCurrency(currency: string | null): void {
    configureScope((scope) => {
      scope.setTag('delivery:currency', `${currency || '?'}`);
    });

    this.zone.run(() => {
      applyTransaction(() => {
        logAction('setCurrency()');
        this.store.setCurrency(currency);
      });
    });
  }

  public updateTabbyPublicKey(token: string | null): void {
    this.zone.run(() => {
      applyTransaction(() => {
        logAction('updateTabbyPublicKey()');
        this.store.setTabbyPublicKey(token);
      });
    });
  }

  public getTabbyPublicKey(): Observable<TabbyPublicKey | null> {
    this.zone.run(() => {
      applyTransaction(() => {
        logAction('getTabbyPublicKey()');
        this.store.toggleGetTabbyPublicKey(true);
        this.store.setGetTabbyPublicKeyError(null);
      });
    });

    return this.tabbyApiService.fetchPublicKey().pipe(
      catchError((error: unknown) => {
        const parsed = parseApiError(error);
        this.zone.run(() => {
          applyTransaction(() => {
            logAction('getTabbyPublicKey() - error');
            this.store.toggleGetTabbyPublicKey(false);
            this.store.setGetTabbyPublicKeyError(parsed);
          });
        });

        return throwError(() => parsed);
      }),
      tap((moipPublicKey: TabbyPublicKey | null) => {
        this.zone.run(() => {
          applyTransaction(() => {
            logAction('getTabbyPublicKey() - done');
            this.store.toggleGetTabbyPublicKey(false);
            this.store.setTabbyPublicKey(moipPublicKey?.publicToken || null);
          });
        });
      })
    );
  }

  public getTabbyPublicKeyAsync(): Subscription {
    return this.getTabbyPublicKey().subscribe({
      next: () => {},
      error: () => {},
    });
  }

  public updateTamaraPublicKey(token: string | null): void {
    this.zone.run(() => {
      applyTransaction(() => {
        logAction('updateTamaraPublicKey()');
        this.store.setTamaraPublicKey(token);
      });
    });
  }

  public getTamaraPublicKey(): Observable<TabbyPublicKey | null> {
    this.zone.run(() => {
      applyTransaction(() => {
        logAction('getTamaraPublicKey()');
        this.store.toggleGetTamaraPublicKey(true);
        this.store.setGetTamaraPublicKeyError(null);
      });
    });

    return this.tamaraApiService.fetchPublicKey().pipe(
      catchError((error: unknown) => {
        const parsed = parseApiError(error);
        this.zone.run(() => {
          applyTransaction(() => {
            logAction('getTamaraPublicKey() - error');
            this.store.toggleGetTamaraPublicKey(false);
            this.store.setGetTamaraPublicKeyError(parsed);
          });
        });

        return throwError(() => parsed);
      }),
      tap((moipPublicKey: TamaraPublicKey | null) => {
        this.zone.run(() => {
          applyTransaction(() => {
            logAction('getTamaraPublicKey() - done');
            this.store.toggleGetTamaraPublicKey(false);
            this.store.setTamaraPublicKey(moipPublicKey?.publicToken || null);
          });
        });
      })
    );
  }

  public getTamaraPublicKeyAsync(): Subscription {
    return this.getTamaraPublicKey().subscribe({
      next: () => {},
      error: () => {},
    });
  }

  public updateCheckoutDotComPublicKey(token: string | null): void {
    this.zone.run(() => {
      applyTransaction(() => {
        logAction('updateCheckoutDotComPublicKey()');
        this.store.setCheckoutDotComPublicKey(token);
      });
    });
  }

  public getCheckoutDotComPublicKey(): Observable<CheckoutDotComPublicKey | null> {
    this.zone.run(() => {
      applyTransaction(() => {
        logAction('getCheckoutDotComPublicKey()');
        this.store.toggleGetCheckoutDotComPublicKey(true);
        this.store.setGetCheckoutDotComPublicKeyError(null);
      });
    });

    return this.checkoutDotComApiService.fetchPublicKey().pipe(
      catchError((error: unknown) => {
        const parsed = parseApiError(error);
        this.zone.run(() => {
          applyTransaction(() => {
            logAction('getCheckoutDotComPublicKey() - error');
            this.store.toggleGetCheckoutDotComPublicKey(false);
            this.store.setGetCheckoutDotComPublicKeyError(parsed);
          });
        });

        return throwError(() => parsed);
      }),
      tap((moipPublicKey: CheckoutDotComPublicKey | null) => {
        this.zone.run(() => {
          applyTransaction(() => {
            logAction('getCheckoutDotComPublicKey() - done');
            this.store.toggleGetCheckoutDotComPublicKey(false);
            this.store.setCheckoutDotComPublicKey(moipPublicKey?.publicToken || null);
          });
        });
      })
    );
  }

  public getCheckoutDotComPublicKeyAsync(): Subscription {
    return this.getCheckoutDotComPublicKey().subscribe({
      next: () => {},
      error: () => {},
    });
  }

  public generateTabbySession(
    configuration: TabbySessionApiRequest,
    recreate?: boolean | null
  ): Observable<TabbySessionApiResponse | null> {
    const currentSession = this.query.getTabbySession(configuration.listingId);

    if (!recreate && currentSession?.status === 'CREATED') {
      return of(currentSession);
    } else {
      const arrayIds: Array<string> = new Array(0);
      configuration.products.forEach((item) => {
        for (let unit = 1; unit <= item.quantity; unit++) {
          arrayIds.push(`${item.product_id}`);
        }
      });
      const productIds = arrayIds.join('-');

      if (this.query.getGeneratingTabbySession(productIds)) {
        return of(null);
      }

      SentryUtil.addBreadcrumb({
        category: 'Payment API',
        message: `generating Tabby Session`,
        level: 'info',
        type: 'http',
      });

      this.zone.run(() => {
        applyTransaction(() => {
          logAction('generateTabbySession()');
          this.store.toggleGeneratingTabbySession(productIds, true);
          this.store.setGeneratingTabbySessionError(productIds, null);
        });
      });

      return this.tabbyApiService.generateSession(configuration).pipe(
        catchError((error: unknown) => {
          const parsed = parseApiError(error);
          this.zone.run(() => {
            applyTransaction(() => {
              SentryUtil.addBreadcrumb({
                category: 'Payment API',
                message: `Failed Generating Tabby Session`,
                level: 'warning',
                type: 'http',
              });
              logAction('generateTabbySession() - error');
              this.store.toggleGeneratingTabbySession(productIds, false);
              this.store.setGeneratingTabbySessionError(productIds, parsed);
              this.store.setTabbySession(null);
            });
          });

          return throwError(() => parsed);
        }),
        tap((session: TabbySessionApiResponse | null) => {
          this.zone.run(() => {
            applyTransaction(() => {
              logAction('generateTabbySession() - done');
              this.store.toggleGeneratingTabbySession(productIds, false);
              this.store.setTabbySession(productIds, session || null);
            });
          });
        })
      );
    }
  }

  public generateTabbySessionAsync(
    configuration: TabbySessionApiRequest,
    recreate?: boolean | null
  ): Subscription {
    return this.generateTabbySession(configuration, recreate).subscribe({
      next: () => {},
      error: () => {},
    });
  }

  public fetchTamaraUserOptionsAsync(
    lang: string,
    products: Array<Product>,
    totalAmount: number,
    discount: number,
    address: SessionAddressModel,
    email?: string | null,
    name?: string | null,
    phone?: string | null
  ): Subscription {
    return this.fetchTamaraUserOptions(
      lang,
      products,
      totalAmount,
      discount,
      address,
      email,
      name,
      phone
    ).subscribe({
      next: () => {},
      error: () => {},
    });
  }

  public fetchTamaraUserOptions(
    lang: string,
    products: Array<Product>,
    totalAmount: number,
    discount: number,
    address: SessionAddressModel,
    email?: string | null,
    name?: string | null,
    phone?: string | null
  ): Observable<TamaraOptionsApiResponse | null> {
    const country = address.country;

    if (this.query.getValue().fetchingTamaraPaymentOptions[country]) {
      return of(null);
    }

    SentryUtil.addBreadcrumb({
      category: 'Payment API',
      message: `fetching Tamara Options`,
      level: 'info',
      type: 'http',
    });

    this.zone.run(() => {
      applyTransaction(() => {
        logAction('fetchTamaraPaymentOptions()');
        this.store.toggleFetchingTamaraPaymentOptions(country, true);
        this.store.setFetchingTamaraPaymentOptionsError(country, null);
      });
    });

    return this.tamaraApiService
      .checkUserOptions(
        lang,
        products,
        totalAmount,
        discount,
        null,
        address,
        email,
        name,
        phone
      )
      .pipe(
        catchError((error: unknown) => {
          const parsed = parseApiError(error);
          this.zone.run(() => {
            applyTransaction(() => {
              logAction('fetchTamaraPaymentOptions() - error');
              this.store.toggleFetchingTamaraPaymentOptions(country, false);
              this.store.setFetchingTamaraPaymentOptionsError(country, parsed);
              this.store.setTamaraPaymentOptions(country, null);
            });
          });
          SentryUtil.addBreadcrumb({
            category: 'Payment API',
            message: `Failedfetching Tamara Session`,
            level: 'warning',
            type: 'http',
          });

          return throwError(() => parsed);
        }),
        tap((options: TamaraOptionsApiResponse | null) => {
          this.zone.run(() => {
            applyTransaction(() => {
              logAction('fetchTamaraPaymentOptions() - done');
              this.store.toggleFetchingTamaraPaymentOptions(country, false);
              this.store.setTamaraPaymentOptions(country, options?.options || null);
            });
          });
        })
      );
  }

  public fetchTamaraPaymentOptions(
    country: string
  ): Observable<TamaraOptionsApiResponse | null> {
    // Verify Supported Countries
    if (
      !TAMARA_SUPPORTED_COUNTRIES.includes(country) ||
      this.query.getValue().fetchingTamaraPaymentOptions[country]
    ) {
      return of(null);
    }

    this.zone.run(() => {
      applyTransaction(() => {
        logAction('fetchTamaraPaymentOptions()');
        this.store.toggleFetchingTamaraPaymentOptions(country, true);
        this.store.setFetchingTamaraPaymentOptionsError(country, null);
      });
    });

    return this.tamaraApiService.checkOptions(country).pipe(
      catchError((error: unknown) => {
        const parsed = parseApiError(error);
        this.zone.run(() => {
          applyTransaction(() => {
            logAction('fetchTamaraPaymentOptions() - error');
            this.store.toggleFetchingTamaraPaymentOptions(country, false);
            this.store.setFetchingTamaraPaymentOptionsError(country, parsed);
            this.store.setTamaraPaymentOptions(null);
          });
        });

        return throwError(() => parsed);
      }),
      tap((options: TamaraOptionsApiResponse | null) => {
        this.zone.run(() => {
          applyTransaction(() => {
            logAction('fetchTamaraPaymentOptions() - done');
            this.store.toggleFetchingTamaraPaymentOptions(country, false);
            this.store.setTamaraPaymentOptions(country, options?.options || null);
          });
        });
      })
    );
  }

  public fetchTamaraPaymentOptionsAsync(country: string): Subscription {
    return this.fetchTamaraPaymentOptions(country).subscribe({
      next: () => {},
      error: () => {},
    });
  }

  public validateApplePaySession(appleID: string): Observable<any> {
    this.zone.run(() => {
      applyTransaction(() => {
        logAction('validateApplePay()');
        this.store.toggleValidatingApplePaySession(true);
        this.store.setValidatingApplePaySessionError(null);
      });
    });

    return this.checkoutDotComApiService.validateApplePay(appleID).pipe(
      catchError((err: unknown) => {
        const parsed = parseApiError(err);
        this.zone.run(() => {
          applyTransaction(() => {
            logAction('validateApplePay() - error');
            this.store.toggleValidatingApplePaySession(false);
            this.store.setValidatingApplePaySessionError(parsed);
          });
        });

        return throwError(() => parsed);
      }),
      tap((session: any) => {
        this.zone.run(() => {
          applyTransaction(() => {
            logAction('validateApplePay() - done');
            this.store.toggleValidatingApplePaySession(false);
            this.store.setApplePaySession(session);
          });
        });
      })
    );
  }
}
