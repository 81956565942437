import { parseTabbyErrorList, TabbyError } from './tabby-error.model';
import { parseTabbyPayment, TabbyPayment } from './tabby-payment.model';
import { parseTabbyProducts, TabbyProducts } from './tabby-products.model';

export class TabbyOnChangeResponse {
  public errors: Array<TabbyError>;
  public id: string;
  public status: string;
  public statusMessage: string;
  public products: TabbyProducts;
  public payment: TabbyPayment;

  constructor() {
    this.errors = new Array(0);
    this.id = '';
    this.status = '';
    this.statusMessage = '';
    this.products = new TabbyProducts();
    this.payment = new TabbyPayment();
  }
}

export const parseTabbyOnChangeResponse = (
  data?: Record<string, any> | null
): TabbyOnChangeResponse | null => {
  let result: TabbyOnChangeResponse | null = null;
  if (data) {
    result = new TabbyOnChangeResponse();
    result.errors = parseTabbyErrorList(data.errors);
    result.id = data.id || '';
    result.status = data.status || '';
    result.statusMessage = data.status_message || data.statusMessage || '';
    result.products = parseTabbyProducts(data.products) || new TabbyProducts();
    result.payment = parseTabbyPayment(data.payment) || new TabbyPayment();
  }
  return result;
};
