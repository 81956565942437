import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnDestroy,
  ViewChild,
  Input,
  NgZone,
} from '@angular/core';
import { Params } from '@angular/router';

import { BehaviorSubject, Observable, Subject, Subscription, takeUntil } from 'rxjs';

import { AkitaScreenQuery } from '@app/akita/screen/state/screen.query';
import { ScreenSizeInfoState } from '@app/akita/screen/state/screen.store';

import { AkitaRouterQuery } from '@app/akita/router/state/router.query';
import { UntypedFormControl, Validators, ReactiveFormsModule } from '@angular/forms';
import { AkitaLocationQuery } from '@app/akita/api/location/state/location.query';
import { NewsletterAPIService } from '@app/akita/api/newsletter/services/newsletter-api.service';
import { logNewsletterSubscribeFailed } from '@app/core/services/google-analytics.lazy';
import { GoogleAnalyticsService } from '@app/core/services/google-analytics.service';
import { TidioService } from '@app/shared/services/tidio.service';
import { KeyboardArrowLeftIconComponent } from '../icons/generated/keyboard-arrow-left/keyboard-arrow-left.component';
import { KeyboardArrowRightIconComponent } from '../icons/generated/keyboard-arrow-right/keyboard-arrow-right.component';
import { RemoveIconComponent } from '../icons/generated/remove/remove.component';
import { NgIf, AsyncPipe } from '@angular/common';
import { TranslocoModule } from '@ngneat/transloco';

@Component({
  selector: 'app-subscribe-box',
  templateUrl: './subscribe-box.template.html',
  styleUrls: ['./subscribe-box.style.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    TranslocoModule,
    NgIf,
    ReactiveFormsModule,
    RemoveIconComponent,
    KeyboardArrowRightIconComponent,
    KeyboardArrowLeftIconComponent,
    AsyncPipe,
  ],
})
export class SubscribeBoxComponent implements OnDestroy {
  @Input() public subscribeType?: string | null;

  public inFocus: boolean;
  public queryParams: Params;
  public isBrowser: boolean;

  public formControl: UntypedFormControl;

  public submitted: boolean;

  private readonly loadingSubject: BehaviorSubject<boolean>;
  public readonly loading$: Observable<boolean>;

  private readonly subscribedSubject: BehaviorSubject<boolean>;
  public readonly subscribed$: Observable<boolean>;

  private readonly subscriptions: Subscription;

  @ViewChild('subscribeField')
  public subscribeFieldElement?: ElementRef | null;

  public lessThan$: Observable<ScreenSizeInfoState>;
  public biggerThan$: Observable<ScreenSizeInfoState>;
  public rtlMode: boolean;

  private readonly destroySubject: Subject<void>;

  constructor(
    private readonly zone: NgZone,
    private readonly changeDetector: ChangeDetectorRef,
    private readonly akitaScreenQuery: AkitaScreenQuery,
    private readonly akitaRouterQuery: AkitaRouterQuery,
    private readonly akitaLocationQuery: AkitaLocationQuery,
    private readonly newsletterAPIService: NewsletterAPIService,
    private readonly googleAnalyticsService: GoogleAnalyticsService,
    private readonly tidioService: TidioService
  ) {
    this.destroySubject = new Subject();
    this.isBrowser = this.akitaRouterQuery.isBrowser;
    this.queryParams = {};
    this.rtlMode = this.akitaRouterQuery.isRtlLanguage;
    this.inFocus = false;
    this.lessThan$ = this.akitaScreenQuery.selectLessThan();
    this.biggerThan$ = this.akitaScreenQuery.selectBiggerThan();

    this.subscriptions = new Subscription();
    this.lessThan$ = this.akitaScreenQuery.selectLessThan();

    this.submitted = false;

    this.loadingSubject = new BehaviorSubject(false as any);
    this.loading$ = this.loadingSubject.asObservable();

    this.subscribedSubject = new BehaviorSubject(false as any);
    this.subscribed$ = this.subscribedSubject.asObservable();

    this.formControl = new UntypedFormControl('', {
      validators: Validators.compose([Validators.required, Validators.email]),
      updateOn: 'change',
    });

    this.subscriptions.add(
      this.formControl.valueChanges.pipe(takeUntil(this.destroySubject)).subscribe({
        next: () => {
          this.submitted = false;
        },
        error: () => {},
      })
    );
  }

  public subscribe(): void {
    // this.requestLocation();

    // Remove Focus
    if (this.subscribeFieldElement) {
      this.subscribeFieldElement.nativeElement.blur();
    }
  }

  public getClearSubscribeQuery(): Params {
    return {
      ...this.queryParams,
      ['text']: undefined,
    };
  }

  public close(): void {
    this.zone.run(() => {
      this.inFocus = false;
      this.changeDetector.markForCheck();
    });
  }

  public openSubscribeInput(): boolean {
    if (!this.inFocus) {
      this.subscribeFieldElement?.nativeElement.focus();
      this.submitted = true;
      this.inFocus = true;
    }
    return false;
  }

  public onSubscribeToNewsletter(): boolean {
    this.submitted = true;

    if (this.formControl.valid) {
      const country = this.akitaLocationQuery.country;
      const email = `${this.formControl.value || ''}`;
      this.loadingSubject.next(true);

      this.tidioService.addVisitorTags(['newsletter_email']);
      this.tidioService.setVisitorData(null, null, null, email, null, null, [
        'newsletter_email',
      ]);

      this.subscriptions.add(
        this.newsletterAPIService
          .signUpToNewsletter(country, email)
          .pipe(takeUntil(this.destroySubject))
          .subscribe({
            next: () => {
              this.subscribedSubject.next(true);
              this.loadingSubject.next(false);
            },
            error: () => {
              logNewsletterSubscribeFailed(
                this.googleAnalyticsService.logEventWrapper,
                email
              );

              this.subscribedSubject.next(true);
              this.loadingSubject.next(false);
              console.log('error');
            },
          })
      );
    }
    return false;
  }

  public ngOnDestroy(): void {
    this.destroySubject.next();
    this.destroySubject.complete();
  }
}
