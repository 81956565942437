import { StoreConfig, Store } from '@datorama/akita';
import { Injectable } from '@angular/core';
import { ApiError } from '@app/shared/models/api/api-error.model';
import {
  AkitaBuybackState,
  BuyBackStepOption,
  createInitialBuybackState,
} from '../models/buyback.state';
import { BuybackOfferModel } from '../models/buyback.offer.model';
import { BuybackOrderModel } from '../models/buyback-order.model';

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'buyback', resettable: true })
export class AkitaBuybackStore extends Store<AkitaBuybackState> {
  constructor() {
    super(createInitialBuybackState());
  }

  public reset(): void {
    this.update(createInitialBuybackState());
  }

  public setUserDetails(userDetails: any): void {
    this.update({
      userDetails,
    });
  }

  public setIban(iban: string | null): void {
    this.update({
      iban,
    });
  }

  public setSelectedOffer(offer: BuybackOfferModel): void {
    this.update({
      selectedOffer: offer,
    });
  }

  public toggleFetchingProducts(fetching?: boolean | null): void {
    this.update({
      fetchingProducts: Boolean(fetching),
    });
  }
  public setErrorFetchingProducts(error?: ApiError | null): void {
    this.update({
      erroFetchingProducts: error || null,
    });
  }

  public setErrorSubmittingOffer(error?: ApiError | null): void {
    this.update({
      errorSubmittingOffer: error || null,
    });
  }

  public setIsSubmitting(isSubmitting?: boolean | null): void {
    this.update({
      isSubmitting: Boolean(isSubmitting),
    });
  }

  public setOrder(order: any): void {
    this.update({
      order,
    });
  }

  public setProducts(country: string, path: string, products: any): void {
    this.update((state) => {
      const newState = { ...state };
      newState.products = { ...newState.products };
      newState.products[country] = { ...newState.products[country] };
      newState.products[country][path] = products;
      return newState;
    });
  }

  public setStep(step: string): void {
    this.update({
      form: { ...this.getValue().form, step },
    });
  }

  public setStepIndex(stepIndex: number): void {
    this.update({
      form: { ...this.getValue().form, stepIndex },
    });
  }

  public setModel(model: string | null): void {
    this.update({
      form: { ...this.getValue().form, model },
    });
  }

  public setCategory(category: string | null): void {
    this.update({
      form: { ...this.getValue().form, category },
    });
  }

  public setFlowSteps(flowSteps: Array<BuyBackStepOption>): void {
    this.update({
      form: { ...this.getValue().form, flowSteps },
    });
  }

  public setFormValues(formValues: Array<BuyBackStepOption>): void {
    this.update({
      form: { ...this.getValue().form, formValues },
    });
  }

  public setUserOrders(orders: Array<BuybackOrderModel>): void {
    this.update({
      userOrders: orders,
    });
  }
}
