/* eslint-disable @typescript-eslint/no-magic-numbers */
import { ChangeDetectionStrategy, Component, Input, Signal } from '@angular/core';
import { TranslocoModule, TranslocoService } from '@ngneat/transloco';
import { CommonModule } from '@angular/common';
import { QuaraArIconComponent } from '@app/shared/components/icons/generated/quara-ar/quara-ar.component';
import { QuaraIconComponent } from '@app/shared/components/icons/generated/quara/quara.component';
import { toSignal } from '@angular/core/rxjs-interop';

@Component({
  standalone: true,
  selector: 'popsy-quara-badge',
  templateUrl: './quara-badge.template.html',
  styleUrls: ['./quara-badge.style.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CommonModule, QuaraArIconComponent, QuaraIconComponent, TranslocoModule],
})
export class QuaraBadgeComponent {
  @Input() public size?: 'small' | 'medium' | 'default' = 'default';

  public readonly language: Signal<string>;

  constructor(private readonly translateService: TranslocoService) {
    this.language = toSignal(this.translateService.langChanges$, {
      initialValue: this.translateService.getActiveLang(),
    });
  }
}
