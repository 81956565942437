import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  WritableSignal,
  signal,
} from '@angular/core';
import { AkitaProductsQuery } from '@app/akita/api/products/state/products.query';
import { AkitaRouterQuery } from '@app/akita/router/state/router.query';
import { TranslocoModule, TranslocoService } from '@ngneat/transloco';
import { BehaviorSubject, Observable, Subject, Subscription, takeUntil } from 'rxjs';

import { RouterModule } from '@angular/router';
import { ApplePayIconComponent } from '@app/shared/components/icons/generated/apple-pay/apple-pay.component';
import { StcPayIconComponent } from '../icons/generated/stc-pay/stc-pay.component';
import { AmexIconComponent } from '../icons/generated/amex/amex.component';
import { PaypalIconComponent } from '../icons/generated/paypal/paypal.component';
import { MadaIconComponent } from '../icons/generated/mada/mada.component';
import { BoletoPayIconComponent } from '../icons/generated/boleto-pay/boleto-pay.component';
import { CashOnDeliveryIconComponent } from '../icons/generated/cash-on-delivery/cash-on-delivery.component';
import { KeyboardArrowUpIconComponent } from '../icons/generated/keyboard-arrow-up/keyboard-arrow-up.component';
import { DinersClubIconComponent } from '../icons/generated/diners-club/diners-club.component';
import { VisaIconComponent } from '../icons/generated/visa/visa.component';
import { MastercardIconComponent } from '../icons/generated/mastercard/mastercard.component';
import { GooglePayIconComponent } from '../icons/generated/google-pay/google-pay.component';
import { CommonModule } from '@angular/common';
import { TABBY_SUPPORTED_COUNTRIES } from '@app/checkout/payment-providers/tabby/services/tabby-actions.service';
import { TAMARA_SUPPORTED_COUNTRIES } from '@app/checkout/payment-providers/tamara/services/tamara-actions.service';
// eslint-disable-next-line max-len
import {
  BASEETA_SUPPORTED_COUNTRIES,
  SHOW_BASEETA_PROMO,
} from '@app/checkout/payment-providers/baseeta/components/baseeta-promo/baseeta-promo.component';
import { BaseetaOverlayDynamicService } from '@app/overlays/baseeta-overlay/services/baseeta-overlay-dynamic.service';
import { QuaraBadgeComponent } from '../../../checkout/payment-providers/quara/components/quara-badge/quara-badge.component';
import { BaseetaBadgeComponent } from '../../../checkout/payment-providers/baseeta/components/baseeta-badge/baseeta-badge.component';
import { TabbyBadgeComponent } from '../../../checkout/payment-providers/tabby/components/tabby-badge/tabby-badge.component';
import { TamaraBadgeComponent } from '../../../checkout/payment-providers/tamara/components/tamara-badge/tamara-badge.component';

@Component({
  standalone: true,
  selector: 'app-payment-methods-line',
  templateUrl: './payment-methods-line.template.html',
  styleUrls: ['./payment-methods-line.style.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    RouterModule,
    CommonModule,
    TranslocoModule,
    // Icons
    ApplePayIconComponent,
    GooglePayIconComponent,
    MastercardIconComponent,
    VisaIconComponent,
    AmexIconComponent,
    DinersClubIconComponent,
    KeyboardArrowUpIconComponent,
    CashOnDeliveryIconComponent,
    BoletoPayIconComponent,
    MadaIconComponent,
    PaypalIconComponent,
    AmexIconComponent,
    StcPayIconComponent,
    QuaraBadgeComponent,
    BaseetaBadgeComponent,
    TabbyBadgeComponent,
    TamaraBadgeComponent,
  ],
})
export class PaymentMethodsLineComponent implements OnDestroy {
  @Input() public footerMode?: boolean | null = null;
  @Input() public expressMode?: boolean | null = null;

  public language$: Observable<string>;

  private readonly displayTabbySubject: BehaviorSubject<boolean>;
  public displayTabby$: Observable<boolean>;

  private readonly displayTamaraSubject: BehaviorSubject<boolean>;
  public displayTamara$: Observable<boolean>;

  private readonly displaySTCSubject: BehaviorSubject<boolean>;
  public displaySTC$: Observable<boolean>;

  public displayQuara: WritableSignal<boolean> = signal(false);

  public displayBaseeta: WritableSignal<boolean> = signal(false);

  private readonly displayMadaSubject: BehaviorSubject<boolean>;
  public displayMada$: Observable<boolean>;

  public locationObserver?: Subscription | null;

  public showingBaseetaOverlay: boolean;
  private openBaseetaOverlaySubscription?: Subscription | null;

  private readonly destroySubject: Subject<void>;

  constructor(
    private readonly akitaRouterQuery: AkitaRouterQuery,
    private readonly translateService: TranslocoService,
    private readonly akitaProductsQuery: AkitaProductsQuery,
    private readonly detectChanges: ChangeDetectorRef,
    private readonly baseetaOverlayDynamicService: BaseetaOverlayDynamicService
  ) {
    this.destroySubject = new Subject();
    this.showingBaseetaOverlay = false;

    this.language$ = this.translateService.langChanges$;

    this.displayTabbySubject = new BehaviorSubject(this.shouldDisplayTabby());
    this.displayTabby$ = this.displayTabbySubject.asObservable();

    this.displayTamaraSubject = new BehaviorSubject(this.shouldDisplayTamara());
    this.displayTamara$ = this.displayTabbySubject.asObservable();

    this.displaySTCSubject = new BehaviorSubject(this.shouldDisplayTamara());
    this.displaySTC$ = this.displaySTCSubject.asObservable();

    this.displayMadaSubject = new BehaviorSubject(this.shouldDisplaySTC());
    this.displayMada$ = this.displayMadaSubject.asObservable();

    if (this.akitaRouterQuery.isBrowser) {
      this.locationObserver = this.akitaProductsQuery
        .selectCountry()
        .pipe(takeUntil(this.destroySubject))
        .subscribe({
          next: (country) => {
            this.displayTabbySubject.next(this.shouldDisplayTabby(country));
            this.displayTamaraSubject.next(this.shouldDisplayTamara(country));
            this.displayMadaSubject.next(this.shouldDisplayMada(country));
            this.displaySTCSubject.next(this.shouldDisplaySTC(country));
            this.displayQuara.set(false); // this.displayQuara.set(this.shouldDisplayQuara(country));
            this.displayBaseeta.set(this.shouldDisplayBaseeta(country));
          },
        });
    }
  }

  private shouldDisplayTabby(currentCountry?: string | null): boolean {
    return TABBY_SUPPORTED_COUNTRIES.includes(`${currentCountry || '--'}`);
  }

  private shouldDisplayTamara(currentCountry?: string | null): boolean {
    return TAMARA_SUPPORTED_COUNTRIES.includes(`${currentCountry || '--'}`);
  }

  private shouldDisplaySTC(currentCountry?: string | null): boolean {
    return Boolean(currentCountry === 'SA');
  }

  private shouldDisplayMada(currentCountry?: string | null): boolean {
    return Boolean(currentCountry === 'SA');
  }

  public shouldDisplayQuara(currentCountry?: string | null): boolean {
    return Boolean(currentCountry === 'SA');
  }

  private shouldDisplayBaseeta(currentCountry?: string | null): boolean {
    return Boolean(
      BASEETA_SUPPORTED_COUNTRIES.includes(currentCountry || '') && SHOW_BASEETA_PROMO
    );
  }

  public openBaseetaOverlay(): void {
    if (this.openBaseetaOverlaySubscription) {
      this.openBaseetaOverlaySubscription.unsubscribe();
    }

    this.openBaseetaOverlaySubscription = this.baseetaOverlayDynamicService.observe
      .pipe(takeUntil(this.destroySubject))
      .subscribe({
        next: (data?: any | null) => {
          this.showingBaseetaOverlay = Boolean(data);
          this.detectChanges.markForCheck();
        },
      });
    this.baseetaOverlayDynamicService.open({
      data: {
        source: 'product',
        mode: 'installments',
      },
      isBrowser: this.akitaRouterQuery.isBrowser,
      // belementRef: this.quaraButtonRef,
    });
  }

  public ngOnDestroy(): void {
    this.destroySubject.next();
    this.destroySubject.complete();

    if (this.locationObserver) {
      this.locationObserver.unsubscribe();
    }
  }
}
