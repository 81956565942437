import { inject, Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { getApiEndpoint } from '@app/shared/utils/url.utils';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AkitaAuthQuery } from '../../auth/state/auth.query';
import { TranslocoService } from '@ngneat/transloco';
import { DEFAULT_AVAILABLE_LANGUAGES } from '../../products/models/products.state';

/**
 * Class to handle the API Calls
 */
@Injectable({
  providedIn: 'root',
})
export class NewsletterAPIService {
  private readonly http = inject(HttpClient);
  private readonly translateService = inject(TranslocoService);

  constructor(private readonly akitaAuthQuery: AkitaAuthQuery) {}

  public signUpToNewsletter(
    country?: string | null,
    email?: string | null,
    phone?: string | null,
    name?: string | null,
    description?: string | null
  ): Observable<unknown> {
    if (!country) {
      return throwError(() => new Error('INVALID_COUNTRY'));
    }

    if (!email) {
      return throwError(() => new Error('INVALID_EMAIL'));
    }

    // eslint-disable-next-line deprecation/deprecation
    const credentials = btoa(`api:Lax4UpCrx9TAmhea`);
    const headers = new HttpHeaders({
      'Content-Type': 'application/json; charset=utf-8',
      Authorization: `Basic ${credentials}`,
    });

    const emailParts = email.split('@');
    const countryCode = `${country || 'US'}`.toUpperCase();
    const language = DEFAULT_AVAILABLE_LANGUAGES.includes(
      this.translateService.getActiveLang()
    )
      ? this.translateService.getActiveLang()
      : 'en';

    return this.http.put<unknown>(
      // Assemble the full API URL
      getApiEndpoint(`newsletter?country=${countryCode}`),
      {
        email: `${email || ''}`,
        phone: `${phone || ''}`,
        name: `${name || this.akitaAuthQuery.user?.firstName || emailParts[0] || ''}`,
        description: `${description || ''}`,
        language: language,
      },
      // Add the Options
      {
        responseType: 'json',
        headers: headers,
      }
    );
  }
}
