import { ApiError } from '@app/shared/models/api/api-error.model';
import {
  BuybackOfferModel,
  parseBuybackOfferModelFromJsonList,
} from './buyback.offer.model';
import { Params } from '@angular/router';
import { UserDetails } from '../../addresses/models/user-details.model';
import { BuybackOrderModel } from './buyback-order.model';

export interface BuyBackStepOption {
  [key: string]: string;
}

export interface BuybackFormModel {
  flowSteps: Array<BuyBackStepOption>;
  formValues: Array<BuyBackStepOption>;
  model: string | null;
  category: string | null;
  step: string | null;
  stepIndex: number;
}

export interface AkitaBuybackState {
  products: {
    [country: string]: {
      [path: string]: {
        offers: Array<BuybackOfferModel>;
        options: Params;
      };
    };
  };

  order: BuybackOrderModel | null;

  userOrders: Array<BuybackOrderModel> | null;

  form: BuybackFormModel;

  selectedOffer: BuybackOfferModel | null;
  userDetails: UserDetails | null;
  iban: string | null;

  isSubmitting: boolean;
  errorSubmittingOffer: ApiError | null;
  fetchingProducts: boolean;
  erroFetchingProducts: ApiError | null;
}

export function createInitialBuybackState(): AkitaBuybackState {
  return {
    order: null,
    userOrders: null,
    products: {},

    form: {
      flowSteps: [],
      formValues: [],
      model: null,
      category: null,
      step: null,
      stepIndex: 0,
    },

    userDetails: null,
    iban: null,
    selectedOffer: null,

    isSubmitting: false,
    errorSubmittingOffer: null,

    fetchingProducts: false,
    erroFetchingProducts: null,
  };
}

export function parseBuybackProductsFromJSON(data: Record<string, any>): {
  offers: Array<BuybackOfferModel>;
  options: Params;
} {
  const offers = parseBuybackOfferModelFromJsonList(data.prices || []);
  const options = data.options || {};

  return {
    offers,
    options,
  };
}
