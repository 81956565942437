/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { LoadableRootConfig } from '@app/ngx-loadable/loadable.config';

/* eslint-disable max-len */
export const LOADABLE_OPTIONS: LoadableRootConfig = {
  moduleConfigs: [
    {
      name: 'auth-overlay-module',
      load: () =>
        import(
          /* webpackChunkName: "auth-overlay" */ '@app/overlays/auth-overlay/auth-overlay.module'
        ).then((mod) => mod.AuthOverlayModule),
      preload: false,
      isElement: true,
    },
    {
      name: 'select-location-overlay',
      load: () =>
        import(
          /* webpackChunkName: "select-location-overlay" */ '@app/overlays/select-location-overlay/select-location-overlay.module'
        ).then((mod) => mod.SelectLocationOverlayModule),
      preload: false,
      isElement: true,
    },

    {
      name: 'filters-overlay',
      load: () =>
        import(
          /* webpackChunkName: "filters-overlay" */ '@app/overlays/filters-overlay/filters-overlay.module'
        ).then((mod) => mod.FiltersOverlayModule),
      preload: false,
      isElement: true,
    },
    {
      name: 'currency-overlay',
      load: () =>
        import(
          /* webpackChunkName: "currency-overlay" */ '@app/overlays/currency-overlay/currency-overlay.module'
        ).then((mod) => mod.CurrencyOverlayModule),
      preload: false,
      isElement: true,
    },
    {
      name: 'delete-account-overlay',
      load: () =>
        import(
          /* webpackChunkName: "delete-account-overlay" */ '@app/overlays/delete-account-overlay/delete-account-overlay.module'
        ).then((mod) => mod.DeleteAccountOverlayModule),
      preload: false,
      isElement: true,
    },
    {
      name: 'edit-profile-overlay',
      load: () =>
        import(
          /* webpackChunkName: "edit-profile-overlay" */ '@app/overlays/edit-profile/edit-profile-overlay.module'
        ).then((mod) => mod.EditProfileOverlayModule),
      preload: false,
      isElement: true,
    },
    {
      name: 'error-overlay',
      load: () =>
        import(
          /* webpackChunkName: "error-overlay" */ '@app/overlays/error-overlay/error-overlay.module'
        ).then((mod) => mod.ErrorOverlayModule),
      preload: true,
      isElement: true,
    },
    {
      name: 'confirm-overlay',
      load: () =>
        import(
          /* webpackChunkName: "confirm-overlay" */ '@app/overlays/confirm-overlay/confirm-overlay.module'
        ).then((mod) => mod.ConfirmOverlayModule),
      preload: false,
      isElement: true,
    },
    {
      name: 'footer-overlay',
      load: () =>
        import(
          /* webpackChunkName: "footer-overlay" */ '@app/overlays/footer-overlay/footer-overlay.module'
        ).then((mod) => mod.FooterOverlayModule),
      preload: false,
      isElement: true,
    },
    {
      name: 'quara-overlay',
      load: () =>
        import(
          /* webpackChunkName: "quara-overlay" */ '@app/overlays/quara-overlay/quara-overlay.module'
        ).then((mod) => mod.QuaraOverlayModule),
      preload: false,
      isElement: true,
    },
    {
      name: 'baseeta-overlay',
      load: () =>
        import(
          /* webpackChunkName: "baseeta-overlay" */ '@app/overlays/baseeta-overlay/baseeta-overlay.module'
        ).then((mod) => mod.BaseetaOverlayModule),
      preload: false,
      isElement: true,
    },
    {
      name: 'alrajhi-overlay',
      load: () =>
        import(
          /* webpackChunkName: "alrajhi-overlay" */ '@app/overlays/alrajhi-overlay/alrajhi-overlay.module'
        ).then((mod) => mod.AlrajhiOverlayModule),
      preload: false,
      isElement: true,
    },
    {
      name: 'list-overlay',
      load: () =>
        import(
          /* webpackChunkName: "footer-overlay" */ '@app/overlays/list-overlay/list-overlay.module'
        ).then((mod) => mod.ListOverlayModule),
      preload: false,
      isElement: true,
    },
    {
      name: 'insurance-confirm-overlay',
      load: () =>
        import(
          /* webpackChunkName: "insurance-confirm-overlay" */ '@app/overlays/insurance-confirm-overlay/insurance-confirm-overlay.module'
        ).then((mod) => mod.InsuranceConfirmOverlayModule),
      preload: false,
      isElement: true,
    },
    {
      name: 'video-overlay',
      load: () =>
        import(
          /* webpackChunkName: "video-overlay" */ '@app/overlays/video-overlay/video-overlay.module'
        ).then((mod) => mod.VideoOverlayModule),
      preload: false,
      isElement: true,
    },
    {
      name: 'conditions-info-overlay',
      load: () =>
        import(
          /* webpackChunkName: "conditions-info-overlay" */ '@app/overlays/conditions-info-overlay/conditions-info-overlay.module'
        ).then((mod) => mod.ConditionsInfoOverlayModule),
      preload: false,
      isElement: true,
    },
    {
      name: 'report-overlay',
      load: () =>
        import(
          /* webpackChunkName: "report-overlay" */ '@app/overlays/report-overlay/report-overlay.module'
        ).then((mod) => mod.ReportOverlayModule),
      preload: false,
      isElement: true,
    },
    {
      name: 'social-share-overlay',
      load: () =>
        import(
          /* webpackChunkName: "social-share-overlay" */ '@app/overlays/social-share-overlay/social-share-overlay.module'
        ).then((mod) => mod.SocialShareOverlayModule),
      preload: false,
      isElement: true,
    },

    {
      name: 'cart-overlay',
      load: () =>
        import(
          /* webpackChunkName: "cart-overlay" */ '@app/overlays/cart-overlay/cart-overlay.module'
        ).then((mod) => mod.CartOverlayModule),
      preload: false,
      isElement: true,
    },
    {
      name: 'cart-promo-overlay',
      load: () =>
        import(
          /* webpackChunkName: "cart-promo-overlay" */ '@app/overlays/cart-promo-overlay/cart-promo-overlay.module'
        ).then((mod) => mod.CartPromoOverlayModule),
      preload: false,
      isElement: true,
    },
    {
      name: 'insurance-overlay',
      load: () =>
        import(
          /* webpackChunkName: "insurance-overlay" */ '@app/overlays/insurance-overlay/insurance-overlay.module'
        ).then((mod) => mod.InsuranceOverlayModule),
      preload: false,
      isElement: true,
    },

    {
      name: 'package-info-overlay',
      load: () =>
        import(
          /* webpackChunkName: "package-info-overlay" */ '@app/overlays/package-info-overlay/package-info-overlay.module'
        ).then((mod) => mod.PackageInfoOverlayModule),
      preload: false,
      isElement: true,
    },
    {
      name: 'tracking-info-overlay',
      load: () =>
        import(
          /* webpackChunkName: "tracking-info-overlay" */ '@app/overlays/tracking-info-overlay/tracking-info-overlay.module'
        ).then((mod) => mod.TrackingInfoOverlayModule),
      preload: false,
      isElement: true,
    },
    {
      name: 'battery-overlay',
      load: () =>
        import(
          /* webpackChunkName: "battery-overlay" */ '@app/overlays/battery-overlay/battery-overlay.module'
        ).then((mod) => mod.BatteryOverlayModule),
      preload: false,
      isElement: true,
    },

    {
      name: 'retry-payment-overlay',
      load: () =>
        import(
          /* webpackChunkName: "retry-payment-overlay" */ '@app/overlays/retry-payment-overlay/retry-payment-overlay.module'
        ).then((mod) => mod.RetryPaymentOverlayModule),
      preload: false,
      isElement: true,
    },

    {
      name: 'burguer-menu-overlay',
      load: () =>
        import(
          /* webpackChunkName: "burguer-menu-overlay" */ '@app/overlays/burguer-menu-overlay/burguer-menu-overlay.module'
        ).then((mod) => mod.BurguerMenuOverlayModule),
      preload: false,
      isElement: true,
    },
    {
      name: 'feedback-overlay',
      load: () =>
        import(
          /* webpackChunkName: "feedback-overlay" */ '@app/overlays/feedback-overlay/feedback-overlay.module'
        ).then((mod) => mod.FeedbackOverlayModule),
      preload: false,
      isElement: true,
    },
    {
      name: 'phone-country-overlay',
      load: () =>
        import(
          /* webpackChunkName: "phone-country-overlay" */ '@app/overlays/phone-country-overlay/phone-country-overlay.module'
        ).then((mod) => mod.PhoneCountryOverlayModule),
      preload: false,
      isElement: true,
    },
    {
      name: 'phone-filters-overlay',
      load: () =>
        import(
          /* webpackChunkName: "phone-filters-overlay" */ '@app/overlays/phone-filters-overlay/phone-filters-overlay.module'
        ).then((mod) => mod.PhoneFiltersOverlayModule),
      preload: false,
      isElement: true,
    },
    {
      name: 'newsletter-overlay',
      load: () =>
        import(
          /* webpackChunkName: "newsletter-overlay" */ '@app/overlays/newsletter-overlay/newsletter-overlay.module'
        ).then((mod) => mod.NewsletterOverlayModule),
      preload: false,
      isElement: true,
    },
  ],
};
/* eslint-enable max-len */
