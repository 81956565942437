/* eslint-disable max-classes-per-file */

import { PriceModel } from '@app/shared/models/api/price.model';

export class BuybackOfferModel {
  public id: string;
  public pid: string;
  public model?: string | null;
  public brand?: string | null;
  public images?: Array<string> | null;
  public title?: string;
  public color: string;
  public colorCode: string;
  public storage: string;
  public storageUnit: string;
  public screenSize: string;
  public keyboard: string;
  public wifiCellular: string;
  public sim: string;
  public strapColor: string;
  public strapColorCode: string;
  public price?: PriceModel;
  public hasBuyBackOption?: boolean;

  constructor() {
    this.id = '';
    this.color = '';
    this.colorCode = '';
    this.storage = '';
    this.screenSize = '';
    this.storageUnit = '';
    this.keyboard = '';
    this.wifiCellular = '';
    this.model = '';
    this.strapColor = '';
    this.strapColorCode = '';
    this.sim = '';
    this.price = new PriceModel();
    this.hasBuyBackOption = false;
    this.pid = '';
  }
}

export const parseBuybackOfferModelFromJson = (
  data?: Record<string, any> | null
): BuybackOfferModel | null => {
  const variant = new BuybackOfferModel();
  variant.id = data?.id || '';
  variant.color = data?.color.name || '';
  variant.colorCode = data?.color.code || '';
  variant.storage = data?.storage || '';
  variant.storageUnit = data?.storage_unit || data?.storagUnit || 'GB';
  variant.screenSize = data?.screenSize || data?.screen_size || '';
  variant.keyboard = data?.keyboard || '';
  variant.wifiCellular = data?.wifiCellular || data?.wifi_cellular || '';
  variant.sim = data?.sim || '';
  variant.strapColor = data?.strapColor?.name || data?.strap_color?.name || '';
  variant.strapColorCode = data?.strapColorCode?.code || data?.strap_color?.code || '';
  variant.price =
    PriceModel.fromJson({ amount: data?.price, currency: data?.currency }) ||
    new PriceModel();

  variant.pid = data?.pid || '';
  variant.model = data?.model || '';
  variant.brand = data?.brand || '';
  variant.images = data?.images || [];
  variant.title = data?.title || '';
  variant.hasBuyBackOption = data?.hasBuyBackOption || false;

  return variant;
};

export const parseBuybackOfferModelFromJsonList = (
  data?: Array<any> | null
): Array<BuybackOfferModel> => {
  const list: Array<BuybackOfferModel> = new Array(0);
  if (data && data.length > 0) {
    for (const item of data) {
      const offer = parseBuybackOfferModelFromJson(item);
      if (offer) {
        list.push(offer);
      }
    }
  }

  return list;
};
