import { TabbyOrderItem } from './tabby-order-item.model';

export class TabbyOrder {
  public tax_amount?: string | null;
  public shipping_amount?: string | null;
  public discount_amount?: string | null;
  public updated_at?: string | null;
  public reference_id: string;
  public items: Array<TabbyOrderItem>;

  constructor() {
    this.tax_amount = null;
    this.shipping_amount = null;
    this.discount_amount = null;
    this.updated_at = null;
    this.reference_id = '';
    this.items = new Array(0);
  }
}

export const parseTabbyOrder = (data?: Record<string, any> | null): TabbyOrder | null => {
  let result: TabbyOrder | null = null;
  if (data) {
    result = new TabbyOrder();
    result.tax_amount = data.tax_amount || null;
    result.shipping_amount = data.shipping_amount || null;
    result.discount_amount = data.discount_amount || null;
    result.updated_at = data.updated_at || null;
    result.reference_id = data.reference_id || '';
    result.items = data.items || new Array(0);
  }
  return result;
};
