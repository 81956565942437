export class TabbyInstallmentInfo {
  public amount: string;
  public dueDate: string;

  constructor() {
    this.amount = '0';
    this.dueDate = '';
  }
}

export const parseTabbyInstallmentInfo = (
  data?: Record<string, any> | null
): TabbyInstallmentInfo | null => {
  let result: TabbyInstallmentInfo | null = null;
  if (data) {
    result = new TabbyInstallmentInfo();
    result.amount = data.amount || '0';
    result.dueDate = data.dueDate || '';
  }
  return result;
};

export const parseTabbyInstallmentInfoList = (
  data?: Array<Record<string, any>> | null
): Array<TabbyInstallmentInfo> => {
  const results = new Array(0);
  if ((data?.length || 0) > 0) {
    for (const item of data || []) {
      const parsed = parseTabbyInstallmentInfo(item);
      if (parsed) {
        results.push(parsed);
      }
    }
  }
  return results;
};
