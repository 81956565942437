import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
} from '@angular/core';
import { Params, RouterModule } from '@angular/router';
import { Subscription, Observable, Subject, map, takeUntil } from 'rxjs';
import { SentryUtil } from '@app/shared/utils/sentry.util';
import { WindowUtils } from '@app/shared/utils/window.util';

import { getTranslatedURI } from '@app/shared/utils/url.utils';
import { AkitaScreenQuery } from '@app/akita/screen/state/screen.query';
import { ScreenSizeInfoState, ScreenSizes } from '@app/akita/screen/state/screen.store';
import { TranslocoModule, TranslocoService } from '@ngneat/transloco';
import { ReportOverlayDynamicService } from '@app/overlays/report-overlay/services/report-overlay-dynamic.service';
import { AkitaRouterQuery } from '@app/akita/router/state/router.query';
import { SafeHtml, DomSanitizer } from '@angular/platform-browser';
import { AkitaAuthQuery } from '@app/akita/api/auth/state/auth.query';
import { UserModel } from '@app/shared/models/api/user.model';
import { HuaweiStoreBadgeIconComponent } from '../icons/generated/huawei-store-badge/huawei-store-badge.component';
import { HuaweiStoreArIconComponent } from '../icons/generated/huawei-store-ar/huawei-store-ar.component';
import { PlayStoreBadgeIconComponent } from '../icons/generated/play-store-badge/play-store-badge.component';
import { AppStoreBadgeIconComponent } from '../icons/generated/app-store-badge/app-store-badge.component';
import { KeyboardArrowUpIconComponent } from '../icons/generated/keyboard-arrow-up/keyboard-arrow-up.component';
import { InIconComponent } from '../icons/generated/in/in.component';
import { InstagramIconComponent } from '../icons/generated/instagram/instagram.component';
import { TwitterIconComponent } from '../icons/generated/twitter/twitter.component';
import { FacebookIconComponent } from '../icons/generated/facebook/facebook.component';
import { CommonModule } from '@angular/common';
import { StartingPriceModel } from '@app/shared/models/api/starting-price.model';
import { AkitaStartingPricesQuery } from '@app/akita/api/starting-prices/state/starting-prices.query';
import { PaymentMethodsLineComponent } from '../payment-methods-line/payment-methods-line.component';
import { NewsletterButtonComponent } from '../newsletter-button/newsletter-button.component';
import { SubscribeBoxComponent } from '../subscribe-box/subscribe-box.component';
import { PopsyLogoNewIconComponent } from '../icons/generated/popsy-logo-new/popsy-logo-new.component';
import { PopsyDuoIconComponent } from '../icons/generated/popsy-duo/popsy-duo.component';

interface LanguageOption {
  display: string;
  country: string;
  locale: string;
  rtl?: boolean;
}

// const US_ADDRESS = 'Popsy LLC 401 Broadway Floor 12 New York, NY 10013 USA';
/* const US_ADDRESS = `Popsy atividades de internet LTDA | CR No. 1010883274 | CNPJ: 36.930.174/0001-09
Av. Paulista, 1374, 11º Andar Bela Vista - São Paulo, SP 01310-100`; */
// eslint-disable-next-line max-len
const BR_ADDRESS = `Popsy atividades de internet LTDA | CNPJ: 36.930.174/0001-09 Av. Paulista, 1374, 11º Andar Bela Vista - São Paulo, SP 01310-100`;
const SA_ADDRESS_EN = '7917 Salman Al Udwani, Ar Rabi, 4462 Riyadh 13315, Saudi Arabia';
const SA_ADDRESS_AR = '7917 سلمان العدواني، الربيع، 4462, الرياض 13315';

const AE_ADDRESS_EN =
  'Business Center 1, M Floor, The Meydan Hotel, Nad Al Sheba, Dubai, U.A.E';

const COMPANY_NUMBER_SA = 'Popsy LLC | CR No. 1010883274';
const COMPANY_NUMBER_AE = 'Popsy LLC | License number: 2306097.01';

const CERTIFICATE_LINK =
  'https://firebasestorage.googleapis.com/v0/b/popsygae.appspot.com/o/CRCertificates%2FCRCertificate_';
const CERTIFICATE_LINK_1 = '.pdf?alt=media&token=bdf30f1f-a2d5-4fe7-a87f-43948407aa2a';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.template.html',
  styleUrls: ['./footer.style.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    RouterModule,
    TranslocoModule,
    NewsletterButtonComponent,
    SubscribeBoxComponent,
    PopsyLogoNewIconComponent,
    PopsyDuoIconComponent,
    FacebookIconComponent,
    TwitterIconComponent,
    InstagramIconComponent,
    InIconComponent,
    KeyboardArrowUpIconComponent,
    AppStoreBadgeIconComponent,
    PlayStoreBadgeIconComponent,
    HuaweiStoreArIconComponent,
    HuaweiStoreBadgeIconComponent,
    PaymentMethodsLineComponent,
  ],
})
export class FooterComponent implements OnDestroy {
  @Input() public showArrow?: boolean | null = null;
  public isBrowser: boolean;
  public rtlMode$: Observable<boolean>;

  public user$: Observable<UserModel | null>;

  public months: string;

  public currentLocation$: Observable<string>;
  public queryParamsToKeep$: Observable<Params>;
  public readonly inFullScreenMode$: Observable<boolean>;

  public country: string;
  public language: string;
  public displayAddress: string;
  public displayAddressBR: string;

  public currentYear = new Date().getFullYear();

  public companyNo: string;

  public biggerThan$: Observable<ScreenSizeInfoState>;
  public screenSize$: Observable<ScreenSizes>;

  public iPhones$: Observable<Array<StartingPriceModel>>;
  public tablets$: Observable<Array<StartingPriceModel>>;
  public watches$: Observable<Array<StartingPriceModel>>;
  public laptops$: Observable<Array<StartingPriceModel>>;

  // Report Overlay
  public reportOverlayOpened: boolean;
  private reportOverlayObserver?: Subscription | null;

  private readonly locationSubscriber?: Subscription | null;

  private readonly destroySubject: Subject<void>;

  constructor(
    private readonly sanitizer: DomSanitizer,
    private readonly changeDetector: ChangeDetectorRef,
    private readonly akitaScreenQuery: AkitaScreenQuery,
    private readonly reportOverlayService: ReportOverlayDynamicService,
    private readonly translateService: TranslocoService,
    private readonly akitaRouterQuery: AkitaRouterQuery,
    private readonly akitaAuthQuery: AkitaAuthQuery,
    private readonly akitaStartingPricesQuery: AkitaStartingPricesQuery
  ) {
    this.destroySubject = new Subject();
    this.reportOverlayOpened = false;
    this.isBrowser = this.akitaRouterQuery.isBrowser;
    this.biggerThan$ = this.akitaScreenQuery.selectBiggerThan();
    this.screenSize$ = this.akitaScreenQuery.selectSize();
    this.inFullScreenMode$ = this.akitaRouterQuery.selectInFullScreenMode();
    this.queryParamsToKeep$ = this.akitaRouterQuery.selectQueryParamsToKeep();
    this.rtlMode$ = this.akitaRouterQuery.selectIsRtlLayout();

    this.months = '12';

    this.displayAddress = SA_ADDRESS_EN;
    this.displayAddressBR = BR_ADDRESS;

    this.companyNo = COMPANY_NUMBER_SA;

    this.language = this.translateService.getActiveLang();

    this.user$ = this.akitaAuthQuery.selectUser();

    this.iPhones$ = this.akitaStartingPricesQuery.selectIPhones();
    this.tablets$ = this.akitaStartingPricesQuery.selectTablets();
    this.watches$ = this.akitaStartingPricesQuery.selectWatches();
    this.laptops$ = this.akitaStartingPricesQuery.selectLaptops('Apple');

    this.currentLocation$ = this.akitaRouterQuery.selectCountry().pipe(
      map((value) => {
        const country = value.toUpperCase();
        if (country !== 'AE') {
          this.companyNo = COMPANY_NUMBER_SA;
          if (this.language === 'ar') {
            this.displayAddress = SA_ADDRESS_AR;
          } else {
            this.displayAddress = SA_ADDRESS_EN;
          }
        } else {
          this.companyNo = COMPANY_NUMBER_AE;

          this.displayAddress = AE_ADDRESS_EN;
        }
        return value.toUpperCase();
      })
    );

    this.country = this.akitaRouterQuery.country.toUpperCase();
  }

  public get homeUrlFragment(): Array<string> {
    return this.akitaRouterQuery.homeUrlFragment;
  }

  public informationPageUrlFragment(path: string): Array<string> {
    const fragment = this.homeUrlFragment;
    const translatedPath = getTranslatedURI(
      path,
      this.translateService.getActiveLang() || 'en'
    );
    fragment.push(translatedPath);
    return fragment;
  }

  public deliverySearchUrlFragment(): Array<string> {
    const fragment = this.homeUrlFragment;
    fragment.push('order');
    return fragment;
  }

  public popsyAllBrandsFragment(): Array<string> {
    const fragment = this.akitaRouterQuery.homeUrlFragment;
    fragment.push('products');
    return fragment;
  }

  public popsySellerFragment(): Array<string> {
    const fragment = this.akitaRouterQuery.homeUrlFragment;
    fragment.push('seller');
    return fragment;
  }

  public popsyCustomerReviewsFragment(): Array<string> {
    const fragment = this.akitaRouterQuery.homeUrlFragment;
    fragment.push('customer-reviews');
    return fragment;
  }

  public popsyHowItWorks(): Array<string> {
    const fragment = this.akitaRouterQuery.homeUrlFragment;
    const translatedPath = getTranslatedURI(
      'about-us',
      this.translateService.getActiveLang() || 'en'
    );
    fragment.push(translatedPath);
    return fragment;
  }

  public urlFragment(
    option: any | null,
    urlValueKey: string,
    changeUrlFragment: boolean
  ): Array<string> {
    const fragment = ['/'];
    if (option && changeUrlFragment) {
      fragment.push(option[urlValueKey]);
    } else if (this.akitaRouterQuery.routeData?.locale) {
      fragment.push(this.akitaRouterQuery.routeData.locale);
    }

    if (this.akitaRouterQuery.routeData?.fragment) {
      for (const urlPath of this.akitaRouterQuery.routeData.fragment) {
        if (urlPath.slice(0, 1) === ':') {
          const key = urlPath.slice(1);
          if (this.akitaRouterQuery.params[key]) {
            fragment.push(this.akitaRouterQuery.params[key]);
          }
        } else {
          fragment.push(urlPath);
        }
      }
    }

    return fragment;
  }

  public urlQueryParams(
    option: any | null,
    urlParam: string,
    urlValueKey: string,
    changeUrlFragment: boolean
  ): Params {
    if (option && !changeUrlFragment) {
      return {
        ...this.akitaRouterQuery.queryParams,
        [urlParam]: option[urlValueKey],
      };
    } else {
      return this.akitaRouterQuery.queryParams;
    }
  }

  public getHrefLang(subdomain?: string): string {
    const params = new Array(0);
    for (const key of Object.keys(this.akitaRouterQuery.params || {})) {
      if (this.akitaRouterQuery.params[key]) {
        params.push(`${this.akitaRouterQuery.params[key]}`);
      }
    }

    const queryParams = new Array(0);
    for (const key of Object.keys(this.akitaRouterQuery.queryParams || {})) {
      queryParams.push(`${key}=${this.akitaRouterQuery.queryParams[key]}`);
    }

    return `https://${subdomain || 'www'}.popsy.app${
      params.length > 0 ? '/' : ''
    }${params.join('/')}/${queryParams.length > 0 ? '?' : ''}${queryParams.join('&')}`;
  }

  public openFeedbackForm(): boolean {
    if (this.reportOverlayObserver) {
      this.reportOverlayObserver.unsubscribe();
    }

    this.reportOverlayObserver = this.reportOverlayService.observe
      .pipe(takeUntil(this.destroySubject))
      .subscribe({
        next: (data?: any | null) => {
          this.reportOverlayOpened = Boolean(data);

          SentryUtil.addBreadcrumb({
            category: 'ui',
            message: `${this.reportOverlayOpened ? 'Opened' : 'Closed'} "Report" modal`,
            level: 'info',
            type: 'user',
          });

          this.changeDetector.markForCheck();
        },
      });

    this.reportOverlayService.open({
      elementRef: null,
      data: {
        mode: 'feedback',
        id: '-',
        title: this.translateService.translate('title_app_feedback'),
        link: `${WindowUtils.getLocationHref()}`,
        rtlMode: this.akitaRouterQuery.isRtlLanguage,
      },
      isBrowser: this.akitaRouterQuery.isBrowser,
    });

    return false;
  }

  public scrollToTop(): void {
    try {
      if (
        this.akitaRouterQuery.isBrowser &&
        window.document &&
        window.document.getElementById
      ) {
        const scrollElement = window.document.getElementById('mainAppScroll');
        if (scrollElement) {
          if (scrollElement.scrollTo) {
            scrollElement.scrollTo({
              top: 0,
              left: 0,
              behavior: 'smooth',
            });
          } else {
            scrollElement.scrollTop = 0;
          }
        }
      }
    } catch (err) {
      /* SSR Not Supported */
    }
  }

  public get contactUsText(): SafeHtml {
    let contactUsStr = this.translateService.translate('contact_us_at');
    contactUsStr = contactUsStr.replace(
      'support@popsy.app',
      '<!--email_off--><a class="popsy-link gray" href="mailto:support@popsy.app">support@popsy.app</a><!--/email_off-->'
    );
    return this.sanitizer.bypassSecurityTrustHtml(`${contactUsStr}`);
  }

  public get certificateLink(): string {
    return `${CERTIFICATE_LINK}${this.language}${CERTIFICATE_LINK_1}`;
  }

  public trackByLanguage(index: number, option?: LanguageOption | null): string {
    return option?.locale || `${index}`;
  }

  public productFragment(id: number | null): Array<string> {
    return this.akitaRouterQuery.getProductUrlFragment(`${id}`);
  }

  public trackByModel(index: number, category?: any | null): string {
    return (category as any)?.id || category || `${index}`;
  }

  public ngOnDestroy(): void {
    this.destroySubject.next();
    this.destroySubject.complete();

    if (this.locationSubscriber) {
      this.locationSubscriber.unsubscribe();
    }

    if (this.reportOverlayObserver) {
      this.reportOverlayObserver.unsubscribe();
    }
  }
}
