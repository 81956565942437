<main
  class="buyback-home-component"
  [class.desktop]="!(lessThan$ | async)?.sm"
  [class.single-header]="!(lessThan$ | async)?.mobileXL"
  *transloco="let t">
  <div class="wrapper scroll" id="mainAppScroll">
    <!--Products Link Bar -->
    @if (biggerThan()?.mobileL) {
      <app-navigation-bar />
      <span class="fixed-space-8" aria-hidden="true"></span>
    }
    <div class="content">
      <div class="header">
        @if (lang !== 'AR') {
          <h1>Trade-in your old tech</h1>
          <span>The simplest and safest way to sell your old device online</span>
        } @else {
          <h1>حوّل أجهزتك القديمة إلى نقود</h1>
          <span>الطريقة الأسهل والأكثر أمانًا لبيع جهازك القديم عبر الإنترنت</span>
        }
        <span class="fixed-space-16" aria-hidden="true"></span>

        @if (
          isSupportedCountry() === true &&
          enabledBuybackFeature() === true &&
          buybackProductOffers()?.length
        ) {
          <button class="blue">
            <a
              class="model-item"
              [routerLink]="getBuybackSellFragment()"
              [queryParams]="{}"
              >{{ t('action_trade_in') }}
            </a>
          </button>
        }
      </div>

      <app-buyback-highlights [centerAlign]="true" />

      <section class="content-wrapper">
        @if (lang !== 'AR') {
          <h1>Choose the device you want to sell</h1>
        } @else {
          <h1>اختر الجهاز الذي تريد بيعه</h1>
        }

        <div class="grid">
          @if (enabledBuybackFeature() === true) {
            @for (item of buybackProductOffers(); track item; let i = $index) {
              <a
                class="model-item"
                [routerLink]="getBuybackSellFragment()"
                [queryParams]="{}">
                <div class="image">
                  <img [src]="item?.images?.[0]" />
                </div>
                <div class="content">
                  @if (lang !== 'AR') {
                    <h3>Sell your Smartphone</h3>
                    <p>Get up to {{ item.price | amount }}</p>
                  } @else {
                    <h3>بيع هاتفك الذكي</h3>
                    <p>احصل على ما يصل إلى {{ item.price | amount }}</p>
                  }
                </div>
              </a>
            }
          }
          <a class="model-item not-allowed">
            <div class="image">
              <img src="assets/iphones/macBook.png" />
            </div>
            <div class="content">
              @if (
                isSupportedCountry() === true &&
                enabledBuybackFeature() === true &&
                buybackProductOffers()?.length
              ) {
                @if (lang !== 'AR') {
                  <h3>Sell your Laptop</h3>
                } @else {
                  <h3>بِع حاسوبك المحمول</h3>
                }
              } @else {
                @if (lang !== 'AR') {
                  <h3>Sell your Device</h3>
                } @else {
                  <h3>بِع جهازك</h3>
                }
              }

              <p>{{ t('label_coming_soon') }}</p>
            </div>
          </a>
        </div>

        <span class="fixed-space-64" aria-hidden="true"></span>

        <div class="faq">
          @if (lang !== 'AR') {
            <h1>Trade-In FAQ</h1>
          } @else {
            <h1>الأسئلة الشائعة حول المقايضة</h1>
          }

          @for (item of frequentFAQData; track item; let i = $index) {
            @if (
              (lang !== 'AR' && item.question.length) ||
              (lang === 'AR' && item.questionAr.length)
            ) {
              <div class="item">
                <div class="question" (click)="item.open = !item.open">
                  @if (lang !== 'AR') {
                    <span>{{ item.question }}</span>
                  }
                  @if (lang === 'AR') {
                    <span>{{ item.questionAr }}</span>
                  }
                  <span class="fixed-space-8" aria-hidden="true"></span>
                  <popsy-icon-chevron-down
                    class="icon icon fill-gray-01"
                    [class.selected]="item.open" />
                </div>
                @if (lang !== 'AR') {
                  <div
                    class="answer"
                    [class.selected]="item.open"
                    [innerHTML]="item.answer"></div>
                }
                @if (lang === 'AR') {
                  <div
                    class="answer"
                    [class.selected]="item.open"
                    [innerHTML]="item.answerAr"></div>
                }
                @if (i === 1 && item.open) {}
                <span class="line fill-row"></span>
              </div>
            }
          }
        </div>
      </section>

      @if ((lessThan$ | async)?.mobileL !== true) {
        <span class="fixed-space-64" aria-hidden="true"></span>
      }
      @if (!(inFullScreenMode$ | async)) {
        <app-footer
          class="footer"
          [class.phone]="(lessThan$ | async)?.mobileL"></app-footer>
      }
    </div>
  </div>
</main>
