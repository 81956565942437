/* eslint-disable @typescript-eslint/no-magic-numbers */
import { Injectable, NgZone } from '@angular/core';
import { applyTransaction, logAction } from '@datorama/akita';
import { AkitaBuybackStore } from './buyback.store';
import { BuybackApiService } from '../services/buyback-api.service';
import { catchError, Observable, of, Subscription, tap, throwError } from 'rxjs';
import { parseApiError } from '@app/shared/models/api/api-error.model';
import {
  BuybackSearchFilters,
  generateBuybackFiltersID,
} from '../models/buyback-search.filters.model';
import { AkitaProductsQuery } from '../../products/state/products.query';
import { BuyBackStepOption } from '../models/buyback.state';
import { AkitaBuybackQuery } from './buyback.query';
import { BuybackOrderModel } from '../models/buyback-order.model';
import { TranslocoService } from '@ngneat/transloco';

@Injectable({ providedIn: 'root' })
export class AkitaBuybackService {
  constructor(
    private readonly zone: NgZone,
    private readonly store: AkitaBuybackStore,
    private readonly query: AkitaBuybackQuery,
    private readonly buybackApiService: BuybackApiService,
    private readonly akitaProductsQuery: AkitaProductsQuery,
    private readonly transloco: TranslocoService
  ) {}

  public reset(): void {
    this.store.reset();
    this.zone.run(() => {
      applyTransaction(() => {
        logAction('resetCheckoutState() Called');
        this.store.setErrorFetchingProducts(null);
        this.store.toggleFetchingProducts(true);
      });
    });
  }

  public searchBuybackProductsAsync(filters: BuybackSearchFilters): Subscription {
    return this.searchBuybackProducts(filters).subscribe({
      next: () => {},
      error: () => {},
    });
  }

  public searchBuybackProducts(filters: BuybackSearchFilters): Observable<any> {
    if (!filters.country) {
      filters = {
        ...filters,
        country: this.akitaProductsQuery.country,
      };
    }

    const path = generateBuybackFiltersID(filters);

    if (this.query.products && this.query.products[path]) {
      return of(this.query.products[path]);
    }

    this.zone.run(() => {
      applyTransaction(() => {
        logAction('searchBuybackProducts() Called');
        this.store.toggleFetchingProducts(true);
      });
    });

    return this.buybackApiService.searchBuybackProducts(filters).pipe(
      catchError((error: unknown) => {
        const parsed = parseApiError(error);
        this.zone.run(() => {
          applyTransaction(() => {
            logAction('search() - error');
            this.store.toggleFetchingProducts(false);
            this.store.setErrorFetchingProducts(parsed);
          });
        });
        return throwError(() => parsed);
      }),
      tap((results: any) => {
        this.zone.run(() => {
          applyTransaction(() => {
            logAction('search() - done');
            this.store.toggleFetchingProducts(false);
            this.store.setProducts(filters?.country || 'SA', path || '-', results);
          });
        });
      })
    );
  }

  public setUserDetails(userDetails: any): void {
    this.zone.run(() => {
      applyTransaction(() => {
        logAction('setUserDetails() Called');
        this.store.setUserDetails(userDetails);
      });
    });
  }

  public setIban(iban: string | null): void {
    this.zone.run(() => {
      applyTransaction(() => {
        logAction('setIban() Called');
        this.store.setIban(iban);
      });
    });
  }

  public setSelectedOffer(offer: any): void {
    this.zone.run(() => {
      applyTransaction(() => {
        logAction('setSelectedOffer() Called');
        this.store.setSelectedOffer(offer);
      });
    });
  }

  public setStep(step: string): void {
    this.zone.run(() => {
      applyTransaction(() => {
        logAction('setStep() Called');
        this.store.setStep(step);
      });
    });
  }

  public setStepIndex(index: number): void {
    this.zone.run(() => {
      applyTransaction(() => {
        logAction('setStepIndex() Called');
        this.store.setStepIndex(index);
      });
    });
  }

  public setIsSubmitting(isSubmitting: boolean): void {
    this.zone.run(() => {
      applyTransaction(() => {
        logAction('setSubmitting() Called');
        this.store.setIsSubmitting(isSubmitting);
      });
    });
  }

  public initializeFlowSteps(steps: Array<BuyBackStepOption>, category: string): void {
    if (!steps || !steps.length) {
      return;
    }
    const newValues = JSON.parse(JSON.stringify(steps)) as BuyBackStepOption[];
    const newSteps = [] as BuyBackStepOption[];
    // initialize the flow steps with the translated values
    newSteps.push({ device: this.transloco.translate('trade_step_device') });
    newSteps.push({
      screenCondition: this.transloco.translate('trade_step_screen_condition'),
    });
    newSteps.push({
      deviceCondition: this.transloco.translate('trade_step_device_condition'),
    });
    newSteps.push({
      functionality: this.transloco.translate('trade_step_functionality'),
    });
    // initialize the form values with empty strings
    newValues.push({ screenCondition: '' });
    newValues.push({ deviceCondition: '' });
    newValues.push({ functionality: 'yes' });
    this.zone.run(() => {
      applyTransaction(() => {
        logAction('setFlowSteps() Called');
        this.store.setIsSubmitting(false);
        this.store.setCategory(category);
        this.store.setStepIndex(0);
        this.store.setStep(Object.keys(newSteps[0])[0]);
        this.store.setFlowSteps(newSteps);
        this.store.setFormValues(newValues);
      });
    });
  }

  public setCurrentFlowStepOption(option: string): void {
    const newSteps = this.query.getValue().form.flowSteps;
    newSteps[this.query.getValue().form.stepIndex] = {
      [`${this.query.getValue().form.step}`]: option,
    };

    this.zone.run(() => {
      applyTransaction(() => {
        logAction('setCurrentStepOption() Called');
        this.store.setFlowSteps(newSteps);
      });
    });
  }

  public setFormValue(step: string, option: string): void {
    // copy of the current flow steps

    const newValues = JSON.parse(
      JSON.stringify(this.query.getValue().form.formValues)
    ) as BuyBackStepOption[];

    newValues.forEach((stepOption: BuyBackStepOption) => {
      if (Object.keys(stepOption)[0] === step) {
        stepOption[step] = option;
      }
    });

    // reset the storage value if the step is model or brand
    if (step === 'model' || step === 'brand') {
      newValues.find((stepOption: BuyBackStepOption) => {
        if (Object.keys(stepOption)[0] === 'storage') {
          stepOption['storage'] = '';
        }
      });
    }

    if (step === 'model') {
      this.zone.run(() => {
        applyTransaction(() => {
          logAction('setStepModel() Called');
          this.store.setModel(option as string);
        });
      });
    }

    this.zone.run(() => {
      applyTransaction(() => {
        logAction('setStepOption() Called');
        this.store.setFormValues(newValues);
      });
    });

    // refetch the products according to the new filters
    if (step === 'category' || step === 'brand' || step === 'model') {
      const filters = {
        ...new BuybackSearchFilters(),
        country: this.akitaProductsQuery.country,
        category: [this.query.getValue().form.category as string],
      };

      if (this.query.getFormValue('brand')) {
        filters.brand = [this.query.getFormValue('brand') as string];
      }
      if (this.query.getFormValue('model')) {
        filters.model = [this.query.getFormValue('model') as string];
      }

      this.searchBuybackProductsAsync(filters);
    }
  }

  public goToNextStep(): void {
    const stepIndex = this.query.getValue().form.stepIndex;
    const flowSteps = this.query.getValue().form.flowSteps;
    const nextStepIndex = stepIndex + 1;
    if (stepIndex < flowSteps.length - 1) {
      this.zone.run(() => {
        applyTransaction(() => {
          logAction('goToNextStep() Called');
          this.store.setStepIndex(nextStepIndex);
          this.store.setStep(Object.keys(flowSteps[nextStepIndex])[0]);
        });
      });
    }
  }

  public goToPreviousStep(): void {
    const stepIndex = this.query.getValue().form.stepIndex;
    if (stepIndex > 0) {
      this.zone.run(() => {
        applyTransaction(() => {
          logAction('goToPreviousStep() Called');
          this.store.setStepIndex(stepIndex - 1);
          this.store.setStep(
            Object.keys(this.query.getValue().form.flowSteps[stepIndex - 1])[0]
          );
        });
      });
    }
  }

  public createBuybackOrder(): Observable<BuybackOrderModel> {
    this.zone.run(() => {
      applyTransaction(() => {
        logAction('createBuybackOrder() Called');
        this.store.setIsSubmitting(true);
      });
    });

    const info = this.query.generateBuybackOrderRequest();

    return this.buybackApiService.createBuybackOrder(info).pipe(
      catchError((error: unknown) => {
        const parsed = parseApiError(error);
        this.zone.run(() => {
          applyTransaction(() => {
            logAction('createBuybackOrder() - error');
            this.store.setIsSubmitting(false);
            this.store.setErrorSubmittingOffer(parsed);
          });
        });
        return throwError(() => parsed);
      }),
      tap(() => {
        this.zone.run(() => {
          applyTransaction(() => {
            logAction('createBuybackOrder() - done');
            this.store.setIsSubmitting(false);
          });
        });
      })
    );
  }

  public fetchBuybackOrder(orderId: string): Observable<BuybackOrderModel | null> {
    this.zone.run(() => {
      applyTransaction(() => {
        logAction('fetchOrder() Called');
      });
    });

    return this.buybackApiService.fetchBuybackOrder(orderId).pipe(
      catchError((error: unknown) => {
        const parsed = parseApiError(error);
        this.zone.run(() => {
          applyTransaction(() => {
            logAction('fetchOrder() - error');
          });
        });
        return throwError(() => parsed);
      }),
      tap((order: BuybackOrderModel) => {
        this.zone.run(() => {
          applyTransaction(() => {
            logAction('fetchOrder() - done');
            this.store.setOrder(order);
          });
        });
      })
    );
  }

  public updateTrackingInfo(
    orderId: string,
    trackingUrl: string,
    trackingAWB: string
  ): Observable<any> {
    this.zone.run(() => {
      applyTransaction(() => {
        logAction('updateTrackingInfo() Called');
        this.store.setIsSubmitting(true);
      });
    });

    return this.buybackApiService
      .updateTrackingInfo(orderId, trackingUrl, trackingAWB)
      .pipe(
        catchError((error: unknown) => {
          const parsed = parseApiError(error);
          this.zone.run(() => {
            applyTransaction(() => {
              logAction('updateTrackingInfo() - error');
              this.store.setIsSubmitting(false);
              this.store.setErrorSubmittingOffer(parsed);
            });
          });
          return throwError(() => parsed);
        }),
        tap((order: BuybackOrderModel) => {
          this.zone.run(() => {
            applyTransaction(() => {
              logAction('updateTrackingInfo() - done');
              this.store.setIsSubmitting(false);
              this.store.setOrder(order);
            });
          });
        })
      );
  }

  public fetchUserBuybackOrdersAsync(): Subscription {
    return this.fetchUserBuybackOrders().subscribe({
      next: () => {},
      error: () => {},
    });
  }

  public fetchUserBuybackOrders(): Observable<Array<BuybackOrderModel> | null> {
    this.zone.run(() => {
      applyTransaction(() => {
        logAction('fetchUserOrders() Called');
      });
    });

    return this.buybackApiService.fetchUserBuybackOrders().pipe(
      catchError((error: unknown) => {
        const parsed = parseApiError(error);
        this.zone.run(() => {
          applyTransaction(() => {
            logAction('fetchUserOrders() - error');
          });
        });
        return throwError(() => parsed);
      }),
      tap((orders: Array<BuybackOrderModel>) => {
        this.zone.run(() => {
          applyTransaction(() => {
            logAction('fetchUserOrders() - done');
            this.store.setUserOrders(orders);
          });
        });
      })
    );
  }
}
