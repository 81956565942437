import { inject, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import {
  BUYBACK_SEARCH_FILTERS_MULTIPLE_ALLOWED,
  BuybackSearchFilters,
  normalizeFilterValue,
} from '../models/buyback-search.filters.model';
import { AkitaAuthQuery } from '../../auth/state/auth.query';
import { catchError, map, Observable, throwError } from 'rxjs';
import { parseApiError } from '@app/shared/models/api/api-error.model';
import { getApiEndpoint } from '@app/shared/utils/url.utils';
import { parseBuybackProductsFromJSON } from '../models/buyback.state';
import { BuybackOrderRequestInfo } from '../models/buyback-order-request-info.model';
import { BuybackOrderModel } from '../models/buyback-order.model';

@Injectable({ providedIn: 'root' })
export class BuybackApiService {
  private readonly http = inject(HttpClient);

  constructor(private readonly akitaAuthQuery: AkitaAuthQuery) {}

  public searchBuybackProducts(filters: BuybackSearchFilters): Observable<any> {
    const accessToken = this.akitaAuthQuery.accessToken;
    let headers = {};
    if (accessToken) {
      headers = {
        Authorization: `Bearer ${accessToken}`,
      };
    }

    let queryParams = new HttpParams();
    if (filters) {
      for (const key of Object.keys(filters || {})) {
        const value = (filters as Record<string, unknown>)[key];
        if (value) {
          if (BUYBACK_SEARCH_FILTERS_MULTIPLE_ALLOWED.includes(`${key || ''}`)) {
            for (const item of [...((value as Array<string>) || [])]) {
              queryParams = queryParams.append(`${key}`, normalizeFilterValue(item));
            }
          } else {
            queryParams = queryParams.set(`${key}`, normalizeFilterValue(value));
          }
        }
      }
    }

    // remove category field from query params for now
    queryParams = queryParams.delete('category');

    return this.http
      .get<any>(getApiEndpoint(`api/v2/buyback/product`, true), {
        responseType: 'json',
        headers,
        params: queryParams,
      })
      .pipe(
        catchError((error: unknown) => throwError(() => parseApiError(error))),
        map((results: any) => parseBuybackProductsFromJSON(results))
      );
  }

  public createBuybackOrder(info: BuybackOrderRequestInfo): Observable<any> {
    // eslint-disable-next-line deprecation/deprecation
    const credentials = btoa(`api:GKbwgZ9rJBf8oggS`);
    const headers = new HttpHeaders({
      'Content-Type': 'application/json; charset=utf-8',
      Authorization: `Basic ${credentials}`,
    });

    return this.http
      .post<any>(getApiEndpoint(`api/v1/order/buyback`, true), info, {
        responseType: 'json',
        headers,
      })
      .pipe(
        catchError((error: unknown) => throwError(() => parseApiError(error))),
        map((results: any) => BuybackOrderModel.fromJson(results))
      );
  }

  public fetchBuybackOrder(orderId: string): Observable<any> {
    // eslint-disable-next-line deprecation/deprecation
    const credentials = btoa(`api:GKbwgZ9rJBf8oggS`);
    const headers = new HttpHeaders({
      'Content-Type': 'application/json; charset=utf-8',
      Authorization: `Basic ${credentials}`,
    });

    return this.http
      .get<any>(getApiEndpoint(`api/v1/order/buyback/${orderId}`, true), {
        responseType: 'json',
        headers,
      })
      .pipe(
        catchError((error: unknown) => throwError(() => parseApiError(error))),
        map((results: any) => BuybackOrderModel.fromJson(results))
      );
  }

  public updateTrackingInfo(
    orderId: string,
    trackingUrl: string,
    trackingAWB: string
  ): Observable<any> {
    // eslint-disable-next-line deprecation/deprecation
    const credentials = btoa(`api:GKbwgZ9rJBf8oggS`);
    const headers = new HttpHeaders({
      'Content-Type': 'application/json; charset=utf-8',
      Authorization: `Basic ${credentials}`,
    });

    return this.http
      .put<any>(
        getApiEndpoint(`api/v1/order/buyback/${orderId}/tracking`, true),
        {
          tracking_url: trackingUrl,
          tracking_awb: trackingAWB,
        },
        {
          responseType: 'json',
          headers,
        }
      )
      .pipe(
        catchError((error: unknown) => throwError(() => parseApiError(error))),
        map((results: any) => BuybackOrderModel.fromJson(results))
      );
  }

  public fetchUserBuybackOrders(): Observable<Array<BuybackOrderModel>> {
    const apiUrl: string = getApiEndpoint(`api/v1/order/buyback/me`, true);

    // eslint-disable-next-line deprecation/deprecation
    const accessToken = this.akitaAuthQuery.accessToken;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json; charset=utf-8',
      Authorization: `Bearer ${accessToken}`,
    });

    return this.http
      .get<any>(apiUrl, {
        responseType: 'json',
        headers,
      })
      .pipe(
        catchError((error: unknown) => throwError(() => parseApiError(error))),
        map((data: any) => data.map((order: any) => BuybackOrderModel.fromJson(order)))
      );
  }
}
