import { PriceModel } from '@app/shared/models/api/price.model';
import { Model } from '@app/shared/models/model.interface';
import { parseProduct, Product } from '../../products/models/product.model';

/**
 * The Buyback Order Model
 * - This model is used to represent the Buyback Order
 * - It is used to store the Buyback Order Information
 */
/* WAITING_TO_SHIP,
SHIPPED,
DELIVERED,
PAID,
NOT_SHIPPED,
RETURNED,
RETURN_COMPLETED */

export class BuybackOrderModel implements Model {
  public id: string;
  public customerId: string;
  public email: string;
  public name: string;
  public phone: string;
  public iban: string;
  public price: PriceModel | null;
  public productId: string;
  public state: string;
  public product: Product;

  public trackingUrl: string | null;
  public trackingAWB: string | null;

  public createdAt: Date;
  public modifiedAt: Date;

  /**
   * Constructor to initialize the properties
   * - Make sure the properties are initialized (to avoid unexpected undefined values)
   * @constructor
   */
  constructor() {
    this.id = '';
    this.customerId = '';
    this.email = '';
    this.name = '';
    this.phone = '';
    this.iban = '';
    this.price = new PriceModel();
    this.productId = '';
    this.state = '';
    this.trackingUrl = null;
    this.trackingAWB = null;
    this.product = new Product();

    this.createdAt = new Date();
    this.modifiedAt = new Date();
  }

  /**
   * Helper function to sanitize the data that comes from the Backend.
   * @param data The Raw JSON Object
   * @returns {PaymentConfigurationModel} An instance of the payment configuration class.
   */
  public static fromJson(data?: Record<string, any> | null): BuybackOrderModel | null {
    let payment: BuybackOrderModel | null = null;
    if (data) {
      payment = new BuybackOrderModel();
      payment.id = data.id || '';
      payment.customerId = data.customerId || data.customer_id || '';
      payment.email = data.email || '';
      payment.name = data.name || '';
      payment.phone = data.phone || '';
      payment.iban = data.iban || '';
      payment.price = PriceModel.fromJson({
        amount: data.price || 0,
        currency: data.currency || '',
      });
      payment.productId = data.productId || '';
      payment.state = data.state || '';

      payment.product = parseProduct(data.shortProduct) || new Product();
      if (payment.product && payment.price) {
        payment.product.price = payment?.price || null;
      }

      payment.trackingUrl = data.trackingUrl || null;
      payment.trackingAWB = data.trackingAWB || null;

      payment.createdAt = new Date(data.createdAt || '');
      payment.modifiedAt = new Date(data.modifiedAt || '');
    }
    return payment;
  }
}
