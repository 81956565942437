import { TabbyOnChangeResponse } from './tabby-on-change-response.model';
import { TabbyPayment } from './tabby-payment.model';

export class TabbyMerchantUrls {
  public success: string;
  public cancel: string;
  public failure: string;

  constructor() {
    this.success = '';
    this.cancel = '';
    this.failure = '';
  }
}

export class TabbyConfiguration {
  public merchantCode?: string | null;
  public sessionId?: string | null;
  public merchantUrls?: TabbyMerchantUrls | null;
  public nodeId: string;
  public apiKey: string;
  public lang: string;
  public payment: TabbyPayment;
  public onChange: (data: TabbyOnChangeResponse) => void;
  public onClose: (data: unknown) => void;

  constructor() {
    this.merchantCode = null;
    this.sessionId = null;
    this.merchantUrls = null;
    this.nodeId = '';
    this.apiKey = '';
    this.lang = '';
    this.payment = new TabbyPayment();
    this.onChange = (): void => {};
    this.onClose = (): void => {};
  }
}

export const parseTabbyConfiguration = (
  data?: Record<string, any> | null
): TabbyConfiguration | null => {
  let result: TabbyConfiguration | null = null;
  if (data) {
    result = new TabbyConfiguration();
    result.merchantCode = data.merchantCode || null;
    result.sessionId = data.sessionId || null;
    result.merchantUrls = data.merchantUrls || null;
    result.nodeId = data.nodeId || '';
    result.apiKey = data.apiKey || '';
    result.lang = data.lang || '';
    result.payment = data.payment || new TabbyPayment();
    result.onChange = data.onChange || function () {};
    result.onClose = data.onClose || function () {};
  }
  return result;
};

export const parseTabbyMerchantUrls = (
  data?: Record<string, any> | null
): TabbyMerchantUrls | null => {
  let result: TabbyMerchantUrls | null = null;
  if (data) {
    result = new TabbyMerchantUrls();
    result.success = data.success || '';
    result.cancel = data.cancel || '';
    result.failure = data.failure || '';
  }
  return result;
};
