import { TabbyAttachment } from './tabby-attachment.model';
import { TabbyBuyerHistory } from './tabby-buyer-history.model';
import { TabbyBuyer } from './tabby-buyer.model';
import { TabbyOrderHistory } from './tabby-order-history.model';
import { TabbyOrder } from './tabby-order.model';
import { TabbyPaymentCapture } from './tabby-payment-capture.model';
import { TabbyRefund } from './tabby-refund.model';
import { TabbyShippingAddress } from './tabby-shipping-address.model';

export class TabbyPayment {
  public id?: string | null;
  public created_at?: string | null;
  public expires_at?: string | null;
  public amount: string;
  public currency?: string | null;
  public description?: string | null;
  public status?: string | null;
  public is_expired?: boolean | null;
  public test?: boolean | null;
  public buyer: TabbyBuyer;
  public buyer_history: TabbyBuyerHistory;
  public order: TabbyOrder;
  public order_history: Array<TabbyOrderHistory>;
  public shipping_address?: TabbyShippingAddress | null;
  public captures?: Array<TabbyPaymentCapture> | null;
  public refunds?: Array<TabbyRefund> | null;
  public meta?: {
    [property: string]: string;
  } | null;
  public attachment?: TabbyAttachment | null;

  constructor() {
    this.id = null;
    this.created_at = null;
    this.expires_at = null;
    this.amount = '';
    this.currency = null;
    this.description = null;
    this.status = null;
    this.is_expired = null;
    this.test = null;
    this.buyer = new TabbyBuyer();
    this.buyer_history = new TabbyBuyerHistory();
    this.order = new TabbyOrder();
    this.order_history = new Array(0);
    this.shipping_address = null;
    this.captures = null;
    this.refunds = null;
    this.meta = null;
    this.attachment = null;
  }
}

export const parseTabbyPayment = (
  data?: Record<string, any> | null
): TabbyPayment | null => {
  let result: TabbyPayment | null = null;
  if (data) {
    result = new TabbyPayment();
    result.id = data.id || null;
    result.created_at = data.created_at || null;
    result.expires_at = data.expires_at || null;
    result.amount = data.amount || '';
    result.currency = data.currency || null;
    result.description = data.description || null;
    result.status = data.status || null;
    result.is_expired = data.is_expired || null;
    result.test = Boolean(data.test);
    result.buyer = data.buyer || new TabbyBuyer();
    result.buyer_history = data.buyer_history || new TabbyBuyerHistory();
    result.order = data.order || new TabbyOrder();
    result.order_history = data.order_history || new Array(0);
    result.shipping_address = data.shipping_address || null;
    result.captures = data.captures || null;
    result.refunds = data.refunds || null;
    result.meta = data.meta || null;
    result.attachment = data.attachment || null;
  }
  return result;
};
