<ng-container *transloco="let t">
  @if (isBaseetaSupported) {
    <a
      class="quara-product-widget"
      [ngClass]="source"
      [class.small]="iconSize === 'small'"
      [class.compact]="mode === 'compact'"
      [class.desk]="(lessThan$ | async)?.sm"
      [class.mobile]="(lessThan$ | async)?.mobileL"
      (click)="openQuaraOverlay()">
      <div class="flex flex-col flex-wrap align-base">
        <div class="flex flex-wrap">
          <span class="small">{{ t('label_pay_as_low') }}</span>
          <span class="fixed-space-4" aria-hidden="true"></span>
          <span class="small bold"> {{ splitAmount | amount }}</span>
        </div>
        <span class="small full-width">
          {{ t('label_n_installments', { var1: maxInstallments }) }}
        </span>
      </div>
      <popsy-baseeta-badge [size]="'default'" />
    </a>
  }
</ng-container>
