import { Injectable } from '@angular/core';
import { AkitaRouterQuery } from '@app/akita/router/state/router.query';
import { Observable, of, tap } from 'rxjs';
import { TamaraInstance } from '../models/tamara-instance.model';
import { TamaraProductWidgetOptions } from '../models/tamara-product-widget-options.model';
import { TamaraInstallmentsScriptTag } from './tamara-installments-script-tag.service';
import { TamaraPayInXScriptTag } from './tamara-pay-in-x-script-tag.service';
import { TamaraScriptTag } from './tamara-script-tag.service';
import { TamaraSummaryScriptTag } from './tamara-sumary-script-tag.service';

export const TAMARA_SUPPORTED_COUNTRIES = ['SA', 'AE', 'KW'];
export const TAMARA_SUPPORTED_CURRENCIES = ['SAR', 'AED', 'KWD'];
/* export const TAMARA_MAX_ALLOWED_AMOUNT = 1400; */

const TAMARA_SUMARY_CSS_EN = `
.tamara-summary-widget__container {
  font-family: Nunito, sans-serif !important;
  font-size: 14px !important;
  border: 0 !important;
  position: relative;
  padding-right: 100px !important;
  }
.tamara-summary-widget__container .tamara-badge {  
  position: absolute;
  top: 0;
  right: 0;
  margin: 16px;
}
  `;

const TAMARA_SUMARY_CSS_AR = `
.tamara-summary-widget__container {
  font-family: Cairo, Nunito, sans-serif !important;
  font-size: 14px !important;
  border: 0 !important;
  position: relative;
  padding-left: 100px !important;
  }
.tamara-summary-widget__container .tamara-badge {  
  position: absolute;
  top: 0;
  left: 0;
  margin: 16px;
}
  `;

@Injectable({ providedIn: 'root' })
export class TamaraActionsService {
  constructor(
    private readonly tamaraScriptTag: TamaraScriptTag,
    private readonly tamaraSummaryScriptTag: TamaraSummaryScriptTag,
    private readonly tamaraInstallmentsScriptTag: TamaraInstallmentsScriptTag,
    private readonly tamaraPayInXScriptTag: TamaraPayInXScriptTag,
    private readonly akitaRouterQuery: AkitaRouterQuery
  ) {}

  public displayTamaraPromo(
    options?: TamaraProductWidgetOptions | null
  ): Observable<TamaraInstance | null> {
    if (this.akitaRouterQuery.isBrowser) {
      return this.tamaraScriptTag.promiseInstance().pipe(
        tap(() => {
          try {
            if (options) {
              (window as any).TamaraProductWidget.init({
                ...options,
                publicKey: undefined, // TODO: Check with API Team why adding a Public Key returns 404 when rendering banners
              });
            }

            (window as any).TamaraProductWidget.render();
          } catch (err) {}
        })
      );
    } else {
      return of(null);
    }
  }

  public displayTamaraSummary(
    options?: TamaraProductWidgetOptions | null
  ): Observable<TamaraInstance | null> {
    try {
      if (options) {
        (window as any).tamaraWidgetConfig = {
          country: options.countryCode,
          lang: options.lang,
          publicKey: options.publicKey,
          style: {
            // Optional to define CSS variable,
            fontSize: '14px',
            badgeRatio: options.badgeRatio || 1,
          },
          css: options.lang === 'ar' ? TAMARA_SUMARY_CSS_AR : TAMARA_SUMARY_CSS_EN,
        };

        (window as any).TamaraWidgetV2.refresh();
      }
    } catch (err) {}
    if (this.akitaRouterQuery.isBrowser) {
      return this.tamaraSummaryScriptTag.promiseInstance().pipe(tap(() => {}));
    } else {
      return of(null);
    }
  }

  public displayTamaraInstallmentsWidget(
    options?: TamaraProductWidgetOptions | null
  ): Observable<TamaraInstance | null> {
    if (this.akitaRouterQuery.isBrowser) {
      return this.tamaraInstallmentsScriptTag.promiseInstance().pipe(
        tap(() => {
          try {
            if (options) {
              (window as any).TamaraInstallmentPlan.init({
                ...options,
                publicKey: undefined, // TODO: Check with API Team why adding a Public Key returns 404 when rendering banners
              });
            }

            (window as any).TamaraInstallmentPlan.render();
          } catch (err) {}
        })
      );
    } else {
      return of(null);
    }
  }

  public displayTamaraPayInXWidget(
    options?: TamaraProductWidgetOptions | null
  ): Observable<TamaraInstance | null> {
    if (this.akitaRouterQuery.isBrowser) {
      return this.tamaraPayInXScriptTag.promiseInstance().pipe(
        tap(() => {
          try {
            if (options) {
              (window as any).TamaraPayInXPlan.init({
                ...options,
                publicKey: undefined, // TODO: Check with API Team why adding a Public Key returns 404 when rendering banners
              });
            }

            (window as any).TamaraPayInXPlan.render();
          } catch (err) {}
        })
      );
    } else {
      return of(null);
    }
  }
}
