export class TamaraPublicKey {
  public publicToken: string;

  constructor() {
    this.publicToken = '';
  }
}

export const parseTamaraPublicKey = (data?: any | null): TamaraPublicKey | null => {
  let parsed: TamaraPublicKey | null = null;
  if (data) {
    parsed = new TamaraPublicKey();
    parsed.publicToken = data.public_token || data.publicToken || '';
  }
  return parsed;
};
