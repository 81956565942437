export class TabbyError {
  public code: string;
  public field: string;
  public message: string;
  public name: string;

  constructor() {
    this.code = '';
    this.field = '';
    this.message = '';
    this.name = '';
  }
}

export const parseTabbyError = (data?: Record<string, any> | null): TabbyError | null => {
  let result: TabbyError | null = null;
  if (data) {
    result = new TabbyError();
    result.code = data.code || null;
    result.field = data.field || null;
    result.message = data.message || null;
    result.name = data.name || '';
  }
  return result;
};

export const parseTabbyErrorList = (
  data?: Array<Record<string, any>> | null
): Array<TabbyError> => {
  const results = new Array(0);
  if ((data?.length || 0) > 0) {
    for (const item of data || []) {
      const parsed = parseTabbyError(item);
      if (parsed) {
        results.push(parsed);
      }
    }
  }
  return results;
};
